import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common.service';
import {  CateTerm, TermEn, TermVi } from './term';

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.css']
})
export class TermComponent implements OnInit {
  terms: CateTerm[] = [];
  langWeb: string = '';
  
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.terms = [];
    this.langWeb = this.commonService.getLangPack();
    this.route.queryParams.subscribe(params => {
        if (params.hl === 'vi') {
          this.terms = TermVi;
        } else if (params.hl === 'en') {
          this.terms = TermEn;
        }
    }); 
  }

}
