import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateComponent } from './views/translate/translate.component';
import { ProfileComponent } from './views/user/profile/profile.component';
import { TermComponent } from './views/term/term.component';
import { HelpComponent } from './views/help/help.component';
import { ActivityComponent } from './views/user/activity/activity.component';

const routes: Routes = [
    { path: '', redirectTo: 'search', pathMatch: 'full' },
    { 
        path: 'search', 
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    },
    { 
        path: 'community', 
        loadChildren: () => import('./community/community.module').then(m => m.CommunityModule) 
    },
    { 
        path: 'verbs', 
        loadChildren: () => import('./verds/verds.module').then(m => m.VerdsModule) 
    },
    { 
        path: 'learning', 
        loadChildren: () => import('./learning/learning.module').then(m => m.LearningModule) 
    },
    { 
        path: 'notebook', 
        loadChildren: () => import('./notebook/notebook.module').then(m => m.NotebookModule) 
    },
    { 
        path: 'translate', 
        component: TranslateComponent 
    },
    { 
        path: 'sale-off', 
        loadChildren: () => import('./sale-off/premium.module').then(m => m.PremiumModule) 
    },
    { path: 'profile', component : ProfileComponent},
    { path: 'activity', component : ActivityComponent},
    { path: 'term', component : TermComponent},
    { path: 'help', component : HelpComponent},
    // { path: '**', redirectTo: 'search'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
