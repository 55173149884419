export interface CateTerm {
    id: number,
    title: string,
    cateContent: Term[]
} 
export interface Term {
    id: number,
    title: string,
    content: Content[]
}   
export interface Content {
    termContent: string,
    childTerm: ChildTerm[]
}
export interface ChildTerm {
    childTerm: string,
    childContent: string[];
}

export const TermVi: CateTerm[] = [
    {
        id: 1,
        title: '',
        cateContent: [
            {
                id: 1,
                title: "Điều 1. Nguyên tắc đăng ký Tài khoản Jaemy.net",
                content: [
                    {
                        termContent: "1. Bạn phải chịu trách nhiệm hoàn toàn trước mọi thông tin đăng ký tài khoản Jaemy.net, thông tin sửa đổi, bổ sung tài khoản Jaemy.net",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. Ngoài việc tuân thủ Thỏa thuận sử dụng, bạn phải chấp hành nghiêm chỉnh các thỏa thuận, quy định, quy trình khác của Jaemy.net được đăng tải công khai trên website Jaemy.net.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. Bạn không được đặt tài khoản theo tên của danh nhân, tên các vị lãnh đạo của Đảng và Nhà nước, tên của cá nhân, tổ chức tội phạm, phản động, khủng bố hoặc tài khoản có ý nghĩa không lành mạnh, trái với thuần phong mỹ tục.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 2,
                title: "Điều 2. Đăng ký và ngừng sử dụng dịch vụ.",
                content: [
                    {
                        termContent: "1. Trước khi đăng ký tài khoản, bạn xác nhận đã đọc, hiểu và đồng ý với tất cả các quy định trong Thỏa thuận sử dụng của Jaemy.net.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. Khi đăng ký tài khoản bạn phải đồng ý cung cấp thông tin một cách trung thực, đầy đủ và cập nhật các thông tin này khi có sự thay đổi trong thực tế. Các vấn đề thu thập, sử dụng và bảo mật thông tin vui lòng xem trong Chính sách bảo mật.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. Khi đăng ký, bạn sẽ tạo một tài khoản Jaemy.net với tên truy cập và một mật khẩu. Bạn đồng ý chịu trách nhiệm bảo vệ tên truy cập và mật khẩu của mình để tránh việc người khác sử dụng trái phép và thông báo kịp thời cho Jaemy.net về bất kỳ việc sử dụng trái phép nào. Việc tạo tài khoản trên điện thoại sẽ đòi hỏi phải truyền dữ liệu, chi phí truyền dữ liệu nếu có sẽ do bạn chịu trách nhiệm chi trả.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. Bạn có thể chấm dứt việc đăng ký nếu bạn không muốn tiếp tục sử dụng dịch vụ của Jaemy.net. Jaemy.net có thể chấm dứt việc đăng ký của bạn hoặc cấm bạn truy cập vào một số phần của dịch vụ nếu có căn cứ xác định bạn đã vi phạm Thỏa thuận.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 3,
                title: "Điều 3. Các nội dung cấm trao đổi, chia sẻ trên mạng xã hội ",
                content: [
                    {
                        termContent: "1. Tuyệt đối nghiêm cấm mọi hành vi: tuyên truyền, chống phá và xuyên tạc chính quyền, thể chế và các chính sách của nhà nước; kích động bạo lực, tuyên truyền chiến tranh, gây hận thù giữa các dân tộc và nhân dân các nước; kích động dâm ô, đồi trụy, tội ác, tệ nạn xã hội, mê tín dị đoan, phá hoại thuần phong mỹ tục của dân tộc; tiết lộ bí mật nhà nước, bí mật quân sự, an ninh, kinh tế, đối ngoại và những bí mật khác đã được pháp luật quy định. Trường hợp phát hiện, không những bị xóa bỏ tài khoản mà chúng tôi còn có thể cung cấp thông tin của người vi phạm cho các cơ quan chức năng để xử lý theo pháp luật.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. Không có những hành vi, thái độ làm tổn hại đến uy tín của các sản phẩm của công ty phát hành dưới bất kỳ hình thức nào, phương thức nào. Mọi vi phạm sẽ bị tước bỏ mọi quyền lợi liên quan đối với dịch vụ hoặc xử lý trước pháp luật nếu cần thiết.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. Nghiêm cấm tổ chức các hình thức cá cược, cờ bạc hoặc các thỏa thuận liên quan đến tiền, hiện kim, hiện vật.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. Tuyệt đối nghiêm cấm việc xúc phạm, nhạo báng người khác dưới bất kỳ hình thức nào (nhạo báng, chê bai, kỳ thị tôn giáo, giới tính, sắc tộc,...).",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. Cấm kêu gọi tổ chức bạo loạn, cấm đăng tải, trao đổi các thông tin về biểu tình, bạo loạn.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. Cấm đăng lại nguồn từ các trang blog cá nhân, diễn đàn, bạn đọc viết trên các mục báo,...",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. Cấm đăng tải bài viết từ các trang có yếu tố nước ngoài như BBC tiếng Việt, VOA tiếng Việt...",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "8. Cấm đăng các nội dung đi ngược lại thuần phong mỹ tục của Việt Nam và vi phạm pháp luật nước CHXHCN Việt Nam.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "9. Nghiêm cấm đưa Đảng Cộng sản Việt Nam, Nhà nước Việt Nam, các thể chế chính trị, lịch sử, các lãnh tụ vào bàn luận trong những chủ đề không liên quan.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "10. Cấm viết bài, trao đổi các thông tin tuyên truyền mê tín dị đoan.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "11. Nghiêm cấm mọi hành vi mạo nhận, cố ý mạo danh làm người khác. Mọi hành vi vi phạm sẽ bị xử lý hoặc tước bỏ tài khoản.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "12. Không gửi lên hoặc truyền phát bất kỳ thông tin bất hợp pháp, lừa gạt, bôi nhọ, sỉ nhục, tục tĩu, khiêu dâm, xúc phạm, đe dọa, lăng mạ, thù hận, kích động… hoặc trái với chuẩn mực đạo đức chung của xã hội thuộc bất kỳ loại nào. Những nội dung miêu tả tỉ mỉ những hành động dâm ô, bạo lực, giết người rùng rợn; đăng, phát các hình ảnh phản cảm, thiếu tính nhân văn; cung cấp nội dung, hình ảnh, tranh khỏa thân có tính chất kích dâm, thiếu thẩm mỹ, không phù hợp với thuần phong, mỹ tục Việt Nam sẽ bị cấm hoàn toàn.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "13. Chúng tôi tôn trọng quyền tự do ngôn luận, nhưng cũng bảo lưu việc có toàn quyền lược bớt, hoặc xoá bỏ một phần hoặc toàn bộ các bài viết của bạn nếu xét thấy bài viết hoặc bất kỳ thông tin gì gửi lên vi phạm những điểm nêu trên, bất kể việc vi phạm đó là rõ ràng hay chỉ là hàm ý.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "14. Nghiêm cấm tuyên truyền bất kỳ thông điệp nào mang tính quảng cáo, mời gọi, thư dây chuyền, cơ hội đầu tư hay bất kỳ dạng liên lạc có mục đích thương mại nào mà người dùng không mong muốn, thư rác hay tin nhắn rác, gửi hay chuyển các thông tin, phần mềm, hoặc các tài liệu khác bất kỳ, vi phạm hoặc xâm phạm các quyền của những người khác, trong đó bao gồm cả tài liệu xâm phạm đến quyền riêng tư hoặc công khai, hoặc tài liệu được bảo vệ bản quyền, tên thương mại hoặc quyền sở hữu khác, hoặc các sản phẩm phái sinh mà không được sự cho phép của người chủ sở hữu hoặc người có quyền hợp pháp. Nếu phát hiện những tài khoản vi phạm chúng tôi sẽ khóa tài khoản vĩnh viễn.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "15. Bạn sẽ không gửi hoặc truyền bất kỳ thông tin hoặc phần mềm nào không thuộc quyền sở hữu của bạn trừ khi đó là phần mềm được cung cấp miễn phí. Không gửi bất kỳ thông tin hay phần mềm nào có chứa bất kỳ loại virus, Trojan, bọ hay các thành phần nguy hại nào. Không sử dụng, cung cấp thông tin vi phạm các quy định về sở hữu trí tuệ, về giao dịch thương mại điện tử và các quy định khác của pháp luật hiện hành.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "16. Không xâm phạm, xâm nhập, tiếp cận, sử dụng hay tìm cách xâm phạm, xâm nhập, tiếp cận hoặc sử dụng bất kỳ phần nào trong máy chủ của chúng tôi, hoặc bất kỳ khu vực dữ liệu nào nếu không được chúng tôi cho phép, nghiêm cấm các hành vi lợi dụng lỗi hệ thống để trục lợi cá nhân gây thiệt hại đến nhà cung cấp dịch vụ,chế hay tổ chức nào khác mà bạn không được uỷ quyền tuyên bố mối liên hệ đó nhằm mục đích trục lợi cá nhân gây sự nhầm lẫn đối với những thành viên cùng tham gia mạng xã hội . Bạn sẽ không được tạo ra các thông tin giả mạo cá nhân, tổ chức, doanh nghiệp khác; thông tin sai sự thật xâm hại đến quyền và lợi ích hợp pháp của tổ chức, doanh nghiệp, cá nhân.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "17. Không được quyền hoặc có các hành động nhằm hạn chế hoặc cấm đoán bất kỳ người dùng nào khác sử dụng website: Jaemy.net.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "18. Không được truyền bá tác phẩm báo chí, văn học, nghệ thuật, âm nhạc, phim ảnh, xuất bản phẩm vi phạm các quy định của pháp luật.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 4,
                title: "Điều 4. Cơ chế xử lý thành viên vi phạm thỏa thuận",
                content: [
                    {
                        termContent: "1. Nguyên tắc xử lý",
                        childTerm: [
                            {
                                childTerm: "Nếu Người sử dụng vi phạm các điều khoản quy định tại Thỏa thuận cung cấp và sử dụng dịch vụ mạng xã hội hoặc theo cách khác tạo rủi ro hoặc trách nhiệm pháp lý có thể cho Công ty TNHH Công nghệ EUP, Người sử dụng sẽ được nhắc nhở, cảnh cáo, áp dụng biện pháp xử lý bài viết, khóa tài khoản có thời hạn hoặc vĩnh viễn.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Hình thức xử phạt khóa tài khoản Jaemy.net có thời hạn hoặc vĩnh viễn, điều đó đồng nghĩa với việc Người sử dụng cũng không thể sử dụng các sản phẩm khác khi truy cập từ tài khoản đã bị khóa.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Nếu tài khoản Jaemy.net bị khóa vĩnh viễn, thì toàn bộ những quyền lợi của Chủ tài khoản cũng sẽ khóa vĩnh viễn.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Các hành vi vi phạm của Người sử dụng không được liệt kê ở dưới, tùy từng trường hợp cụ thể, Ban quản trị Jaemy.net có toàn quyền đưa ra mức phạt phù hợp theo các hình thức xử phạt theo quy định tại thỏa thuận này.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Các hình thức xử phạt:",
                                childContent: [
                                    "1. Hình thức xử phạt cấp 1: Khóa tài khoản 10 ngày.", 
                                    "2. Hình thức xử phạt cấp 2: Khóa tài khoản 30 ngày.", 
                                    "3. Hình thức xử phạt cấp 3: Khóa tài khoản 120 ngày", 
                                    "4. Hình thức xử phạt cấp đặc biệt: Khóa tài khoản vĩnh viễn."
                                ]
                            },
                            {
                                childTerm: "Tất cả các bài viết vi phạm quy định có thể bị xoá, chuyển chuyên mục, lọc bỏ nội dung quảng cáo, thay từ ngữ không phù hợp, tạm ngừng hiển thị chờ kiểm duyệt tùy theo mức độ vi phạm. Jaemy.net có thể gửi thông báo cảnh báo nội dung vi phạm và yêu cầu Người sử dụng ngay lập tức gỡ bỏ, lọc bỏ nội dung vi phạm. Nếu Người sử dụng không gỡ bỏ, lọc bỏ nội dung vi phạm, Ban quản trị Jaemy.net xử lý bài viết và khóa tài khoản của Người sử dụng.",
                                childContent: [""]
                            }
                            ,
                            {
                                childTerm: "Ban quản trị có toàn quyền trong việc xác định và xử lý bài viết/ thành viên vi phạm quy định. Nhật ký xử lý sẽ được lưu trữ tối thiểu hai năm, Ban quản trị có toàn quyền công khai các vi phạm của Thành viên nhưng không bao gồm nội dung vi phạm đã bị xóa hay lọc bỏ.",
                                childContent: [""]
                            }
                        ]
                    },
                    {
                        termContent: "2.  Hình thức xử phạt",
                        childTerm: [
                            {
                                childTerm: "Hình thức xử phạt cấp đặc biệt, khóa vĩnh viễn được áp dụng bao gồm nhưng không giới hạn đối với các hành vi sau:",
                                childContent: [
                                    "Người sử dụng có hành vi lợi dụng Jaemy.net nhằm chống phá nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam. Hành vi này bao gồm nhưng không giới hạn việc Người sử dụng đặt Tên tài khoản/ Tên thành viên trùng tên với các vĩ nhân, các vị anh hùng của dân tộc, các vị lãnh đạo của Đảng và Nhà nước, hoặc người dùng có sử dụng hình ảnh, video, phát ngôn,… có chứa thông tin bàn luận về vấn đề chính trị hoặc tiết lộ bí mật nhà nước Cộng hòa Xã hội Chủ nghĩa Việt Nam.",
                                    "Thông tin, hình ảnh, video khiêu dâm: Người sử dụng đăng tải hình ảnh, âm thanh, video khiêu dâm, chat sex hoặc tuyên truyền các các nội dung khiêu dâm. Thông tin cá cược, cờ bạc: Lợi dụng Jaemy.net, người dùng đăng tải nội dung thông tin, hình ảnh, âm thanh, video chứa thông tin để tổ chức các hình thức cá cược, cờ bạc hoặc các thỏa thuận liên quan đến tiền, hiện kim, hiện vật.",
                                    "Lan truyền thông tin lừa đảo: Sử dụng văn bản, hình ảnh, âm thanh hoặc video có chứa thông tin lừa đảo: giả làm chính thức hoặc các tổ chức, cá nhân; gian lận, lừa đảo tài sản của người khác hoặc tài khoản Jaemy.net, điểm tích lũy."
                                ]
                            },
                            {
                                childTerm: "Hình thức xử phạt cấp 3, khóa tài khoản 120 ngày áp dụng bao gồm nhưng không giới hạn đối với các hành vi sau:",
                                childContent: [
                                    "Phá hoại hệ thống mạng xã hội Jaemy.net: Người sử dụng lợi dụng việc sử dụng sản phẩm để xâm nhập vào hệ thống máy chủ nhằm phá hoại sản phẩm hoặc cản trở việc truy cập thông tin. Người sử dụng sử dụng công cụ kỹ thuật nhằm tăng điểm hoạt động, vật phẩm hoặc nhằm treo máy, spam chat.",
                                    "Sử dụng Diễn đàn thảo luận hay Hoạt động nhóm để lôi kéo tổ chức hội họp thực tế ở bên ngoài thực hiện các hành vi vi phạm pháp luật."
                                ]
                            },
                            {
                                childTerm: "Hình thức xử phạt cấp 2, khóa tài khoản 30 ngày được áp dụng bao gồm nhưng không giới hạn đối với các hành vi sau:",
                                childContent: [
                                    "Hành vi giao tiếp: khiêu dâm ở mức độ nhẹ, nhắn tin rác, kích động các Thành viên khác đến các Nhóm thảo luận để gây rối hoặc tuyên truyền những thông tin vi phạm.",
                                    "Quảng cáo: Trong tên thành viên, trạng thái, giải thích về bản thân có chứa thông tin quảng cáo hoặc sử dụng các văn bản, hình ảnh, âm thanh, hoặc video và thông tin quảng cáo mà không có sự đồng ý bằng văn bản của Công ty TNHH Công nghệ EUP. Ngoài ra thành viên sử dụng sản phẩm với mục đích quảng cáo, tuyên truyền, mua bán và truyền bá hàng hóa, dịch vụ bị cấm, trong trường hợp này tùy thuộc vào mức độ nghiêm trọng sẽ áp dụng hình phạt 2 hoặc hình phạt 3.", 
                                    "Xâm phạm riêng tư: Sử dụng hình ảnh cá nhân của người khác, công khai những tư liệu cá nhân và những thông tin của khác như danh tính, địa chỉ, số điện thoại mà chưa được sự đồng ý và tiến hành gọi điện quấy nhiễu hoặc kích động người khác quấy nhiễu.", 
                                    "Công kích người khác: Sử dụng hình ảnh, thông tin, âm thanh hoặc video, xúc phạm, đưa thông tin xuyên tạc, vu khống, nhạo báng, xúc phạm uy tín tới tổ chức, cá nhân.Vi phạm bản quyền: Ăn cắp các nội dung, sao chép hoặc trích dẫn mà không được phép sử dụng bản quyền của người khác."]
                            },
                            {
                                childTerm: "Hình thức xử phạt cấp 1, khóa tài khoản 10 ngày được áp dụng bao gồm nhưng không giới hạn đối với các hành vi sau:",
                                childContent: ["Bình luận sai chủ đề, spam trên diễn đàn."]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        title: 'QUYỀN VÀ TRÁCH NHIỆM CÁC BÊN',
        cateContent: [
            {
                id: 1,
                title: "Điều 5. Quyền của người sử dụng dịch vụ website Jaemy.net",
                content: [
                    {
                        termContent: "Được sử dụng dịch vụ của mạng xã hội Jaemy.net trừ các dịch vụ bị cấm theo quy định của pháp luật.",
                        childTerm: [
                            {
                                childTerm: "1. Bạn được quyền thay đổi, bổ sung thông tin tài khoản, mật khẩu, giấy tờ tùy thân, email đã đăng ký.",
                                childContent: [""]
                            },
                            {
                                childTerm: "2. Bạn được hướng dẫn cách đặt mật khẩu an toàn; nhập các thông tin quan trọng để bảo vệ tài khoản; sử dụng tài khoản liên kết để đăng nhập tài khoản.",
                                childContent: [""]
                            },
                            {
                                childTerm: "3. Bạn được quyền tặng/cho tài khoản Jaemy.net cho người khác. Quyền được tặng/cho tài khoản chỉ được áp dụng đối với tài khoản đã đăng ký đầy đủ và chính xác các thông tin tài khoản theo quy định Thỏa thuận sử dụng Jaemy.net.",
                                childContent: [""]
                            },
                            {
                                childTerm: "4. Được bảo vệ bí mật thông tin riêng và thông tin cá nhân theo quy định của pháp luật.",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            },
            {
                id: 2,
                title: "Điều 6. Trách nhiệm của người sử dụng dịch vụ mạng xã hội",
                content: [
                    {
                        termContent: "1. Để nhận được sự hỗ trợ từ Jaemy.net, tài khoản của bạn phải đăng ký đầy đủ các thông tin trung thực, chính xác. Nếu có sự thay đổi, bổ sung về thông tin, hãy cập nhật ngay cho chúng tôi. Bạn đảm bảo rằng, thông tin hiện trạng của bạn là mới nhất, đầy đủ, trung thực và chính xác và bạn phải chịu trách nhiệm toàn bộ các thông tin bạn cung cấp.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. Bạn có trách nhiệm bảo mật thông tin tài khoản bao gồm: Mật khẩu, giấy tờ tùy thân, Email bảo vệ tài khoản và tài khoản liên kết. Nếu những thông tin trên bị tiết lộ dưới bất kỳ hình thức nào thì bạn phải chấp nhận những rủi ro phát sinh Jaemy.net sẽ căn cứ vào những thông tin hiện có trong tài khoản để làm căn cứ quyết định chủ sở hữu tài khoản nếu có tranh chấp và sẽ không chịu trách nhiệm về mọi tổn thất phát sinh. Thông tin Giấy tờ tùy thân đăng ký trong tài khoản là thông tin quan trọng nhất để chứng minh chủ sở hữu tài khoản.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. Bạn đồng ý sẽ thông báo ngay cho Jaemy.net về bất kỳ trường hợp nào sử dụng trái phép tài khoản và mật khẩu của bạn hoặc bất kỳ các hành động phá vỡ hệ thống bảo mật nào. Bạn cũng bảo đảm rằng, bạn luôn thoát tài khoản của mình sau mỗi lần sử dụng.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. Tuân thủ thỏa thuận quản lý, cung cấp và sử dụng dịch vụ mạng xã hội .",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. Chịu trách nhiệm về nội dung thông tin do mình lưu trữ, cung cấp, truyền đưa trên mạng xã hội, phát tán thông tin qua đường liên kết trực tiếp do mình thiết lập.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. Khi phát hiện ra lỗi, các vấn đề gây ảnh hưởng tới hoạt động bình thường của Jaemy.net, bạn hãy thông báo cho chúng tôi qua số điện thoại 0354 7373 64.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. Bạn cam kết thực hiện trách nhiệm bảo đảm sử dụng hợp pháp nội dung thông tin số đưa lên đăng tải trên hệ thống mạng Internet và mạng viễn thông.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "8. Bạn phải tuân thủ tuyệt đối quy định tại Điều 2 thỏa thuận này về các hành vi cấm. Nếu vi phạm một hoặc nhiều hành vi, tùy thuộc vào mức độ vi phạm Jaemy.net sẽ khóa tài khoản vĩnh viễn, tước bỏ mọi quyền lợi của bạn.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "9. Tuân thủ các quy định về bảo đảm an toàn thông tin, an ninh thông tin và các quy định khác có liên quan tại Nghị định 72/2013/NĐ-CP và Nghị định 27/2018/NĐ-CP quy định về Quản lý, cung cấp, sử dụng dịch vụ internet và thông tin trên mạng.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "10. Thực hiện trách nhiệm khác theo quy định pháp luật.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 3,
                title: "Điều 7. Quyền của Công ty TNHH Công nghệ EUP",
                content: [
                    {
                        termContent: "1. Nếu Người sử dụng cung cấp bất kỳ thông tin nào không trung thực, không chính xác, hoặc nếu Công ty TNHH Công nghệ EUP có cơ sở để nghi ngờ rằng thông tin đó không chính xác hoặc nếu Người sử dụng vi phạm bất cứ điều khoản nào trong thỏa thuận sử dụng hoặc thỏa thuận sử dụng các sản phẩm và dịch vụ khác của Jaemy.net được quy định trên website, Công ty TNHH Công nghệ EUP có toàn quyền chấm dứt, xóa bỏ tài khoản của Người sử dụng mà không cần sự đồng ý của Người sử dụng và không phải chịu bất cứ trách nhiệm nào đối với Người sử dụng.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. Mọi vi phạm của Chủ tài khoản trong quá trình sử dụng sản phẩm Jaemy.net, Công ty TNHH Công nghệ EUP có quyền áp dụng các biện pháp chế tài theo như điều khoản và thỏa thuận sử dụng này như gỡ bỏ, xóa nội dung vi phạm, có quyền tước bỏ mọi quyền lợi của Chủ tài khoản đối với việc sử dụng sản phẩm cũng như sẽ yêu cầu cơ quan chức năng truy tố Chủ tài khoản trước pháp luật nếu cần thiết.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. Khi phát hiện những vi phạm như gian lận, phá hoại, truyền bá nội dung cấm hoặc bất kỳ vi phạm quy định của pháp luật thông qua việc sử dụng Jaemy.net, Công ty TNHH Công nghệ EUP có quyền sử dụng những thông tin cá nhân mà người sử dụng cung cấp khi đăng ký tài khoản để chuyển cho Cơ quan chức năng giải quyết theo quy định của pháp luật.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. Công ty TNHH Công nghệ EUP có quyền từ chối hỗ trợ, giải quyết đối với những Tài khoản đăng ký thông tin không chính xác đầy đủ theo quy định và đối với những Tài khoản vi phạm trách nhiệm bảo mật tài khoản được quy định tại Thỏa thuận này.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. Có quyền áp dụng các quy định về điều khoản sử dụng dịch vụ bổ sung mà không cần phải được Người sử dụng đồng ý.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. Công ty TNHH Công nghệ EUP có quyền thương mại hóa dịch vụ Jaemy.net, tuy nhiên Công ty TNHH Công nghệ EUP tuyệt đối tuân thủ thỏa thuận này. Công ty TNHH Công nghệ EUP tuyệt đối không bán lại nội dung, thông tin của Người sử dụng. Trong trường hợp Công ty TNHH Công nghệ EUP có thu phí sử dụng dịch vụ thì Công ty TNHH Công nghệ EUP phải thông báo cho Người sử dụng và Người sử dụng phải tự nguyện đồng ý.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. Công ty TNHH Công nghệ EUP có quyền bảo trì và nâng cấp hệ thống để phục vụ tốt hơn. Quá trình bảo trì hay nâng cấp có thể làm gián đoạn việc sử dụng dịch vụ hoặc chất lượng dịch vụ không đảm bảo trong thời điểm bảo trì hay nâng cấp.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 4,
                title: "Điều 8. Trách nhiệm của Công ty TNHH Công nghệ EUP",
                content: [
                    {
                        termContent: "1. Có trách nhiệm hỗ trợ chủ tài khoản trong quá trình sử dụng Jaemy.net",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. Nhận và giải quyết khiếu nại của người dùng các trường hợp phát sinh trong quá trình sử dụng mạng xã hội Jaemy.net, tuy nhiên chúng tôi chỉ hỗ trợ, nhận và giải quyết đối với tài khoản đăng ký thông tin đầy đủ, chính xác, trung thực.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. Có trách nhiệm bảo mật thông tin cá nhân của chủ tài khoản Jaemy.net, không bán hoặc trao đổi những thông tin này với bên thứ 3, trừ trường hợp theo quy định pháp luật hoặc được chủ tài khoản chấp nhận.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. Công khai thỏa thuận cung cấp và sử dụng dịch vụ mạng xã hội;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. Có biện pháp bảo vệ bí mật thông tin riêng, thông tin cá nhân của người sử dụng; thông báo cho người sử dụng về quyền, trách nhiệm và các rủi ro khi lưu trữ, trao đổi và chia sẻ thông tin trên mạng;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. Bảo đảm quyền quyết định của người sử dụng khi cho phép thông tin cá nhân của mình được cung cấp cho tổ chức, doanh nghiệp, cá nhân khác;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. Không được chủ động cung cấp thông tin công cộng có nội dung vi phạm quy định tại Điều 5 Nghị định 72/2013/NĐ-CP quy định về Quản lý, cung cấp, sử dụng dịch vụ internet và thông tin trên mạng;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "8. Phối hợp với cơ quan quản lý nhà nước có thẩm quyền để loại bỏ hoặc ngăn chặn thông tin có nội dung vi phạm quy định tại Điều 5 Nghị định 72/2013/NĐ-CP quy định về Quản lý, cung cấp, sử dụng dịch vụ internet và thông tin trên mạng khi có yêu cầu;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "9. Cung cấp thông tin cá nhân và thông tin riêng của người sử dụng có liên quan đến hoạt động khủng bố, tội phạm, vi phạm pháp luật khi có yêu cầu của cơ quan quản lý nhà nước có thẩm quyền;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "10. Có ít nhất 01 hệ thống máy chủ đặt tại Việt Nam đáp ứng việc thanh tra, kiểm tra, lưu trữ, cung cấp thông tin theo yêu cầu của cơ quan quản lý nhà nước có thẩm quyền và giải quyết khiếu nại của người dùng đối với việc cung cấp dịch vụ theo quy định của Bộ Thông tin và Truyền thông;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "11. Thực hiện việc đăng ký, lưu trữ và quản lý thông tin cá nhân của người thiết lập trang thông tin điện tử cá nhân và người cung cấp thông tin khác trên mạng xã hội theo quy định của Bộ Thông tin và Truyền thông. Bảo đảm chỉ những người đã cung cấp đầy đủ, chính xác thông tin cá nhân theo quy định mới được thiết lập trang thông tin điện tử cá nhân hoặc cung cấp thông tin trên mạng xã hội;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "12. Báo cáo theo quy định và chịu sự thanh tra, kiểm tra của các cơ quan quản lý nhà nước có thẩm quyền.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                ]
            }
        ]
    },
    {
        id: 3,
        title: 'CHÍNH SÁCH BẢO VỆ QUYỀN RIÊNG TƯ',
        cateContent: [
            {
                id: 1,
                title: "Điều 9. Mục đích thu thập Thông tin cá nhân.",
                content: [
                    {
                        termContent: "1. Thực hiện quản lý việc đăng thông tin và kiểm duyệt thông tin công khai.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. Thực hiện quản lý lưu lượng người dùng sử dụng dịch vụ, số lượng người dùng;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. Thực hiện quản lý hoạt động tiếp thị, cung cấp thông tin tới người dùng như gửi các cập nhật mới nhất về dịch vụ diễn đàn và các tính năng mới liên quan đến sản phẩm và dịch vụ của chúng tôi;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. Cung cấp giải pháp và thay đổi dịch vụ nhằm phục vụ nhu cầu người dùng;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. Quản lý, phân tích, đánh giá số liệu liên quan đến dữ liệu hệ thống ;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. Tiếp nhận thông tin, góp ý, đề xuất, khiếu nại của thành viên nhằm cải thiện chất lượng dịch vụ;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. Liên hệ với thành viên để giải quyết các yêu cầu của thành viên;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 2,
                title: "Điều 10. Phạm vi thu thập thông tin cá nhân",
                content: [
                    {
                        termContent: "1. Loại thông tin thu thập.",
                        childTerm: [
                            {
                                childTerm: "Thông tin cá nhân",
                                childContent: [""]
                            },
                            {
                                childTerm: "Thông tin liên lạc như số điện thoại, địa chỉ gửi thư, địa chỉ email, số fax;",
                                childContent: [""]
                            }
                        ]
                    },
                    {
                        termContent: "2. Nội dung thông tin thu thập",
                        childTerm: [
                            {
                                childTerm: "Họ và tên;",
                                childContent: [""]
                            },
                            {
                                childTerm: "Ngày, tháng, năm sinh;",
                                childContent: [""]
                            },
                            {
                                childTerm: "Số chứng minh nhân dân hoặc số hộ chiếu, ngày cấp, nơi cấp;",
                                childContent: [""]
                            },
                            {
                                childTerm: "Số điện thoại, địa chỉ email.",
                                childContent: [""]
                            }
                        ]
                    },
                    {
                        termContent: "Trường hợp người sử dụng internet dưới 14 tuổi và chưa có hộ chiếu, người giám hộ hợp pháp quyết định việc đăng ký thông tin cá nhân của mình để thể hiện sự cho phép và chịu trách nhiệm trước pháp luật về việc đăng ký đó.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Người dùng có quyền từ chối hoặc không cung cấp đầy đủ các thông tin được yêu cầu.Trong trường hợp đó, Chúng tôi không thể đảm bảo cho người dùng những dịch vụ đầy đủ và chất lượng. Chúng tôi sẽ yêu cầu bạn cung cấp thông tin cá nhân khi bạn đăng ký làm thành viên của website chúng tôi (Acount). Nếu sử dụng thông tin này cho những mục đích khác ngoài mục đích thu thập, khi đó chúng tôi sẽ xin phép bạn trước khi sử dụng những thông tin này.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 3,
                title: "Điều 11. Phương pháp thu thập thông tin.",
                content: [
                    {
                        termContent: "Mạng xã hội Jaemy.net, thu thập các Thông tin cá nhân của thành viên nêu tại Điều 10 nêu trên qua website thông qua Form Đăng ký thành viên được cung cấp công khai tại địa chỉ Jaemy.net.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 4,
                title: "Điều 12. Thời gian lưu trữ thông tin thu thập",
                content: [
                    {
                        termContent: "Mạng xã hội Jaemy.net sẽ Lưu trữ tối thiểu 02 (hai) năm đối với các thông tin về tài khoản, thời gian đăng nhập, đăng xuất, địa chỉ IP của người sử dụng và nhật ký xử lý thông tin được đăng tải. Trong mọi trường hợp thông tin cá nhân thành viên sẽ được bảo mật trên máy chủ của Jaemy.net.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 5,
                title: "Điều 13. Việc công khai, chỉnh sửa thông tin thu thập.",
                content: [
                    {
                        termContent: "1. Chúng tôi cấp quyền cho thành viên tự chỉnh sửa thông tin công khai trên website. Chúng tôi chỉ hủy bỏ hoặc ẩn thông tin khi thành viên có yêu cầu hoặc thông tin vi phạm Quy định của mạng xã hội hội Jaemy.net.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2.Chúng tôi công bố các thông tin cá nhân thu thập từ người dùng lên website Jaemy.net và website liên quan nhằm mục đích cung cấp dịch vụ tốt nhất cho người dùng và đảm bảo tính hiệu quả của dịch vụ.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3.  Chúng tôi có thể phải cung cấp các thông tin cá nhân của người dùng cho các cơ quan chức năng, cơ quan của Chính phủ vì các mục đích an toàn an ninh, hải quan, nhập cư và các mục đích theo luật định khác trong phạm vi được yêu cầu hoặc theo luật định.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 6,
                title: "Điều 14. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân",
                content: [
                    {
                        termContent: "Công ty TNHH Công nghệ EUP",
                        childTerm: [
                            {
                                childTerm: "Trụ sở chính: Phòng 403, tòa nhà Số 01 ngõ 120 đường Trường Chinh, Phường Phương Mai, Quận Đống Đa, Thành phố Hà Nội, Việt Nam.",
                                childContent: [""] 
                            },
                            {
                                childTerm: "Tel: 0969 758 516",
                                childContent: [""]
                            },
                            {
                                childTerm: "Email: trinhnguyen.se@gmail.com",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            },
            {
                id: 7,
                title: "Điều 15. Cam kết bảo mật thông tin",
                content: [
                    {
                        termContent: "Chúng tôi sử dụng các biện pháp bảo mật thích hợp để bảo vệ việc truy cập hoặc sửa đổi trái phép, tiết lộ hoặc huỷ dữ liệu. Các biện pháp này bao gồm xem xét thực tiễn thu thập, lưu trữ, xử lý và biện pháp bảo mật dữ liệu nội bộ, cũng như các biện pháp bảo mật mức vật lý để bảo vệ việc truy cập trái phép vào hệ thống lưu trữ dữ liệu cá nhân.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Chúng tôi chỉ cho phép nhân viên có thẩm quyền của mạng xã hội Jaemy.net truy cập thông tin cá nhân; những người cần biết thông tin này để vận hành, phát triển và cải tiến dịch vụ của chúng tôi. Những cá nhân này bị ràng buộc bởi nghĩa vụ bảo mật và có thể chịu kỷ luật, bao gồm chấm dứt việc làm và truy tố hình sự, khi họ vi phạm các nghĩa vụ này.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Mỗi thành viên tự chịu trách nhiệm bảo mật mật khẩu tài khoản mạng xã hội Jaemy.net, cam kết không công khai hay cung cấp thông tin bảo mật tài khoản của thành viên đến bất kỳ ai hay đơn vị thứ ba nào khác. Thông tin về tài khoản của bạn được đặt trên một máy chủ rất an toàn và được tường lửa bảo vệ.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Quy định về quyền riêng tư có thể có những thiếu sót nhất định, do trục trặc kỹ thuật, hoặc các vấn đề bất khả kháng và mạng xã hội Jaemy.net hoàn toàn không chịu trách nhiệm về những thiệt hại do các lỗi đó gây ra. Hãy luôn luôn cẩn trọng và tự bảo vệ thông tin cá nhân của bạn.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Chúng tôi sẽ bắt buộc phải công khai thông tin nếu đó là việc cần thiết để bảo vệ tài sản cũng như sự an toàn của mạng xã hội Jaemy.net hoặc những người khác cũng như ngăn ngừa các hoạt động phạm pháp, vi phạm quy định làm ảnh hưởng lớn tới các thành viên khác. Hoặc khi cơ quan thực thi pháp luật yêu cầu cung cấp thông tin.Người đăng tin sẽ chịu trách nhiệm về tính xác thực, và chịu trách nhiệm về mọi tranh chấp pháp lý xảy ra liên quan đến thông tin của mình.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 8,
                title: "Điều 16. Chính sách bảo vệ thông tin cá nhân, thông tin riêng của người sử dụng dịch vụ mạng xã hội .",
                content: [
                    {
                        termContent: "Chúng tôi công khai các thông tin quy định tại Điều 9, 10, 11, 12, 13, 14, 15 tại bản thỏa thuận này để người dùng nắm được chính sách bảo vệ quyền riêng tư của mạng xã hội Jaemy.net.Để bảo vệ thông tin cá nhân, thông tin riêng của người sử dụng dịch vụ mạng xã hội chúng tôi sử dụng các phần mềm lưu trữ, phần mềm bảo mật thông tin và hệ thống tường lửa rất chắc chắn.Đảm bảo thông tin của quý khách hàng không bị đánh cắp.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        title: 'GIẢI QUYẾT TRANH CHẤP, KHIẾU NẠI',
        cateContent: [
            {
                id: 1,
                title: "Điều 17. Cơ chế giải quyết tranh chấp",
                content: [
                    {
                        termContent: "Mọi tranh chấp phát sinh giữa mạng xã hội Jaemy.net và thành viên hoặc nhà cung cấp sẽ được giải quyết trên cơ sở thương lượng. Trường hợp không đạt được thỏa thuận như mong muốn, một trong hai bên có quyền đưa vụ việc ra Tòa án để giải quyết.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Khi tranh chấp phát sinh giữa các thành viên hoặc giữa thành viên với nhà cung cấp dịch vụ trực tiếp, ban quản lý website sẽ có trách nhiệm cung cấp cho thành viên thông tin về nhà cung cấp dịch vụ, tích cực hỗ trợ thành viên hoặc đại diện thành viên bảo vệ quyền lợi và lợi ích hợp pháp của mình.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Trong trường hợp xảy ra sự cố do lỗi của Jaemy.net chúng tôi sẽ ngay lập tức áp dụng các biện pháp cần thiết để đảm bảo quyền lợi cho người dùng.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 2,
                title: "Điều 18. Quy trình giải quyết tranh chấp",
                content: [
                    {
                        termContent: "Bước 1: Thành viên mạng xã hội khiếu nại về dịch vụ hoặc thông báo các tranh chấp giữa các bên qua email: trinhnguyen.se@gmail.com",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Bước 2: Bộ phận bảo vệ quyền lợi thành viên của mạng xã hội Jaemy.net sẽ tiếp nhận các khiếu nại của thành viên, tùy theo tính chất và mức độ của khiếu nại thì Jaemy.net sẽ có những biện pháp cụ thể hỗ trợ thành viên để giải quyết tranh chấp đó.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Bước 3: Trong trường nằm ngoài khả năng và thẩm quyền của Jaemy.net thì ban quản trị sẽ yêu cầu thành viên đưa vụ việc này ra cơ quan nhà nước có thẩm quyền giải quyết theo pháp luật.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Thời gian giải quyết tranh chấp:",
                        childTerm: [
                            {
                                childTerm: "Trường hợp nằm trong khả năng và thẩm quyền của Jaemy.net chúng tôi cam kết sẽ giải quyết tranh chấp của thành viên trong vòng 5 ngày làm việc.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Trường hợp nằm ngoài khả năng và thẩm quyền của Jaemy.net thì thời gian giải quyết tranh chấp tùy thuộc vào tính chất vụ việc và cơ quan nhà nước có thẩm quyền.",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            },
            {
                id: 3,
                title: "Điều 19. Vai trò và trách nhiệm của các bên trong việc giải quyết tranh chấp",
                content: [
                    {
                        termContent: "",
                        childTerm: [
                            {
                                childTerm: "Mạng xã hội Jaemy.net sẽ hỗ trợ thành viên bằng việc cung cấp đầy đủ thông tin và các nội dung liên quan đến nhà cung cấp dịch vụ trên mạng xã hội.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Mạng xã hội Jaemy.net có trách nhiệm tiếp nhận khiếu nại và có biện pháp xử lý kịp thời để giải quyết những mâu thuẫn xảy ra giữa thành viên và bên thứ ba.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Nhà cung cấp dịch vụ trên mạng xã hội Jaemy.net có trách nhiệm cung cấp đầy đủ thông tin liên quan đến quá trình giao dịch và có nghĩa vụ bồi thường theo thỏa thuận với thành viên mạng xã hội Jaemy.net nếu lỗi thuộc về nhà cung cấp dịch vụ",
                                childContent: [""]
                            },
                            {
                                childTerm: "Mọi hành động lừa đảo, gây mâu thuẫn trên mạng xã hội Jaemy.net đều bị lên án và phải chịu trách nhiệm trước pháp luật.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Nếu thông qua hình thức thỏa thuận mà vẫn không thể giải quyết được mâu thuẫn phát sinh thì sẽ chuyển cho cơ quan nhà nước có thẩm quyền can thiệp nhằm đảm bảo lợi ích hợp pháp của các bên. Đồng thời mạng xã hội Jaemy.net có trách nhiệm hỗ trợ Cơ quan pháp luật bằng việc cung cấp thông tin về các bên quá trình điều tra.",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 5,
        title: 'THÔNG TIN BỔ SUNG',
        cateContent: [
            {
                id: 1,
                title: "Điều 20. Chỉnh sửa bổ sung thỏa thuận",
                content: [
                    {
                        termContent: "Các điều khoản quy định tại Thỏa thuận sử dụng mạng xã hội Jaemy.net được quy định trên website, có thể được cập nhật, chỉnh sửa bất cứ lúc nào mà không cần phải thông báo trước tới người sử dụng. Jaemy.net sẽ công bố rõ trên Website, diễn đàn về những thay đổi, bổ sung đó, người dùng có nghĩa vụ cập nhật thường xuyên.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 2,
                title: "Điều 21. Xung đột pháp luật",
                content: [
                    {
                        termContent: "Trong trường hợp một hoặc một số điều khoản Thỏa thuận sử dụng này xung đột với các quy định của luật pháp và bị Tòa án tuyên là vô hiệu, điều khoản đó sẽ được chỉnh sửa cho phù hợp với quy định pháp luật hiện hành, và phần còn lại của Thỏa thuận sử dụng vẫn giữ nguyên giá trị.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 3,
                title: "Điều 22. Cảnh báo",
                content: [
                    {
                        termContent: "Mạng xã hội hội Jaemy.net cảnh báo cho tất cả người sử dụng các rủi ro có thể gặp phải khi lưu trữ, trao đổi và chia sẻ thông tin trên mạng. Thông tin của khách hàng có thể bị tin tặc tấn công và mạo danh, đề đảm bảo an toàn thành viên không nên đăng tải những hình ảnh cá nhân nhạy cảm, những thông tin về bí mật đời tư, bí mật kinh doanh, thông tin về tài khoản ngân hàng...",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Bạn sở hữu tất cả các nội dung và thông tin bạn đăng trên Jaemy.net và bạn có thể kiểm soát việc chia sẻ thông tin đó thông qua thông tin cá nhân và cập nhật thông tin cá nhân . Ngoài ra:",
                        childTerm: [
                            {
                                childTerm: "Đối với những nội dung có quyền sở hữu trí tuệ như ảnh, Audio và video (nội dung IP), bạn mặc định cho chúng tôi bản quyền của bạn: bạn cấp cho chúng tôi một giấy phép không độc quyền, có thể chuyển nhượng, có thể cấp phép lại, miễn phí, toàn cầu để sử dụng mọi nội dung IP mà bạn đăng trên hoặc liên quan tới Jaemy.net. Và kết thúc khi bạn xóa nội dung IP hoặc tài khoản của bạn trừ khi nội dung của bạn được chia sẻ với người khác và những người đó chưa xóa nội dung đó.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Khi bạn xóa nội dung IP, thao tác đó tương tự như làm rỗng thùng rác trên máy tính. Tuy nhiên, bạn hiểu rằng nội dung bị xóa có thể vẫn còn trong các bản sao lưu trong một khoảng thời gian hợp lý (nhưng sẽ không khả dụng với những người khác).",
                                childContent: [""]
                            },
                            {
                                childTerm: "Khi bạn công bố nội dung hoặc thông tin bằng cách điền thông tin cá nhân hoặc cập nhật thông tin cá nhân, có nghĩa là bạn đang cho phép mọi người bao gồm những người là thành viên của Jaemy.net, truy cập, sử dụng thông tin đó.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Chúng tôi luôn tiếp nhận phản hồi hoặc các đề xuất khác của bạn về Jaemy.net nhưng bạn cần hiểu rằng chúng tôi có thể sử dụng các phản hồi và đề xuất đó mà không có nghĩa vụ phải đền bù cho bạn (cũng như bạn không có nghĩa vụ phải cung cấp các phản hồi và đề xuất đó).",
                                childContent: [""]
                            }
                        ]
                    },
                    {
                        termContent: "Ø  An toàn",
                        childTerm: [{
                            childTerm: "Chúng tôi nỗ lực hết sức mình để tạo an toàn cho Jaemy.net nhưng chúng tôi không thể đảm bảo điều đó. Chúng tôi cần sự giúp đỡ của bạn để tạo an toàn cho Jaemy.net, với các cam kết sau từ phía bạn:",
                            childContent: [
                                "Bạn sẽ không vi phạm quy định như spam trên Jaemy.net", 
                                "Bạn sẽ không tham gia vào hoạt động tiếp thị bất hợp pháp như quảng cáo đồi trụy… trên Jaemy.net", 
                                "Bạn sẽ không tải lên virus hoặc mã độc hại khác.", 
                                "Bạn sẽ không hack thông tin đăng nhập hoặc tài khoản của người khác.", 
                                "Bạn sẽ không hăm dọa hoặc quấy rối bất kỳ người dùng nào.", 
                                "Bạn sẽ không đăng nội dung: có ngôn từ kích động thù địch, mang tính đe dọa hay khiêu dâm; kích động bạo lực hoặc chứa ảnh khỏa thân hoặc hình ảnh bạo lực hay nội dung bạo lực vô cớ.", 
                                "Bạn sẽ không dùng Jaemy.net để làm bất kỳ điều gì bất hợp pháp, sai trái, độc hại hoặc phân biệt đối xử.", 
                                "Bạn sẽ không làm bất kỳ điều gì có thể làm vô hiệu, quá tải hoặc ảnh hưởng đến hệ thống của Jaemy.net."
                            ]
                        }]
                    },
                    {
                        termContent: "Ø  Đăng ký và bảo mật tài khoản",
                        childTerm: [
                            {
                                childTerm: "Người dùng Jaemy.net phải cung cấp đầy đủ thông tin bắt buộc khi đăng ký tài khoản Sau đây là một số cam kết của bạn đối với chúng tôi liên quan đến việc đăng ký và duy trì bảo mật tài khoản của bạn:",
                                childContent: [
                                    "Bạn sẽ không cung cấp mọi thông tin cá nhân sai lệch trên Jaemy.net hoặc tạo tài khoản cho bất kỳ ai khác ngoài bạn mà không được phép.",                            
                                    "Bạn sẽ không tạo nhiều hơn một tài khoản cá nhân.",
                                    "Nếu chúng tôi vô hiệu tài khoản của bạn, bạn sẽ không tạo một tài khoản khác mà không có sự cho phép của chúng tôi.",
                                    "Bạn sẽ không dùng Jaemy.net nếu bạn bị kết án phạm tội tình dục.",
                                    "Bạn sẽ duy trì thông tin liên hệ của mình chính xác và cập nhật.",
                                    "Bạn sẽ không chia sẻ mật khẩu của mình, không cho bất kỳ ai truy cập vào tài khoản của mình hoặc làm bất kỳ điều gì có thể gây nguy hiểm cho việc bảo mật tài khoản của mình.",
                                    "Bạn sẽ không chuyển nhượng tài khoản (bao gồm mọi Trang) cho bất kỳ ai mà không có văn bản cho phép trước hết của chúng tôi."
                                ]
                            },
                            {
                                childTerm: "Nếu bạn chọn một tên người dùng hoặc tên định danh tương tự cho tài khoản hoặc Trang của bạn, chúng tôi bảo lưu quyền xóa hoặc xác nhận lại tên đó nếu chúng tôi tin là phù hợp (chẳng hạn như khi chủ sở hữu thương hiệu phàn nàn về tên người dùng không liên quan chặt chẽ tới tên thật của người dùng).",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            },
            {
                id: 4,
                title: "Điều 23. Việc cập nhật của Chính sách bảo mật",
                content: [
                    {
                        termContent: "Mạng xã hội Jaemy.net sẽ chỉnh sửa Chính sách bảo mật này vào bất kỳ thời điểm nào khi cần thiết, bản Chính sách bảo mật cập nhật sẽ được công bố trên website Jaemy.net và sẽ được ghi ngày để người dùng nhận biết được bản mới nhất.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 5,
                title: "Điều 24. Địa chỉ liên hệ",
                content: [
                    {
                        termContent: "Nếu bạn có bất kì câu hỏi nào liên quan đến quá trình xử lý thông tin của bạn hay Chính sách bảo mật này, vui lòng liên hệ với chúng tôi qua số điện thoại trên trang chủ của trang web hoặc tại địa chỉ: Số Phòng 502, tòa nhà Số 01 ngõ 120 đường Trường Chinh, Phường Phương Mai, Quận Đống Đa, Thành phố Hà Nội, Việt Nam.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 6,
                title: "Điều 25: Thỏa thuận cung cấp sử dụng mạng xã hội Jaemy.net có giá trị từ ngày 01 tháng 07 năm 2021.",
                content: [
                    {
                        termContent: "",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            }
        ]
    }
]

export const TermEn: CateTerm[] = [
    {
        id: 1,
        title: '',
        cateContent: [
            {
                id: 1,
                title: "Article 1. Principles of Hanzii.net Account registration",
                content: [
                    {
                        termContent: "1. You are solely responsible for all Hanzii.net account registration information, Hanzii.net account modification and supplement information.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. In addition to complying with the Terms of Use, you must strictly abide by Hanzii.net's other agreements, rules, and procedures posted publicly on the Hanzii.net website.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. You must not put an account in the name of a celebrity, the names of Party and State leaders, the name of an individual, criminal organization, reactionary, terrorist or account of an unfair meaning, contrary to fine customs and traditions.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
        
                ]
        
            },
            {
                id: 2,
                title: "Article 2. Registration and stop using the service",
                content: [
                    {
                        termContent: "1. Before registering an account, you confirm that you have read, understood and agree to all the provisions of the Hanzii.net Terms of Use.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. When registering an account you must agree to provide honest, complete and up-to-date information when there is a change in reality. Information collection, use and security issues, please see the Privacy Policy.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. When registering, you will create a Hanzii.net account with username and a password. You agree to be responsible for protecting your username and password to prevent unauthorized use and promptly notify Hanzii.net of any unauthorized use. Creating an account on your phone will require data transfer, data transmission costs if any will be borne by you.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. You can cancel the registration if you do not want to continue using the service of Hanzii.net. Hanzii.net may terminate your registration or prohibit you from accessing certain parts of the service if there is evidence that you have violated the Agreement.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
        
                ]
        
            },
            {
                id: 3,
                title: "Article 3. Prohibited contents to be exchanged and shared on social networks",
                content: [
                    {
                        termContent: "1. Absolutely prohibit all acts: propaganda, sabotage and misrepresentation of government, institutions, and government policies; inciting violence, propagating war, causing hatred between nations and people of countries; inciting lust, depravity, crime, social evils, superstition, undermining the nation's fine customs and traditions, revealing state secrets, military secrets, security, economics, and foreign and other secrets are prescribed by law. In case of detection, not only the account is deleted but we can also provide that person's information to the authorities to handle in accordance with the law. ",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. There are no acts, attitudes that harm the reputation of the company's products issued in any form or any method. All violations will be deprived of all related rights to the service or before the law if necessary.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. Strictly prohibiting the organization of forms of betting, gambling or agreements related to money, cash and in kind.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. It is absolutely forbidden to insult or ridicule other people in any form (ridicule, decry, religious discrimination, gender, ethnicity, etc.).",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. It is forbidden to call riot organizations, to ban the posting and exchange of information about protests and riot.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. Prohibit reposting sources from personal blogs, forums, readers writing in newspaper articles, ...",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. Prohibit posting articles from foreign elements sites such as BBC Vietnamese, VOA Vietnamese ....",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "8. The content does not go against the Vietnamese fine customs and practices and violates the laws of the Socialist Republic of Vietnam.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: " 9. It is strictly forbidden to bring the Communist Party of Vietnam, the State of Vietnam, political institutions, history and leaders into discussion on unrelated topics.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "10. Prohibiting writing, exchanging superstitious propaganda information.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "11. Absolutely prohibit any act of impersonation, or intentionally impersonating others to mistakenly believe that you are another user. All violations will be handled or stripped of account.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "12. Do not post or transmit any information that is illegal, deceptive, defamatory, humiliating, obscene, pornographic, offensive, threatening, abusive, hateful, inciting… or contrary to ethical standards The common virtue of society of any kind. The content describes meticulously acts of violence, violence, murderous horror; post and broadcast offensive images with a lack of humanity; providing content, images, nudity with an aphrodisiac, lack of aesthetics, not in accordance with Vietnamese traditions and customs will be completely banned.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "13. We respect the right to freedom of expression, but also reserve the right to reduce, or remove part or all of your articles if it considers the post or any information submitted in violation of those The foregoing, regardless of whether the breach is clear or implied.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "14. It is strictly prohibited to propagate any advertising messages, invitations, chain letters, investment opportunities or any other form of commercial communication that users do not expect, spam or spam messages. , send or transmit any information, software, or other materials that violate or infringe on the rights of others, including materials that violate privacy or publicity, or whether protected by copyright, trade name or other proprietary right, or derivative works without the permission of the owner or other legal right. If detected violations, we will permanently lock the account.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "15. You will not send or transmit any information or software not owned by you unless it's free software. Do not send any information or software containing any viruses, Trojans, bugs or other harmful components. Do not use or provide information that violates the regulations on intellectual property, e-commerce transactions and other provisions of current law.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "16. Do not compromise, access, use, or attempt to compromise, gain access to, access to or use of any part of our server, or any data area without us. allow, prohibit any misuse of the system error to gain personal gain that causes damage to a service provider, institution or other organization for which you are not authorized to declare such relationship for the purpose of axis Personal gain causes confusion for members participating in social networks. You will not create fake personal information, other organizations, businesses; False information infringes upon the legal rights and interests of organizations, businesses and individuals.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "17.  No rights or actions are required to restrict or prohibit any other user from using and enjoying the website: Hanzii.net. ",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "18. It is forbidden to spread press works, literature, art, music, movies, publications that violate the provisions of law.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 4,
                title: "Article 4. Mechanism for handling members who violate the agreement",
                content: [
                    {
                        termContent: "1.Principles of handling",
                        childTerm: [
                            {
                                childTerm: "If the User violates the provisions of the Agreement on the provision and use of social networking services or otherwise creates a risk or liability possible for EUP Technology Co., Ltd., the User will be reminded, warned, applied to post treatment measures, account lockout term or permanently.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Hanzii.net account lockout penalty is limited or permanent, which means that users cannot use other products when accessing from locked accounts.",
                                childContent: [""]
                            },
                            {
                                childTerm: "If Hanzii.net account is permanently locked, all rights of the Account Holder will be permanently locked.",
                                childContent: [""]
                            },
                            {
                                childTerm: "User's violations are not listed below, depending on each specific case, Hanzii.net Management reserves the right to impose appropriate penalties according to the sanctions specified in the agreement. pros.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Sanctioning forms:",
                                childContent: [
                                    "1. Level of sanctioning level 1: Lock account 10 days.", 
                                    "2. Level 2 sanction: Lock account for 30 days.", 
                                    "3. Level of sanctioning level 3: Lock account 120 days.", 
                                    "4. Special level sanction: Permanent account lockout."
                                ]
                            },
                            {
                                childTerm: "All posts that violate the regulations may be deleted, moved categories, filtered out ad content, replaced inappropriate words, suspended display and censored depending on the level of violations. Hanzii.net can send a warning notice of infringing content and ask Users to immediately remove and filter infringing content. If the User does not remove, filter out the infringing content, Hanzii.net Management Board will handle the post and lock the User's account.",
                                childContent: [""]
                            },
                            {
                                childTerm: "The board has full authority to identify and handle posts / members who violate the rules. The handling log will be archived for a minimum of two years.",
                                childContent: [""]
                            }
                        ]
                    },
                    {
                        termContent: "2.  Sanctioning forms ",
                        childTerm: [
                            {
                                childTerm: "Special-level and permanent lock sanctions applied include but are not limited to the following acts:",
                                childContent: [
                                    "The user acts to take advantage of Hanzii.net to fight against the Socialist Republic of Vietnam. This behavior includes, but is not limited to, the User naming an Account / Member Name with the same name as the great men, heroes of the nation, Party and State leaders, or users who have use images, videos, speeches, etc. that contain information to discuss political issues or disclose state secrets of the Socialist Republic of Vietnam. ",
                                    "Pornographic information, images, videos: Users post pictures, audio, porn videos, sex chat or propagate pornography. Betting, Gambling Information: Taking advantage of Hanzii.net, users post information, images, audio and video content containing information to organize betting, gambling or related agreements. to money, cash, in kind. Spreading fraudulent information: Using text, images, audio or video containing deceptive information: official or impersonation of organizations or individuals; fraud, fraudulent property of others or Hanzii.net account, accumulated points."
                                ]
                            },
                            {
                                childTerm: "Level 3 sanction, 120 days account lock applies including but not limited to the following acts:",
                                childContent: [
                                    "Vandalizing social network system Hanzii.net: Users take advantage of using the product to penetrate into the server system to sabotage the product or prevent access to information. Users use technical tools to increase activity points, items or to hang up, spam chat.",
                                    "Use the Discussion Forum or Group Activity to entice outside practical meetings to commit violations of the law. "
                                ]
                            },
                            {
                                childTerm: "Level 2 sanctions, 30 day account lock applied including but not limited to the following acts:",
                                childContent: [
                                    "Communication behavior: mild pornography, spamming, inciting other Members to Discussion Groups to disrupt or propagate infringing information. Advertising: In the member's name, status, self-explanatory containing advertising information or the use of text, images, audio, or video and advertising information without written consent version of EUP Technology Ltd. In addition, members using products for the purpose of advertising, propagating, trading and propagating prohibited goods and services, in this case depending on the severity, a penalty of 2 or penalty will be imposed.", 
                                    "Invasion of privacy: Using the personal image of others, disclosing personal data and other information such as identity, address, telephone number without consent and conducting a harassing phone call harass or incite others to harass. Assaulting another person: Using images, information, audio or video, insulting, misrepresenting, slandering, mocking or insulting information to organizations and individuals.", 
                                    "Copyright infringement: Stealing content, copying or quoting without permission to use the copyrights of others."]
                            },
                            {
                                childTerm: "Penalties of first-degree and 10-day account lock-outs include but are not limited to the following acts:",
                                childContent: ["Comment wrong topic, spam on the forum."]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        title: 'RIGHTS AND RESPONSIBILITIES OF THE PARTIES',
        cateContent: [
            {
                id: 1,
                title: "Article 5. Rights of social network service users Hanzii.net",
                content: [
                    {
                        termContent: "Use the services of Hanzii.net social network except those prohibited by the law.",
                        childTerm: [
                            {
                                childTerm: "1. You have the right to change and supplement your account information, password, identification documents, registered email.",
                                childContent: [""]
                            },
                            {
                                childTerm: "2. You are instructed on how to set a secure password; enter important information to protect accounts; Use linked account to login account. You are entitled to donate your Hanzii.net account to others.",
                                childContent: [""]
                            },
                            {
                                childTerm: "3. The gift granted to the account is only applicable to accounts that have fully and accurately registered account information in accordance with Hanzii.net Terms of Use. ",
                                childContent: [""]
                            },
                            {
                                childTerm: "4. To be protected confidentiality of private information and personal information in accordance with the law.",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            },
            {
                id: 2,
                title: "Article 6. Responsibilities of social network service users",
                content: [
                    {
                        termContent: "1. In order to receive support from Hanzii.net, your account must register with all the honest and accurate information. If there is a change or addition of information, please update immediately to us. You warrant that your status information is current, complete, truthful and accurate and that you are responsible for all the information you provide.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. You are responsible for the security of account information including: Password, identification documents, Email protecting accounts and linked accounts. If the above information is disclosed in any way, you must accept the risks arising Hanzii.net will base on the existing information in the account to make a decision on the account owner. If there is a dispute and will not be responsible for any losses incurred. Information Identity papers registered in the account are the most important information to prove the account owner.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. You agree to immediately notify Hanzii.net of any unauthorized use of your account and password or any acts of circumventing security systems. You also ensure that you always exit your account after each use.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. Comply with the agreement to manage, provide and use social networking services.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. Be responsible for the content of information that they store, provide, transmit on social networks, distribute information via a direct link established by themselves.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. When detecting errors, problems affecting the normal operation of Hanzii.net, please notify us via phone number 0969 758 516.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. You undertake to undertake the responsibility of ensuring the lawful use of digital information content posted on the Internet and telecommunication networks.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "8. You must strictly comply with the provisions of Article 2 of this agreement on prohibited behaviors. If one or more violations are committed, depending on the level of violations Hanzii.net will permanently lock your account, stripping all your rights.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "9. Comply with regulations on information security, information security and other relevant regulations in Decree 72/2013 / ND-CP and Decree 27/2018 / ND-CP , providing and using internet services and online information.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "10. Perform other responsibilities as per the law.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 3,
                title: "Article 7. Rights of EUP Technology Co., Ltd",
                content: [
                    {
                        termContent: "1. If the User provides any information that is dishonest, inaccurate, or if EUP Technology Co., Ltd. has grounds to suspect that such information is inaccurate or if the User violates any any terms of Hanzii.net's agreements to use or use other products and services specified on the website, EUP Technology Co., Ltd reserves the right to terminate or delete the User's account. without the user's consent and without any liability to the User.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. All violations of the Account Holder during the use of Hanzii.net products, EUP Technology Co., Ltd. reserves the right to apply sanctions according to these terms and agreements of use such as removing, deleting content. violate, have the right to deprive the Account Holder of all rights to use the product and will require authorities to prosecute the Account Holder before the law if necessary.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. When detecting violations such as fraud, vandalism, spreading prohibited content or any breach of the law through the use of Hanzii.net, EUP Technology Co., Ltd. reserves the right to use the information. individuals provided by users when registering for accounts to transfer to functional agencies for settlement according to law provisions.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. EUP Technology Co., Ltd. reserves the right to refuse to support and resolve accounts that register information inaccurately according to regulations and for accounts that violate the account security responsibilities prescribed in This deal.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. The right to apply the provisions of terms of use of additional services without the User's consent.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. Technology Co., Ltd. reserves the right to commercialize Hanzii.net services, but EUP Technology Co., Ltd. strictly adheres to this agreement. EUP Technology Co., Ltd. absolutely does not resell User's content and information. In case EUP Technology Co., Ltd charges a service fee, EUP Technology Co., Ltd. must notify the User and the User must voluntarily agree.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. EUP Technology Co., Ltd. reserves the right to maintain and upgrade the system to better serve. Maintenance or upgrades may result in disruption of service use or unqualified service quality during maintenance or upgrading.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 4,
                title: "Article 8. Responsibilities of EUP Technology Ltd",
                content: [
                    {
                        termContent: "1. Is responsible for supporting the account holder during using Hanzii.net.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. Receiving and resolving user complaints in case of using Hanzii.net, but we only support, receive and resolve accounts registered with full information, the main body.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. Be responsible for protecting the personal information of Hanzii.net account holders, do not sell or exchange this information with third parties, except where required by law or accepted by account holders.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. Publicize the agreement on provision and use of social networking services;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. Are taken to protect private and personal information of users; notify users about their rights, responsibilities and risks when storing, exchanging and sharing information online;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. Ensuring the user's right to decide when permitting his or her personal information to be provided to other organizations, businesses and individuals;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. Not to proactively provide public information with content violating the provisions of Article 5 of Decree 72/2013 / ND-CP stipulating the management, provision and use of internet services and online information;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: " 8. Coordinate with competent state management agencies to remove or prevent information with content violating the provisions of Article 5 of Decree 72/2013 / ND-CP stipulating the management, supply and use internet services and online information upon request;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "9. Providing personal information and private information of users related to terrorist activities, crimes, and law violations at the request of competent state management agencies;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "10. Having at least 01 server system located in Vietnam that satisfies the inspection, examination, storage and provision of information at the request of competent state management agencies and resolving user complaints against with the provision of services in accordance with the Ministry of Information and Communications; ",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "11. Registration, storage and management of personal information of the person who establishes a personal website and other information providers on social networks shall comply with the regulations of the Ministry of Information and Communications. Ensuring that only individuals who have provided complete and accurate personal information in accordance with regulations are allowed to set up personal websites or provide information on social networks;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "12. Report as prescribed and subject to inspection and examination by the competent state management agencies.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        title: 'PRIVACY PROTECTION POLICY',
        cateContent: [
            {
                id: 1,
                title: "Article 9. Purpose of collecting Personal Information",
                content: [
                    {
                        termContent: "1. To manage the posting of information and censorship of information publicly.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. To manage the flow of users using the service, the number of users;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. To manage marketing activities, provide information to users such as sending the latest updates on forum services and new features related to our products and services; ",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "4. Providing solutions and changing services to serve users needs;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "5. Managing, analyzing and evaluating data related to system data;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "6. Receive information, comments, suggestions and complaints from members to improve service quality;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "7. Contact members to resolve member requests;",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 2,
                title: "Article 10. Methods of information collection",
                content: [
                    {
                        termContent: "1. Type of information collected.",
                        childTerm: [
                            {
                                childTerm: "Personal information",
                                childContent: [""]
                            },
                            {
                                childTerm: "Contact information such as phone number, mailing address, email address, fax number;",
                                childContent: [""]
                            }
                        ]
                    },
                    {
                        termContent: "2. Content of collected information",
                        childTerm: [
                            {
                                childTerm: "First and last name;",
                                childContent: [""]
                            },
                            {
                                childTerm: "Date of birth;",
                                childContent: [""]
                            },
                            {
                                childTerm: "Identity card or passport number, date of issue, place of issue;",
                                childContent: [""]
                            },
                            {
                                childTerm: "Phone number, email address.",
                                childContent: [""]
                            }
                        ]
                    },
                    {
                        termContent: "In case the internet user is under 14 years old and has no passport, the legal guardian decides to register his / her personal information in order to show his / her permission and takes responsibility before law for such registration.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Users have the right to refuse or fail to provide the required information. In that case, we cannot guarantee the users the complete and quality services. We will ask you for personal information when you register to be a member of our website (Acount). If you use this information for purposes other than collecting it, we will ask your permission before using this information.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 3,
                title: "Article 11. Methods of information collection",
                content: [
                    {
                        termContent: "Hanzii.net social network, collecting the Personal Information of members mentioned in Article 10 above via the website via the Member Registration Form provided publicly at Hanzii.net.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 4,
                title: "Article 12. Storage period of collected information",
                content: [
                    {
                        termContent: "Hanzii.net social network will store a minimum of 02 (two) years for account information, login time, logout, user's IP address and information processing log posted. In all cases, member's personal information will be kept confidential on Hanzii.net server.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 5,
                title: "Article 13. Publicity and modification of collected information",
                content: [
                    {
                        termContent: "1. We grant members the right to self-edit public information on the website. We only cancel or hide information when members request or information violates the Regulations of social networks Hanzii.net",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "2. We publish personal information collected from users on Hanzii.net website and related websites in order to provide the best service to users and ensure the effectiveness of the service.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "3. We may be required to provide users personal information to government agencies, authorities for security, customs, immigration purposes and statutory purposes. other to the extent required or statutory.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 6,
                title: "Article 14. Address of unit collecting and managing personal information",
                content: [
                    {
                        termContent: "EUP Technology Ltd.",
                        childTerm: [
                            {
                                childTerm: "Headquarters: Room 403, Building No. 01, Lane 120, Truong Chinh Street, Phuong Mai Ward, Dong Da District, Hanoi City, Vietnam.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Tel: 0969 758 516",
                                childContent: [""]
                            },
                            {
                                childTerm: "Email: trinhnguyen.se@gmail.com",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            },
            {
                id: 7,
                title: "Article 15. Commitment to information security",
                content: [
                    {
                        termContent: "We use appropriate security measures to protect unauthorized access or modification, disclosure or destruction of data. These include consideration of internal data collection, storage, processing, and security practices, as well as physical level security measures to protect unauthorized access to storage systems. Personal data storage.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "We only allow authorized Hanzii.net employees to access personal information; people who need to know this information to operate, develop and improve our services. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination of employment and criminal prosecution, when they violate these obligations.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Each member is solely responsible for securing Hanzii.net's social network account password, committing to not disclosing or providing the member's account security information to anyone or any other third party. Information about your account is located on a very secure server and is protected by a firewall.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Privacy regulations may have certain shortcomings, due to technical problems, or force majeure issues and Hanzii.net is not responsible for damages caused by such errors. out. Always be careful and protect your personal information.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "We will be required to disclose information if it is necessary to protect the property as well as the safety of social networks Hanzii.net or others as well as prevent illegal activities and violations of regulations. intends to greatly influence the other members. Or when law enforcement agencies ask for information.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "The poster will be responsible for the authenticity, and responsible for any legal disputes that occur with respect to its information.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 8,
                title: "Article 16. Policies to protect personal and private information of social network service users",
                content: [
                    {
                        termContent: "We disclose the information specified in Articles 9, 10, 11, 12, 13, 14, 15 of this agreement so that users know the privacy policy of Hanzii.net. In order to protect the personal and private information of social network users, we use archiving software, information security software and firewall systems very firmly. Customers are not stolen.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        title: 'RESOLUTION DISPUTE, COMPLAINTS',
        cateContent: [
            {
                id: 1,
                title: "Article 17. Dispute settlement mechanism",
                content: [
                    {
                        termContent: "Any dispute arising between Hanzii.net social network and member or supplier will be settled on a negotiated basis. If the agreement is not reached as expected, either party has the right to bring the case to Court to resolve.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "When disputes arise between members or between members and direct service providers, the website management board will be responsible for providing members with information about service providers, actively supporting members. or representative of members to protect their legal rights and interests.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "In the event of a fault caused by Hanzii.net we will immediately apply the necessary measures to ensure the rights of users.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 2,
                title: "Article 18. Dispute settlement process",
                content: [
                    {
                        termContent: "Step 1: Social network members complain about the service or notify the disputes between the parties via email: trinhnguyen.se@gmail.com",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Step 2: The member rights protection department of social network Hanzii.net will receive member complaints, depending on the nature and severity of the complaint, Hanzii.net will take specific measures to support. Member support to resolve that dispute.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Step 3: In a school that is beyond the capabilities and authority of Hanzii.net, the board of directors will ask members to bring this case to the state agency competent to resolve according to law.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "Time to resolve disputes:",
                        childTerm: [
                            {
                                childTerm: "Where within the capabilities and authority of Hanzii.net we are committed to resolving member disputes within 5 working days.",
                                childContent: [""]
                            },
                            {
                                childTerm: "In case outside Hanzii.net's capabilities and authority, the time for settling disputes depends on the nature of the case and competent state agencies.",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            },
            {
                id: 3,
                title: "Article 19. Roles and responsibilities of the parties in resolving disputes",
                content: [
                    {
                        termContent: "",
                        childTerm: [
                            {
                                childTerm: "Hanzii.net social network will support its members by providing sufficient information and content related to social service providers.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Hanzii.net social network is responsible for receiving complaints and taking timely measures to resolve conflicts between members and third parties.",
                                childContent: [""]
                            },
                            {
                                childTerm: "Hanzii.net social service provider is responsible for providing full information related to the transaction process and is obliged to pay compensation according to the agreement with Hanzii.net social network member if the error belongs to about the service provider.",
                                childContent: [""]
                            },
                            {
                                childTerm: "All fraudulent actions, conflicts on social networks Hanzii.net are condemned and responsible before the law.",
                                childContent: [""]
                            },
                            {
                                childTerm: "If the agreement does not resolve through the agreement, it will be transferred to the competent state agency to intervene to ensure the legitimate interests of the parties. At the same time, Hanzii.net social network is responsible for supporting the Legal Department by providing information about the parties to the investigation process.",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            }, 
        ]
    },
    {
        id: 5,
        title: 'ADDITIONAL INFORMATION',
        cateContent: [
            {
                id: 1,
                title: "Article 20. Amending and supplementing the agreement",
                content: [
                    {
                        termContent: "The terms specified in the Hanzii.net social networking agreement are provided on the website, which can be updated and modified at any time without prior notice to the user. Hanzii.net will publish clearly on the Website, the forum of changes and additions, users are obliged to update regularly.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 2,
                title: "Article 21. Conflicting laws",
                content: [
                    {
                        termContent: "In the event that one or more of these Terms of Use conflict with the laws and are declared invalid by the Court, that provision will be amended to conform to the applicable laws, and the rest of the Terms of Use remain valid.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 3,
                title: "Article 22. Warnings",
                content: [
                    {
                        termContent: "Social network Hanzii.net warns all users of the risks that may be encountered when storing, exchanging and sharing information online. Customer information can be hacked and impersonated, to ensure member safety should not post sensitive personal images, information about privacy, business secrets and information. bank account information ...",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    },
                    {
                        termContent: "You own all the content and information you post on Hanzii.net and you can control the sharing of that information through your personal information and updating your personal information. Besides:",
                        childTerm: [
                            {
                                childTerm: "For intellectual property content such as photos, audios and videos (IP content), you give us your copyright by default: you grant us a non-exclusive, transferable license concession, sublicense, free, worldwide to use any IP content you post on or related to Hanzii.net. And end when you delete your IP content or account unless your content is shared with others and those people haven't deleted that content yet.",
                                childContent: [""]
                            },
                            {
                                childTerm: "When you delete IP content, the operation is similar to emptying the trash on a computer. However, you understand that the deleted content may remain in the backup for a reasonable amount of time (but will not be available to others).",
                                childContent: [""]
                            },
                            {
                                childTerm: "When you publish content or information by filling out personal information or updating personal information, it means that you are allowing people including those who are members of Hanzii.net to access, use, use that information.",
                                childContent: [""]
                            },
                            {
                                childTerm: "We always receive your feedback or other suggestions about Hanzii.net but you need to understand that we can use those feedback and suggestions without obligation to compensate you (as well as You are not obligated to provide such feedback and suggestions).",
                                childContent: [""]
                            }
                        ]
                    },
                    {
                        termContent: "Ø Safe",
                        childTerm: [
                            {
                                childTerm: "We do our best to make Hanzii.net safe, but we cannot guarantee it. We need your help to make Hanzii.net safe, with the following commitments on your part:",
                                childContent: [
                                    "You will not violate regulations like spam on Hanzii.net", 
                                    "You will not engage in illegal marketing activities such as pornography or advertising on Hanzii.net", 
                                    "You will not upload viruses or other malicious code.", 
                                    "You will not hack someone else's login or account information.", 
                                    "You will not threaten or harass any user.", 
                                    "You will not post content: contain hate speech, be threatening or obscene; incite violence or contain nudity or graphic violence or gratuitous violence. ", 
                                    "You will not use Hanzii.net to do anything illegal, wrong, malicious or discriminatory. ", 
                                    "You will not do anything that could invalidate, overload or affect Hanzii.net's system."
                                ]
                            }
                        ]
                    },
                    {
                        termContent: "Ø Register and secure your account",
                        childTerm: [
                            {
                                childTerm: "Hanzii.net users must provide the required information when registering for an account. Following are some of your commitments to us regarding the registration and maintenance of your account security:",
                                childContent: [
                                    "You will not provide any false personal information on Hanzii.net or create an account for anyone other than you without permission.", 
                                    "You will not create more than one personal account. If we disable your account, you will not create another account without our permission.", 
                                    "You will not use Hanzii.net if you are convicted of a sex offense. You will maintain your contact information accurate and up to date.", 
                                    "You will not share your password, give anyone access to your account, or do anything that could jeopardize your account security.", 
                                    "You will not assign your account (including any Pages) to anyone without our first written permission."
                                ]
                            },
                            {
                                childTerm: "If you choose a similar username or identifier for your account or Site, we reserve the right to remove or reconfirm that name if we believe it is appropriate (such as when the trademark owner Complaints about usernames are not strictly related to the user's real name).",
                                childContent: [""]
                            }
                        ]
                    }
                ]
            },
            {
                id: 4,
                title: "Article 23. Update of the Privacy Policy",
                content: [
                    {
                        termContent: "Hanzii.net social network will revise this Privacy Policy at any time when necessary, updated Privacy Policy will be published on Hanzii.net website and will be dated for users to identify. get the latest version.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 5,
                title: "Article 24. Contact address",
                content: [
                    {
                        termContent: "If you have any questions regarding the processing of your information or this Privacy Policy, please contact us via the phone number on the website's home page or at: Room Number 403, Building No. 01, Lane 120, Truong Chinh Street, Phuong Mai Ward, Dong Da District, Hanoi City, Vietnam.",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            },
            {
                id: 6,
                title: "Article 25: Agreement of using social network Hanzii.net is valid from January 6, 2018.",
                content: [
                    {
                        termContent: "",
                        childTerm: [{
                            childTerm: "",
                            childContent: [""]
                        }]
                    }
                ]
            } 
        ]
    }
]