import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ModalModule } from './modules/modal/modal.module';
import { DrawModule } from './modules/draw/draw.module';

import { SafePipe } from './pipes/safe-value.pipe';
import { FirstcasePipe } from './pipes/firstcase.pipe';
import { UserPremiumPipe } from './pipes/user-premium.pipe';

import { BoxBannerComponent } from './components/box-banner/box-banner.component';
import { DetailWordComponent } from './components/detail-word/detail-word.component';
import { AddCateComponent } from './components/add-cate/add-cate.component';
import { AddNoteComponent } from './components/add-note/add-note.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { PaginateTotalComponent } from './components/paginate-total/paginate-total.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ModalNotifyComponent } from './components/modal-notify/modal-notify.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { AddFeedbackComponent } from './components/add-feedback/add-feedback.component';
import { DrawAreaComponent } from './components/draw-area/draw-area.component';
import { TimePipe } from './pipes/time.pipe';
import { ActionComponent } from './components/action/action.component';

@NgModule({
    declarations: [
        BoxBannerComponent,
        DetailWordComponent,
        SafePipe,
        FirstcasePipe,
        UserPremiumPipe,
        AddCateComponent,
        AddNoteComponent,
        ConfirmComponent,
        PaginateTotalComponent,
        PaginationComponent,
        ModalNotifyComponent,
        FeedbackComponent,
        AddFeedbackComponent,
        DrawAreaComponent,
        TimePipe,
        ActionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ModalModule,
        DrawModule
    ],
    exports: [
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        BoxBannerComponent,
        DetailWordComponent,
        SafePipe,
        ModalModule,
        DrawModule,
        FirstcasePipe,
        UserPremiumPipe,
        AddCateComponent,
        AddNoteComponent,
        ConfirmComponent,
        TimePipe,
        PaginateTotalComponent,
        PaginationComponent,
        ModalNotifyComponent,
        FeedbackComponent,
        AddFeedbackComponent,
        DrawAreaComponent,
        ActionComponent
    ]
})
export class ShareModule {
  
}
