import { Component, OnInit } from '@angular/core';
import { BroadcasterService } from '../../services/broadcaster.service';
import { CommonService } from '../../services/common.service';

interface Notify {
    content: string;
    type: string;
}

@Component({
    selector: 'notify',
    templateUrl: './modal-notify.component.html',
    styleUrls: ['./modal-notify.component.css']
})
export class ModalNotifyComponent implements OnInit {

    content: string = '';
    type: string = ''; // success | warning | danger

    constructor(
        private broadcaster: BroadcasterService,
        private commonService: CommonService
    ) { }

    ngOnInit(): void {
        
        this.broadcaster.on('notify').subscribe((res: Notify) => {
            if (res) {
                this.content = res.content;
                this.type = res.type;

                setTimeout(() => {
                    this.content = '';
                    this.type = '';
                }, 2000);
            }
        });
    }
}
