import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { UserService } from '../../../services/user.service';
import { CommonService } from '../../../services/common.service';
import { BroadcasterService } from '../../../services/broadcaster.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    dataLogin!: FormGroup;
    loadDone: boolean = true; 
    submitted: boolean = false; 
    loginSocial: boolean = false;
    notiErr: string = '';
    provider: string = '';

    constructor(
        public commonService: CommonService,
        private userService: UserService,
        // private authService: SocialAuthService ,
        public translate: TranslateService,
        private broadcaster: BroadcasterService
    ) { 
    }

    ngOnInit() {
        this.loadDone = true;
        this.submitted = false;
        this.loginSocial = false;

        this.dataLogin = new FormGroup({
            email     : new FormControl('', [ Validators.required, Validators.email]),
            password  : new FormControl('', [ Validators.required, Validators.minLength(6)])
        });

        // this.authService.authState.subscribe((user) => {
        //     if (user != null) {
        //         if (this.provider) {
        //             let idToken;
        //             let dataSend;
        //             let accessToken;

        //             if (this.provider == 'google') {
        //                 accessToken = user.authToken;
        //                 idToken = user.idToken;
        //             } else if (this.provider == 'facebook') {
        //                 idToken = '';
        //                 accessToken = user.authToken;
        //             }

        //             dataSend = {
        //                 accessToken,
        //                 provider: this.provider,
        //                 idToken,
        //                 language: this.commonService.convertLang()
        //             };
        //             this.userService.loginWidth(dataSend, (res) => {
        //                 this.loginSuccess(res);
        //             });
        //         }

        //         // this.loginSocial = true;
        //     } else {
        //         this.loadDone = true;
        //     }
        // });

    }

    enterLogin(event) {
        if (event.key === 'Enter') {
            this.loginWithEmail();
        }
    }

    loginWithEmail() {
        this.loadDone = false;
        this.submitted = true;

        if (this.dataLogin.invalid) {
            this.loadDone = true;
            return;
        }

        const inforLogin = this.dataLogin.value;
        this.userService.login(inforLogin.email, inforLogin.password, (res) => {
            this.loginSuccess(res);

        });
    }

    loginWithFB() {
        this.loadDone = false;
        this.provider = 'facebook';
        // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    loginWithGG() {
        this.loadDone = false;
        this.provider = 'google';
        // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    loginSuccess(value) {
        this.loadDone = true;
        if (this.commonService.getEnvironment() === 'client') {
            if (value !== null) {
                if ( value.status && value.status === 200) {

                    this.commonService.closeModal('login');
                    this.broadcaster.broadcast('notify', 
                        {
                            'content': this.translate.instant('user.txt_login_success'),
                            'type': 'success'
                        }
                    )
                    this.commonService.setInforUser(value.data);
                    this.commonService.setLoginStatus(true);
                    this.notiErr = '';
                } else {
                    this.notiErr = this.translate.instant('user.txt_err_login');
                }
            } else {
                this.notiErr = this.translate.instant('user.txt_err_login');
            }
        }
    }

    goRegister() {
        this.commonService.closeModal('login');
        this.commonService.openModal('register');
    }

    onTextChange(event) {
        this.notiErr = '';
    }

    resetPassword() {
        // this.commonService.closeModal('login');
        // $('#modal-send-email').modal('show');
    }

    close() {
        this.dataLogin.reset();
        this.submitted = false;
        this.notiErr = '';
        this.commonService.closeModal('login');
    }
}
