<nav *ngIf="listIndex.length > 1">
    <ul class="pagination">
        <li class="page-item" (click)="previousPaginationLast()" *ngIf="showButtonPaginationPreviousLast">
            <a class="page-link" aria-label="Previous">
                <img class="icon-10 " src="assets/images/icons/ic_last_pre.png" alt="icon Jaemy">
                <span class="sr-only">Previous</span>
            </a>
        </li>
        <li class="page-item" (click)="previousPagination()" *ngIf="showButtonPaginationPreviousLast">
            <a class="page-link" aria-label="Previous">
                <img class="icon-10 " src="assets/images/icons/ic_previous.png" alt="icon Jaemy">
                <span class="sr-only">Previous</span>
            </a>
        </li>
        <li class="page-item" *ngFor="let pagination of listIndex" (click)="changePagination(pagination.value)" [ngClass]="{'active': (pagination.value === (indexPagination + 1))}">
            <a class="page-link">{{ pagination.label }}</a>
        </li>
        <li class="page-item" *ngIf="showButtonPaginationNextLast" (click)="nextPagination()">
            <a class="page-link" aria-label="Next">
                <img class="icon-10 " src="assets/images/icons/ic_next.png" alt="icon Jaemy">
                <span class="sr-only">Next</span>
            </a>
        </li>
        <li class="page-item" *ngIf="showButtonPaginationNextLast" (click)="nextPaginationLast()">
            <a class="page-link" aria-label="Next">
                <img class="icon-10 " src="assets/images/icons/ic_last_next.png" alt="icon Jaemy">
                <span class="sr-only">Next</span>
            </a>
        </li>
    </ul>
</nav>