<div class="infor-user">
    <div class="box-avar dp-inblock">
        <img class="avar-user img-circle" [src]="feedback.image ? feedback.image : 'assets/images/ic_avatar.png'" 
            onError="this.src='assets/images/ic_avatar.png';" alt="icon Jaemy dictionary"/>
       
    </div>
    <div class="box-name dp-inblock">
        <div class="name-user">{{ feedback.username }}</div>
        <div class="box-premium" [ngClass]='{"premium": feedback | premium }'>
            <span *ngIf="feedback | premium">Vip member</span>
            <span *ngIf="!(feedback | premium)">Member</span>
        </div>
    </div>
    <app-action *ngIf="user && (user.id == feedback.userId)" (actionEvent)="action($event)"></app-action>
</div>
<div class="content-feedback" *ngIf="!showEdit">
    {{ feedback.mean }}
</div>
<div class="box-edit" *ngIf="showEdit">
    <input class="edit-content dp-inblock" [(ngModel)]="feedbackEdit" placeholder="{{ 'community.txt_place_comment' | translate }} ...">
    <div class="btn btn-add dp-inblock" (click)="saveFeedback()">{{ 'txt_update' | translate }}</div>
    <div class="btn btn-cancel dp-inblock" (click)="cancel()">{{ 'txt_cancel' | translate }}</div>
</div>
<div class="line-action">
    <span class="user-like" (click)="actionReport(feedback.id, 'like')">
        <img class="love-feedback icon-18" alt="icon Jaemy dictionary"
            [src]="feedback.action? 'assets/images/icons/ic_love_red.png' : 'assets/images/icons/ic_love_white.png'">
        {{ feedback.like }}
    </span>
    <div class="time-up"> {{ feedback.created_at | time:'UTC' }}</div>
   
    <!-- <span class="user-dislike dislike-{{ feedback.id }}" (click)="actionReport(feedback, -1)">
        <img *ngIf="feedback.rated == null || feedback.rated.action == '1'" class="icon-13 dislike-no-active" src="assets/images/icons/ic_dislike_gray.png" alt="icon Jaemy dictionary">
        <img *ngIf="feedback.rated && feedback.rated.action == '-1'" class="icon-13 dislike-active" src="assets/images/icons/ic_dislike_blue.png" alt="icon Jaemy">
        {{ feedback.dislike }}
    </span> -->
</div>