<app-modal id="add-cate">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body box-add-cate">
                <div class="title-modal text-center font-20" *ngIf="type == 'create'">{{ 'txt_create_folder' | translate }}</div>
                <div class="title-modal text-center font-20" *ngIf="type == 'edit'">{{ 'txt_edit_folder' | translate }}</div>
                <div class="modal-box">
                    <div class="text-center">
                        <input class="name-folder" [(ngModel)]="name" placeholder="{{ 'txt_folder_name' | translate }}"> 
                    </div>
                    <div class="txt-err txt-center" *ngIf="notiErr">{{ notiErr }}</div>
                    <div class="box-btn">
                        <button class="btn btn-update btn-sm" (click)="addCategory()" *ngIf="type == 'create'">
                            {{ 'txt_add' | translate }}
                        </button>
                        <button class="btn btn-update btn-sm" *ngIf="type == 'edit'" (click)="addCategory()">
                            {{ 'txt_edit' | translate }}
                        </button>
                        <button class="btn btn-secondary btn-sm" (click)="close()">{{ 'txt_cancel' | translate }}</button>
                    </div>      
                </div>
            </div>
        </div>
    </div>
</app-modal>
