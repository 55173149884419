<div class="box-default box-content-default">
    <div class="box-profile" *ngIf="inforUser">
        <div class="box-user">
            <div class="avatar">
                <img class="ic_avatar" *ngIf="inforUser.image" [src]="inforUser.image" onError="this.src='assets/images/ic_avatar.png';" alt="icon Jaemy">
                <img class="ic_avatar" *ngIf="!inforUser.image" src="assets/images/ic_avatar.png" alt="icon Jaemy">
                <div class="edit-avatar" (click)="fileInput.click()">
                    <img class="icon-13" src="assets/images/icons/ic_camera.png" alt="icon Jaemy">
                    <div>Update</div>
                </div>
                <div class="box-input-avatar">
                    <input #fileInput name="file" type="file" class="fileInput-avatar" (change)="postAvatar($event)" />
                </div>
            </div>
            <div class="username" *ngIf="!editName">
                <span>{{ username }}</span>
                <img class="icon-18" (click)="editUsername()" src="assets/images/icons/ic_note.png" alt="icon Jaemy">
            </div>
            <div class="username" *ngIf="editName">
				<input class="name" [(ngModel)]="username" placeholder="{{ 'user.txt_input_username' | translate }}"/>
                <img class="icon-18" (click)="updateUsername()" src="assets/images/icons/ic_check_green.png" alt="icon Jaemy">
				<div class="box-danger-center" *ngIf="checkEmpty()">
					<img class="icon-16" src="assets/images/icons/ic_warning_red.png" alt="icon Jaemy">
					<span class="txt-err">{{ 'user.txt_input_username' | translate }}</span>
				</div>
			</div>
        </div>
        <div class="box-infor">
            <div class="profile-title">{{ 'txt_information' | translate }}</div>
            <div class="show-infor">
                <div class="item-infor row">
                    <div class="col-md-3 content-title">
                        <p>Email:</p>
                    </div>
                    <div class="col-md-7">
                        <div class="input-data">{{ inforUser.email }}</div> 
                    </div>
                </div>
                <div class="item-infor row" *ngIf="!editPass">
                    <div class="col-md-3 content-title">
                        <p>{{ 'user.txt_password' | translate }}:</p>
                    </div>
                    <div class="col-md-7">
                        <div class="input-data">*************</div>
                    </div>
                    <div class="col-md-2 change-pass">
                        <img class="icon-18" (click)="editPassword()" src="assets/images/icons/ic_note.png" alt="icon Jaemy">
                    </div>
                </div>
                <form class="change-password" [formGroup]="dataChangePass" *ngIf="editPass">
                    <div class="item-infor row">
                        <div class="col-md-3 content-title">
                            <p>{{ 'user.txt_password_old' | translate }}:</p>
                        </div>
                        <div class="col-md-7">
                            <input class="input-data" formControlName="old_password" type="password" (input)="onTextChange($event)">
                            <div *ngIf="dataChangePass.controls.old_password.invalid && (dataChangePass.controls.old_password.dirty || dataChangePass.controls.old_password.touched)">
                                <div  class="txt-err" *ngIf="dataChangePass.controls.old_password.errors.minlength">
                                    <img class="icon-10" src="assets/images/icons/ic_close_red.png" alt="icon Jaemy">{{ 'user.txt_pw_length' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-infor row">
                        <div class="col-md-3 content-title">
                            <p>{{ 'user.txt_password_new' | translate }}:</p>
                        </div>
                        <div class="col-md-7">
                            <input class="input-data" formControlName="new_password" type="password" (input)="onTextChange($event)">
                            <div *ngIf="dataChangePass.controls.new_password.invalid && (dataChangePass.controls.new_password.dirty || dataChangePass.controls.new_password.touched)">
                                <div class="txt-err" *ngIf="dataChangePass.controls.new_password.errors.minlength">
                                    <img class="icon-10" src="assets/images/icons/ic_close_red.png" alt="icon Jaemy">{{ 'user.txt_pw_length' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-infor row">
                        <div class="col-md-3 content-title">
                            <p>{{ 'user.txt_password_new_confirm' | translate }}:</p>
                        </div>
                        <div class="col-md-7">
                            <input class="input-data" formControlName="confirm_password" type="password" (input)="onTextChange($event)">
                            <div *ngIf="dataChangePass.value.confirm_password && !(dataChangePass.value.new_password == dataChangePass.value.confirm_password)">
                                <div class="txt-err">
                                    <img class="icon-13" src="assets/images/icons/ic_warning_red.png" alt="icon Jaemy">{{ 'user.txt_pass_match' | translate }} !
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="changePassStatus" class="txt-err err-pass col-md-7 offset-md-3">
                            <img class="icon-16" src="assets/images/icons/ic_warning_red.png" alt="icon Jaemy">{{ 'user.txt_err_oldpass' | translate }} !
                        </div>
                    </div>
                    <div class="item-infor row">
                        <div class="col-md-10">
                            <button class="btn btn-update" (click)="changePass()">{{ 'txt_change' | translate }}</button>
                            <button class="btn btn-cancel" (click)="cancel()">{{ 'txt_cancel' | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="box-activate">
            <div class="profile-title">{{ 'txt_activate' | translate }}</div>
            <div class="activate-content">
                <div class="list-activate" *ngFor="let item of listReport" (click)="showWord(item)">
                    <div class="content">
                        <img class="icon-16" src="assets/images/icons/ic-history.png" alt="icon Jaemy">
                        <span>{{ 'txt_act_comment' | translate }}: "{{ item.mean }}"</span>
                    </div>
                    <div class="time">{{ item.created_at.date }}</div>
                </div>
                <div class="list-activate" *ngFor="let item of listRate" (click)="showWord(item)">
                    <div class="content">
                        <img class="icon-16" src="assets/images/icons/ic-history.png" alt="icon Jaemy">
                        <span>{{ 'txt_act_react' | translate }}: "{{ item.word }}"</span>
                    </div>
                </div>
                <div class="text-center" *ngIf=" (listReport.length === 0) && (listRate.length === 0)">{{ 'txt_none_activity' | translate }}</div>
                <div class="text-right view-more" (click)="moreActivity()">
                    {{ 'txt_view_more' | translate }} <img class="icon-16 mb-1 ml-1" src="assets/images/icons/next.png" alt="icon Jaemy" />
                </div>
            </div>
        </div>
    </div>
</div>
<app-crop-image [myImage]="myImage" (changeImageOk)="changeImageOk($event)"></app-crop-image>
<!-- <app-modal-word *ngIf="wordDetail" [dataSend]="{'wordDetail' : wordDetail, 'langWeb' : langWeb}"></app-modal-word>
<app-modal-grammar *ngIf="grammarDetail" [dataSend]="{'grammarDetail' : grammarDetail, 'langWeb' : langWeb }"></app-modal-grammar> -->