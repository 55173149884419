import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';
import * as CONFIG from '../config';

@Injectable({
  providedIn: 'root'
})
export class PracticeService {

    constructor() { }

    playAudio(value: string, type: string, lang: string) {
        if (type == 'learning') {
            let wordHash = Md5.hashStr(value);

            const audio = new Audio();
            const typeSpeak = 0; // 1: man; 0: woman
            audio.src = CONFIG.BASE_URL_DATA + '/study/'+ lang +'/audios/'+ typeSpeak +'/'+ wordHash +'.mp3';
            audio.load();
            audio.play();
        } else {
            this.audioBrowser(value, 'ko');
        }
    }

    audioBrowser(value: string, lang: string) {
        const newvalue = value.replace(/(<([^>]+)>)/ig, '');
        if (newvalue) {

            speechSynthesis.cancel();
            const msg = new SpeechSynthesisUtterance();
            msg.volume  = 1;
            msg.rate = 0.75;
            msg.pitch = 1;
            msg.text = newvalue;
            msg.lang = lang;

            speechSynthesis.speak(msg);
        }
    }
}
