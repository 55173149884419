import { Component, Input, OnInit } from '@angular/core';
import { Help, ListHelp } from '../help';

@Component({
  selector: 'app-help-item',
  templateUrl: './help-item.component.html',
  styleUrls: ['./help-item.component.css']
})
export class HelpItemComponent implements OnInit {
  toggle: boolean = false;
  help: Help[] = [];
  @Input()data: Help[];

  constructor() { }

  ngOnInit(): void {
    this.help =  this.data
  }

  showAns() {
    this.toggle = !this.toggle;
  }
  
}
