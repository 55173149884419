<div class="translate-component box-default box-content-default">
    <app-box-banner></app-box-banner>
    <div class="box-language row">
        <div class="col-md-6 col-sm-6 col-6 box-from no-padding">
            <div class="list-language-source">
                <div class="row content-lang">
                    <!-- <div *ngFor="let source of langViewFrom" class="item-language">
                        <div class="item-content {{source.code == langFrom.code ? 'item-language-active' : ''}}" (click)="changeLang(source, 'from')">
                            {{ source.name }}
                        </div>
                    </div>  -->
                    <div class="item-language">
                        <div class="item-content" (click)="changeLang(langFrom, 'from')">
                            {{ langFrom.name }}
                        </div>
                    </div>
                </div>
                <div class="btn-select select-from" (click)="showAllLang('from')">
                    <img class="" src="assets/images/icons/ic_angle_down.png" alt="arrow down">
                </div>
            </div>
            <!-- <div *ngIf="typeAllLang == 'from'" class="list-lang-all list-lang-from">
                <h5 class="item-lang-all {{source.code == langFrom.code ? 'item-language-active' : ''}}" 
                    *ngFor="let source of langSource" (click)="changeLang(source, 'from')"> 
                    {{ source.name }} 
                </h5>
                <div class="btn-select select-from-all" (click)="showAllLang('close')">
                    <img class="" src="assets/images/icons/ic_angle_up.png" alt="arrow down">
                </div>
            </div> -->
        </div>
        <div class="col-md-6 col-sm-6 col-6 box-to no-padding">
            <div class="list-language-translate">
                <div class="row content-lang">
                    <!-- <div class="item-language" *ngFor="let item of langViewTo">
                        <div class="item-content {{item.code == langTo.code ? 'item-language-active' : ''}}" (click)="changeLang(item, 'to')">
                            {{ item.name }} 
                        </div>
                    </div> -->
                    <div class="item-language">
                        <div class="item-content" (click)="changeLang(langTo, 'to')">
                            {{ langTo.name }} 
                        </div>
                    </div>
                </div>
                <div class="btn-select select-to" (click)="showAllLang('to')">
                    <img class="" src="assets/images/icons/ic_angle_down.png" alt="arrow down">
                </div>
            </div>
            <!-- <div *ngIf="typeAllLang == 'to'" class="list-lang-all list-lang-to">
                <div class="item-lang-all {{item.code == langTo.code ? 'item-language-active' : ''}}" 
                    *ngFor="let item of langSource" (click)="changeLang(item, 'to')"> {{ item.name }} </div>
                <div class="btn-select select-to" (click)="showAllLang('close')">
                    <img class="" src="assets/images/icons/ic_angle_up.png" alt="arrow down">
                </div>
            </div> -->
        </div> 

        <div class="btn-swap-language" (click)="swapLanguage()">
            <img src="assets/images/icons/ic_change.png" alt="Swap language" class="ic-swap-language" >
        </div>
    </div>

    <div class="box-result row no-margin">
        <div class="col-md-6 col-sm-6 box-input no-padding">
            <textarea id="text-translate" name="text-translate" class="text-translate" rows="1" spellcheck="false" autocapitalize="off" 
                autocomplete="off" autocorrect="off" maxlength="800" [(ngModel)]="textTranslate" (input)="onTextChange($event)" ></textarea>

            <div *ngIf="romaja && langFrom.code == 'ko'" [innerHTML]="romaja" class="romaja"></div>
            
            <div class="box-line">
                <div class="line-left">
                    <div *ngIf="textTranslate.length > 0 && langFrom.code != 'vi'" (click)="playAudio('from')" class="btn-audio">
                        <img src="assets/images/icons/ic_volume.png" alt="Play audio">
                    </div>
                    <div (click)="getHistory()" class="btn-history">
                        <img src="assets/images/icons/ic_history.png" alt="History" class="icon-24">
                    </div>
                    <div *ngIf="langFrom.code == 'ko'" (click)="draw()" class="btn-draw">
                        <img src="assets/images/icons/ic_draw.png" alt="draw kanji" class="icon-22">
                    </div>
                    <!-- <div class="btn-file">
                        <img src="assets/images/icons/ic_image.png" title="File" alt="translate text file kanji" class="icon-18">
                    </div> -->
                   <!-- <div class="btn-file">
                        <img (click)="openFile()" src="assets/images/icons/ic_image.png" title="File" alt="translate text file kanji" class="icon-18">
                        <input #file type="file" (change)="readFile($event.target.files)" hidden>
                    </div> -->
                </div>
                <div class="line-right">
                    <span class="txt-leng"> {{ textTranslate.length }}/800 </span>
                    <div class="btn-translate" (click)="translateGoogle()">
                        <span class="txt-translate">{{ 'txt_translate' | translate }}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="textTranslate.length > 0" (click)="clearTextTranslate()" class="btn-clear">
                <img alt="clear" class="icon-20" src="assets/images/icons/ic_close.png">
            </div>
        </div>
        <div class="col-md-6 col-sm-6 content-result no-padding" id="result-tranlate">
            <div [innerHTML]="resultTranslate" class="content-translate" title="{{resultTranslate}}" id="content-translate"></div> 
            <div *ngIf="romaja && langTo.code == 'ko'" [innerHTML]="romaja" class="romaja"></div>
            <div class="box-line line-left" *ngIf="resultTranslate">
                <div *ngIf="langTo.code != 'vi'" class="btn-audio" (click)="playAudio('to')" >
                    <img src="assets/images/icons/ic_volume.png" alt="Jeamy translte">
                </div>
                <div class="btn-copy txt-right" (click)="copyToClipBoard(resultTranslate)">
                    <img src="assets/images/icons/ic_copy.png" alt="coppy" class="icon-24">
                </div>
            </div>
        </div>
    </div> 

    <div class="draw-kanji no-margin" id="draw-kanji" >
        <div [class.hidden]="!showDraw" class="draw_content">
            <app-draw-area (insertQueryText)="insertQueryText($event)"></app-draw-area>
        </div>
    </div>

    <div class="box-history" *ngIf="showHistory">
        <p class="txt-hist">{{ 'txt_history' | translate }}</p>
        <div *ngIf="dataHistory && dataHistory.length > 0">
            <div class="item-history" *ngFor="let item of dataHistory.slice().reverse()" (click)="getItemHistory(item)">
                <span [innerHTML]=" (item.query.length < 60) ? item.query : (item.query.slice(0, 60) + '...') "></span>
                <div class="time">{{ getTime(item.date) }}</div>
            </div>
        </div>
        <div class="none-activity" *ngIf="!dataHistory || dataHistory.length == 0">{{ 'txt_history_trans' | translate }}</div>
    </div>

    <!-- <div class="box-history" *ngIf="listWordCutTrans.length > 0">
        <div class="row">
            <div class="col-md-6 col-xs-12" *ngFor="let dataExam of listWordCutTrans">
                <div *ngFor="let item of dataExam" class="box-item-word">
                    <div class="box-word" (click)="clickWord(item.word)">
                        <div class="exam-word cl-tt">
                            {{ item.word }} 
                        </div>
                        <span>  【{{ item.romaja }}】</span>
                        <div class="btn-right">  
                            <div class="cust-btn-default icon-audio" (click)="playAudioWord(item.word)"> 
                                <img class="icon-18" src="assets/images/icons/ic_volume.png" alt="audio"> 
                            </div>
                        </div>
                    </div>
                    <div class="exam-mean">
                        {{ item.mean }}
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="noti-coppy">{{ 'txt_copy_translate' | translate }}</div>

</div>
