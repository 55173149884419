<div class="help-component box-default box-content-default">
    <div class="box-help my-5">
        <p class="title-help text-center mt-2"> {{ 'txt_help' | translate }} </p>
		<div class="content-help">
			<div class="box-item" *ngFor="let help of helps">
				<app-help-item [data]="help"></app-help-item>
			</div>
		</div>
    </div>
</div>
