<div class="box-header headerbar app-background">
    <div class="list-menu box-default">
        <div class="home-page no-pml" (click)="routeToHome()">
            <img alt="Jaemy" class="logo-jaemy" src="assets/images/ic_logo.png">
            <img alt="Jaemy" class="" src="assets/images/app_name.png">
        </div>
        <div class="box-left">
            <div class="box-menu" [class.active]="showTabMenu || innerWidth >= 990">
                <div class="d-flex bg-white box-mobile">
                    <div class="home-page no-pml" (click)="showMenu()" routerLink='/search' [queryParams]="{ hl: langWeb}">
                        <img alt="Jaemy" class="logo-jaemy" src="assets/images/ic_logo.png">
                    </div>
                    <div class="user-infor {{ routerActive('profile') }} no-mpr" *ngIf="commonService.getLoginStatus() && user">
                        <div class="txt-name"> {{ user.username }} </div>
                        <div class="item-user logout bg-danger rounded text-light w-100 px-2" (click)="logout()"> 
                            <span> {{ 'user.txt_logout' | translate }} </span> 
                        </div>
                    </div>
                    <div class="box-login" *ngIf="!commonService.getLoginStatus()">
                        <div class="btn-user btn-login rounded p-2" (click)="openModal('login')"> {{ 'user.txt_login' | translate }} </div>
                        <div class="btn-user btn-register app-color bg-danger rounded text-light p-2" (click)="openModal('register')"> {{ 'user.txt_register' | translate }} </div>
                    </div>
                </div>
                <div class="item-menu search {{ routerActive('search') }}" (click)="routerLink('search')">
                    <h2> {{ 'header.search' | translate }} </h2>
                </div>
                <div class="item-menu translate {{ routerActive('translate') }}" (click)="routerLink('translate')">
                    <h2> {{ 'header.translate' | translate }} </h2>
                </div>
                <div class="item-menu verbs {{ routerActive('verbs') }}" (click)="routerLink('verbs')">
                    <h2> {{ 'header.verbs' | translate }} </h2>
                </div>
                <div class="item-menu notebook {{ routerActive('notebook') }}" (click)="routerLink('notebook')">
                    <h2> {{ 'header.notebook' | translate }} </h2>
                </div>
                <div class="item-menu learning {{ routerActive('learning') }}" (click)="routerLink('learning')">
                    <h2> {{ 'header.learning' | translate }} </h2>
                </div>
                <div class="item-menu community {{ routerActive('community') }}" (click)="routerLink('community')">
                    <h2> {{ 'header.community' | translate }} </h2>
                </div>
                <!-- <div class="item-menu sale-off {{ routerActive('sale-off') }}" (click)="routerLink('sale-off')">
                    <h2> {{ 'header.premium' | translate }} </h2>
                </div> -->
            </div>
            <div class="item-menu language">
                <span> <img class="icon-26" src="assets/images/flags/ic_{{langWeb}}.png" alt="icon flat {{ langWeb }}"></span>
                <span>
                    <img src="assets/images/icons/ic_select.png" class="icon-16" alt="icon jaemy">
                </span>
                <div class="box-hover list-language">
                    <div *ngFor="let item of dataLanguage" class="item-language" (click)="changeLanguage(item.key)">  
                        <img class="icon-26" src="assets/images/flags/ic_{{ item.key }}.png" alt="icon flat {{ item.name }}">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="item-menu user-infor box-web {{ routerActive('profile') }} no-mpr" *ngIf="commonService.getLoginStatus() && user">
                <img [src]="user.image ? user.image : 'assets/images/ic_avatar.png'"
                    onError="this.src='assets/images/ic_avatar.png';" alt="profile" class="img-avatar">
                <div class="box-pre" *ngIf="user.premium">
                    <img alt="icon jaemy" class="icon-pre" src="assets/images/icons/ic_crown.png">
                </div>
                <div class="box-hover user-action">
                    <div class="user-content">
                        <div class="box-user" (click)="routerLink('profile')">
                            <div class="user-name">
                                <div class="txt-name"> {{ user.username }} </div>
                                <div class="txt-email"> {{ user.email }} </div>
                            </div>
                        </div>
                        <div class="item-user" (click)="routerLink('profile')"> 
                            <span>{{ 'user.txt_profile' | translate }}</span> 
                        </div>
                        <div class="item-user" (click)="routerLink('activity')"> 
                            <span>{{ 'txt_activate' | translate }}</span> 
                        </div>
                        <div class="item-user" (click)="routerLink('help')"> 
                            <span>{{ 'txt_help' | translate }}</span> 
                        </div>    
                    </div>                   
                    <div class="item-user logout" (click)="logout()"> 
                        <span> {{ 'user.txt_logout' | translate }} </span> 
                    </div>
                </div>
            </div>
            <div class="box-login box-web" *ngIf="!commonService.getLoginStatus()">
                <div class="btn-user btn-login" (click)="openModal('login')"> {{ 'user.txt_login' | translate }} </div>
                <div class="btn-user btn-register app-color" (click)="openModal('register')"> {{ 'user.txt_register' | translate }} </div>
            </div>
        </div>
    </div>
    <div class="cover" *ngIf="showTabMenu && innerWidth < 990" (click)="closePanel()"></div>
</div>

<app-login></app-login>
<app-register></app-register>