import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as CONFIG from '../config';
import { Observable, of } from 'rxjs';
import { Feedback, TrendingSearch } from '../models/search';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    requestCaches = {};

    constructor(
        private http: HttpClient
    ) { }

    addNoteBook(word: string, note, cateId, callback) {
        let url;
        let dataSend;

        url = CONFIG.BASE_API_URL + 'api/notebooks/create';

        dataSend = {
            word: JSON.stringify(word),
            note,
            category_id: cateId
        };

        this.http.post(url, dataSend, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    getDetailPremium(slug, callback) {
        const url = CONFIG.BASE_API_URL + 'api/notebooks/premium/' + slug;

        this.http.get(url, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    searchAll(text, dict, type, page = 1, limit = 50, callback) {
        let urlRegister;
        let dataSend;

        urlRegister = CONFIG.BASE_API_URL_SEARCH;
        dataSend = {
            text,
            dict,
            type,
            page,   
            limit
        }   
        this.http.post(urlRegister, dataSend, httpOptions)
        .subscribe(
            data => callback(data['data']),
            err => callback(null)
        );

    }

    getFeedback(wordId: number, dict: string, page: number): Observable<Feedback[]> {

        const url = CONFIG.BASE_API_URL + 'api/open-dict/detail/' + wordId + '/' + dict + '/' + page;

        return this.http.get<Feedback[]>(url, httpOptions).pipe(
            map(x => x['data'])
        );
    }

    addFeedback(dataSend): Observable<Feedback[]> {
        const url = CONFIG.BASE_API_URL + 'api/open-dict/dict';
      
        return this.http.post<Feedback[]>(url, dataSend, httpOptions).pipe(
            map(x => x['data'])
        );
    }

    rateFeedback(feedbackId: number, type: string) {
        const url = CONFIG.BASE_API_URL + 'api/open-dict/dict/rate';
        const dataSend = {
            "popupId": feedbackId,
            "type": type     
        }
      
        return this.http.post<Feedback>(url, dataSend, httpOptions).pipe(
            map(x => x['data'])
        );
    }

    editFeedback(feedbackId: number, mean: string) {
        const url = CONFIG.BASE_API_URL + 'api/open-dict';
        const dataSend = {
            "id": feedbackId,
            "mean": mean     
        }
      
        return this.http.put(url, dataSend, httpOptions).pipe();
    }

    deleteFeedback(feedbackId: number) {
        const url = CONFIG.BASE_API_URL + 'api/open-dict/' + feedbackId;
      
        return this.http.delete(url, httpOptions).pipe();
    }

    trendingSearch(type): Observable<TrendingSearch[]> {
        const url = CONFIG.BASE_API_URL + 'api/history/trends/' + type;

        return this.http.get(url, httpOptions).pipe(
            map(x => x['result']),
            catchError(this.handleError<TrendingSearch[]>(`list trending`))
        );
    }

    ranking(): Observable<any> {
        const url = CONFIG.BASE_API_URL + 'api/open-dict/top';

        return this.http.get(url, httpOptions).pipe(
            map(x => x['data'])
        );
    }

    getListVerd(lang, page, callback) {
        const url = CONFIG.BASE_API_URL_SEARCH + '/verb/'+ lang +'/30/' + page;

        this.http.get(url, httpOptions)
        .subscribe(data => {
            callback(data['data']);
        },
        error => {
            callback(null);
        });
    }

    getPostNoAnswer(lang: string, callback) {
        const url = CONFIG.BASE_API_URL + 'api/socials/posts/no-answers?lang=' + lang + '&limit=5';
 
        this.http.get(url, httpOptions)
        .subscribe(
            data => callback(data['data']),
            err => callback(null)
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          return of(result as T);
        };
    }

}
