import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})

export class TransService {
    requestCaches = {};

    constructor(
        private http: HttpClient,
        private translate: TranslateService
    ) { }

    
    cloudTranslate(query, from, to, callback) {
        from = from.substring(0, 2);
        to = to.substring(0, 2);

        let url = "https://translate.googleapis.com/translate_a/single?client=gtx&dt=t&dt=bd&dj=1&dt=ex&dt=ld&dt=md&dt=qca&dt=rm&dt=ss&sl="+ from +"&tl="+ to +"&q=" + query;

        if (this.requestCaches[url] != null) {
            callback(this.requestCaches[url]);
            return;
        } else {

            this.http.get(url).subscribe( data => {
                
                if (data && data['sentences']) {
                    let newData = data['sentences'];
                    this.requestCaches[url] = newData;
                    callback(newData);
                } else {
                    this.requestCaches[url] = null;
                    callback(null);
                }
            },
                error => {
                callback(null);
            });
        }
    }

    startTranslate(query, from, to, callback) {
        this.cloudTranslate(query, from, to, (data) => {
            if (!data) {
                callback(null);
            }

            let textTranslate = '';
            let pinyinAutoTran = '';

            let dataConverted  = this.convertGGResult(data);
            textTranslate += dataConverted[0];
            pinyinAutoTran += dataConverted[1];

            const itemTranslate = {
                compound : null,
                content : [{
                    kind: null,
                    means : [{
                        mean : textTranslate
                    }]
                }],
                id : -1,
                lv_hsk : null,
                phonetic : null,
                pinyin : pinyinAutoTran,
                type : from + to,
                word : query,
                source : this.translate.instant('txt_auto_translate')
            };
            callback(itemTranslate);
        });
    }

    convertGGResult(result) {
        let arr = [];
        let resultTranslate = '';
        let pinyin = '';
        let resultLenght = result.length;

        result.forEach((sen, index) => {
            if (resultLenght == 1) {
                resultTranslate += sen.trans;
    
            } else if (index < resultLenght - 1) {
                resultTranslate += sen.trans;

            } else {
                let dataPinyin = result[resultLenght - 1].src_translit;

                if (dataPinyin == undefined) {
                    dataPinyin = result[resultLenght - 1].translit
                }
                if (dataPinyin != undefined) {
                    pinyin += dataPinyin;
                }
            }
        });

        arr.push(resultTranslate);
        arr.push(pinyin)
        return arr;
    }
}
