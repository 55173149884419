import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

import { CommonService } from './services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from './models/user';
import { FirebaseService } from './services/firebase.service';
import { BroadcasterService } from './services/broadcaster.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'jaemy-web-client';
    url: string = ''; 
    location!: Location;
    dataNote: any;

    tabLangCode = {
        'vi': "vi", 
        'en': "en"
    }
    
    constructor(
        private router: Router,
        public commonService: CommonService,
        public translate: TranslateService,
        private firebase: FirebaseService,
        private broadcaster: BroadcasterService,

        @Inject(PLATFORM_ID) private platformId: object,

    ) {

        if (isPlatformBrowser(this.platformId)) {
            this.commonService.setEnvironment('client');
            
            let user: User = this.commonService.getLocal('inforUser');
            if (user) {
                this.commonService.setLoginStatus(true);
                this.commonService.setInforUser(user);
            } 

        } else {
            this.commonService.setEnvironment('server');
        }

        this.firebase.requestPermission();
        this.firebase.receiveMessage();

        this.broadcaster.on('modal-add-note').subscribe(res => {
            if (res) {
                this.dataNote = res;
                setTimeout(() => {
                    this.commonService.openModal('modal-add-note');
                }, 200);
            }
        });
    }

    ngOnInit() {
        const language = this.getLang();

        if (['vi', 'en'].indexOf(language) > -1) {
            this.translate.setDefaultLang(language);
            let langWeb = this.tabLangCode[language];
            this.commonService.setLangPack(langWeb);
            this.commonService.setLocal('currentLanguage', langWeb);
            this.translate.use(language);

        } else {
            this.commonService.setLangPack('vi');
            this.translate.setDefaultLang('vi');
            this.translate.use('vi');
        }        
    }

    public getLang(): string {
        let lang: string;
        if (isPlatformBrowser(this.platformId)) {
            
            let languageStorage = this.commonService.getLocal('currentLanguage');
            if (!languageStorage) {
                lang = this.translate.getBrowserLang();
            } else {
                lang = languageStorage;
            }
        } else {
            lang = 'vi';
        }
        return lang;
    }
}
