import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawComponent } from './draw.component';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
    declarations: [DrawComponent],
    imports: [
      CommonModule,
      HttpClientModule
    ],
    exports: [DrawComponent]
})
export class DrawModule { }
