import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { ShareModule } from './share.module';

import { AuthService } from './services/auth.service';

import { AppComponent } from './app.component';
import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { IntroComponent } from './views/intro/intro.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateComponent } from './views/translate/translate.component';
import { LoginComponent } from './views/user/login/login.component';
import { RegisterComponent } from './views/user/register/register.component';
import { ProfileComponent } from './views/user/profile/profile.component';
import { CropImageComponent } from './views/user/crop-image/crop-image.component';
import { ModalDetailComponent } from './views/modal-detail/modal-detail.component';
import { TermComponent } from './views/term/term.component';
import { HelpComponent } from './views/help/help.component';
import { HelpItemComponent } from './views/help/help-item/help-item.component';
import { ActivityComponent } from './views/user/activity/activity.component';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        IntroComponent,
        TranslateComponent,
        LoginComponent,
        RegisterComponent,
        ProfileComponent,
        CropImageComponent,
        ModalDetailComponent,
        TermComponent,
        HelpComponent,
        HelpItemComponent,
        ActivityComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ShareModule,
        ImageCropperModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        AngularFireMessagingModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS,
            useClass: AuthService,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(translate: TranslateService) {
        translate.addLangs(['vi', 'en', 'ru', 'fr', 'de', 'es']);
        translate.setDefaultLang('vi');
    }
 }
