import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../../../services/common.service';
import { UserService } from './../../../services/user.service';
import { Rate, Report } from './activity';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
    langWeb: string = '';
    language: string = '';
    actChoosen: string = 'reports';
    dictSearch: string = '';
    dataAct: any = [];
    listReportAll: Report[] = [];
    listRateAll: Rate[] = [];
    listReports: Report[] = [];
    listRates: Rate[] = [];
    toggle: boolean = true;

    constructor(
        private commonService: CommonService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.langWeb = this.commonService.getLangPack();
        this.route.queryParams.subscribe(params => {
            this.language = params.hl;
        });
        this.getUserActivity(); 
        window.scrollTo(0,0);
   }

    getUserActivity() {
        this.userService.userActivity( this.language, (data) => {
            if (data) {   
                this.dataAct = data; 
                this.listReportAll = this.dataAct['reports'].data; 
                this.listReports = this.listReportAll.slice(0,10);  
                this.listRateAll = this.dataAct['rates'].data;
                this.listRates = this.listRateAll.slice(0,10); 
                console.log(this.listReports);
                        
            }
        });
    }

    moreActivity(value) {
        if(value === 'reports') {
            this.listReports = this.listReportAll.slice(0, this.listReports.length + 10)
            if (this.listReports.length - this.listReportAll.length === 0) {
                this.toggle = false;
            }
            return;
        } else {
            this.listRates = this.listRateAll.slice(0, this.listRates.length + 10)
            if (this.listRates.length - this.listRateAll.length === 0) {
                this.toggle = false;
            }
        }
    }

    showWord(item) {
        this.router.navigate(['/search/word', item.word], {
            queryParams: {
                hl      : this.commonService.getLangPack()
            }
        });       
    }

    changeAct(value) {
        this.actChoosen = value;
        this.dictSearch = 'ko' + this.langWeb;
        if(value !== 'rates') {
            this.listReports = this.dataAct[value].data.slice(0,10);
            this.toggle = true;
            return;
        }
        this.listRates = this.dataAct[value].data.slice(0,10); 
        this.toggle = true;        
    }

}
