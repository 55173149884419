import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { PaginationService } from '../../services/pagination.service';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {
    @Input() listData: any;
    @Input() limit: any;
    @Output() paginateEvent = new EventEmitter();
    indexPagination: number;
    showButtonPaginationPreviousLast; showButtonPaginationNextLast; listIndex: any;
    maxSize = 7;

    constructor(
        private paginateService: PaginationService
    ) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.indexPagination = 0;
        if (this.listData) {
            this.listIndex = this.paginateService.createPageArray(this.indexPagination + 1, this.maxSize, this.listData.length, this.limit);
        } else {
            this.listIndex = [];
        }
        this.loadDataPaginate();
    }

    nextPagination() {
        if (this.listData !== null && this.listData.length !== 0 ) {
            this.indexPagination ++;
            this.loadDataPaginate();
        }
    }

    nextPaginationLast() {
        if (this.listData !== null || this.listData.length !== 0) {
            this.indexPagination = Math.ceil(this.listData.length / this.limit - 1);
            this.loadDataPaginate();
        }
    }

    previousPagination() {

        if (this.listData == null || this.listData.length === 0 || this.indexPagination === 0) {
            return;
        }

        this.indexPagination --;

        this.loadDataPaginate();
    }

    previousPaginationLast() {
        if (this.listData == null || this.listData.length === 0) {
            return;
        }

        this.indexPagination = 0;
        this.loadDataPaginate();
    }

    loadDataPaginate() {
        let result;
        result = this.paginateService.getDataPaginate(this.indexPagination, this.listData, this.limit);
        this.paginateEvent.emit(result.result);
        this.showButtonPaginationPreviousLast = result.showPreviousLast;
        this.showButtonPaginationNextLast     = result.showNextLast;
    }

    changePagination(index) {
        if (index === 0) {
            this.indexPagination = 0;
        } else {
            this.indexPagination = index - 1;
        }
        this.listIndex = this.paginateService.createPageArray(this.indexPagination + 1, this.maxSize, this.listData.length, this.limit);
        this.loadDataPaginate();
    }

}
