import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Category, DataAddNote } from 'src/app/models/note';
import { BroadcasterService } from 'src/app/services/broadcaster.service';
import { User } from '../../models/user';
import { CommonService } from '../../services/common.service';
import { NoteService } from '../../services/note.service';

interface LocalData {
    [key: string]: Category[]
}

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.css']
})
export class AddNoteComponent implements OnInit {

    @Input() dataWord: DataAddNote;
    page: number = 1;
    limit: number = 5;
    user: User | undefined; 
    notify: string = ''; 
    err: string = ''; 
    totalCategory: number = 0;
    
    listCategory: Category[] = []; 
    listCategoryPaginate: Category[] = [];
    localData: LocalData = {};
    localKey: string = '';
    langWeb: string = this.commonService.getLangPack();
  
    constructor(
        private noteService: NoteService,
        public commonService: CommonService,
        public translate: TranslateService,
        private broadcast: BroadcasterService
    ) {
        this.localData = {};
        this.user = this.commonService.getInforUser();
    }

    ngOnInit() {
        this.localKey = JSON.stringify({
            page: this.page,
            language: this.langWeb
        });

        this.broadcast.on('add-cate').subscribe((data: Category) => {
            if (data) {
                data.notebooks_count = 0;
                this.listCategory.unshift(data);
                this.localData[this.localKey] = this.listCategory;
            }
        });

        this.broadcast.on('delete-cate').subscribe((data: number) => {
            if (data) {
                let newArr = [];
                this.listCategory.forEach(item => {
                    if (item.id != data) {
                        newArr.push(item);
                    }
                });
                this.listCategory = newArr;
                this.localData[this.localKey] = newArr;
            }
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            if ((this.langWeb != event.lang)) {
                this.langWeb = event.lang;
                this.localKey = JSON.stringify({
                    page: this.page,
                    language: this.langWeb
                });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.listCategory = [];
        if (this.dataWord) {
            this.getListCategory();
        }
    }

    getListCategory() {

        if ( this.localData && this.localData[this.localKey] !== null && this.localData[this.localKey] !== undefined) {
            this.listCategory = this.localData[this.localKey];
        } else {
            this.noteService.getListCategory((data) => {
                if (data) {
                    this.listCategory = data.result;
                    this.totalCategory = data.total;
                    this.localData[this.localKey] = this.listCategory;
                }
            });
        }
    }

    addToCategory(value) {
        this.noteService.addNoteBook(this.dataWord.word, 'w', this.dataWord.mean, value, (data) => {
            if (data) {
                const index = this.localData[this.localKey].findIndex(x => x.id === value);
                this.localData[this.localKey][index].notebooks_count ++ ;

                this.notify = this.translate.instant('txt_add_note_success');

                setTimeout(() => {
                    this.notify = '';
                }, 3000);

            } else {
                this.err = this.translate.instant('txt_add_note_err');

                setTimeout(() => {
                    this.err = '';
                }, 3000);
            }
        });
    }

    addCategory() {
        this.localData = {};
        this.broadcast.broadcast('edit-cate', null);
    }

    closeModal() {
        this.localData = {};
        this.commonService.closeModal('modal-add-note');
    }

    paginateCategoryEvent(value) {
        this.page = value;
        setTimeout(() => {
            this.getListCategory();
        });
    }
}
