import { Component, OnInit, ViewEncapsulation, Input, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
  @Input() id: string;
  private element: any;

  constructor(
      private modalService: ModalService, 
      private el: ElementRef,
      private commonService: CommonService) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        if (this.commonService.getEnvironment() == 'client') {
            document.body.appendChild(this.element);
        }

        // close modal on background click
        this.element.addEventListener('click', el => {
            if (el.target.className.includes('app-modal')) {
                this.close();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.element.style.display = 'block';
        if (this.commonService.getEnvironment() == 'client') {
            document.body.classList.add('app-modal-open');
            this.commonService.scrollModal();
        }
    }

    // close modal
    close(): void {
        this.element.style.display = 'none';
        if (this.commonService.getEnvironment() == 'client') {
            document.body.classList.remove('app-modal-open');
        }
    }
}
