import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { CommonService } from '../../services/common.service';
import { Feedback, Word } from '../../models/search';
import { PracticeService } from '../../services/practice.service';
import { BroadcasterService } from '../../services/broadcaster.service';
import { DataAddNote } from '../../models/note';
import { SearchService } from '../../services/search.service';
import { User } from '../../models/user';

@Component({
    selector: 'app-detail-word',
    templateUrl: './detail-word.component.html',
    styleUrls: ['./detail-word.component.css']
})
export class DetailWordComponent implements OnInit {
    langWeb: string = '';
    dataWord: Word | undefined;
    listFeedback: Feedback[] = [];
    user: User | undefined = this.commonService.getInforUser();

    @Input() googleTranslate!: boolean;
    @Input() dictSearch!: string;

    constructor(
        private commonService: CommonService,
        private practiceService: PracticeService,
        private broadcaster: BroadcasterService,
        private translate: TranslateService,
        private searchService: SearchService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.broadcaster.on('change-infor').subscribe((user: User) => {
            if (user) {
                this.user = user;
            }
        });
    }

    @Input()
    set data(value) {
        if (value) {
            this.langWeb = this.commonService.getLangPack();
            this.dataWord = value;
            this.getFeedback(value.id)
        }
    }

    addNote() {
        if (this.user) {
            let mean: string = '';
            if ( this.dataWord.content.length > 0 ) {
                if (this.dataWord.content[0].means.length > 0) {
                    mean = this.dataWord.content[0].means[0].mean;
                }
            }
            let dataWord: DataAddNote = {
                id: this.dataWord.id,
                word: this.dataWord.word,
                romaja: this.dataWord.romaja,
                mean,
                type: 'word'
            };
    
            this.broadcaster.broadcast('modal-add-note', dataWord);
        } else {
            this.broadcaster.broadcast('notify', 
                {
                    'content': this.translate.instant('txt_required_login'),
                    'type': 'warning'
                }
            )
        }
    }

    getFeedback(wordId) {
        if (wordId) {
            this.searchService.getFeedback(wordId, this.dictSearch, 1).subscribe(res => {
                this.listFeedback = res;  
            });
        }
    }

    report() {
        // this.broadcaster.broadcast('report-search', { type: 'word', wordId: this.dataWord.id});
    }

    playAudio(value: string, type: string) {
        this.practiceService.playAudio(value, type, this.langWeb);
    }

    showMore() {
        // this.hidden = false;
        // this.dataCvCompound = this.dataWord.cvCompound;
    }

    searchItem(value) {
        this.router.navigate(['/search/word', value], {
            queryParams: {
                hl      : this.langWeb
            }
        });
    }

    cvWord(value) {
        if (!value || !value.trim()) {
            return null;
        }
        let newWord;

        newWord = value.split(/[，;,。]+/);
        return newWord;
    }

    addFeedback(feedback: string) {
        if (feedback) {
            this.listFeedback.push(JSON.parse(feedback));
        }
    }

    feedbackEvent(data) {
        if (data) {
            const feedback: Feedback = data['feedback'];
            const type: string = data['type'];

            if (type == 'delete') {
                let newFeedbacks: Feedback[] = [];
                this.listFeedback.map(item => {
                    if (item.id !== feedback.id) {
                        newFeedbacks.push(item);
                    }
                });
                this.listFeedback = newFeedbacks;
            }

            if (type == 'edit') {
                this.listFeedback.map(item => {
                    if (item.id == feedback.id) {
                        item.mean = feedback.mean;
                    }
                });
            }
        }
    }
    
    login() {
        this.commonService.openModal('login')
    }
}
