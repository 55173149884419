import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

    transform(time: any, type: string): unknown {
        let dateTime: Date;
        if (type === 'timestamp') {
            dateTime = new Date(time * 1000);
            let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            let year = dateTime.getFullYear();
            let month = months[dateTime.getMonth()];
            let date = dateTime.getDate();

            return date + ' ' + month + ', ' + year ;
        }

        if (type === 'UTC') {
            // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // console.log(this.convertTZ(new Date(time.date), timezone))
            let newTime = new Date(time.date).getTime() + (7*60*60*1000);
            dateTime = new Date(newTime);
            const currDate = this.convertNumber(dateTime.getDate());
            const currMonth = this.convertNumber(dateTime.getMonth() + 1);
            const currYear = dateTime.getFullYear();
            const currHour = this.convertNumber(dateTime.getHours());
            const currMinutes = this.convertNumber(dateTime.getMinutes());
            return currHour + ':' + currMinutes + ' ' + currDate + '-' + currMonth + '-' + currYear;
        }

        if (type === 'datetime') {
            const d = new Date(time * 1000);
            const currDate = this.convertNumber(d.getDate());
            const currMonth = this.convertNumber(d.getMonth() + 1);
            const currYear = d.getFullYear();
            return currDate + '-' + currMonth + '-' + currYear;
        }

        return '';
    }

    convertNumber(value) {
        if (value < 10) {
            return '0' + value;
        } else {
            return value;
        }
    }

    convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }
}
