import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { BroadcasterService } from './broadcaster.service';
import { ModalService } from '../modules/modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
    langPack: string = null;
    environment: string = 'client';
    inforUser: User = null;
    statusLogin: boolean = false;
    // langWeb: string = 'vi';
    dataHistory: any;

    public langWeb = new Subject<string>();

    constructor(
        private http: HttpClient,
        private broadcaster: BroadcasterService,
        private modalService: ModalService
    ) { 
    }

    setLangPack(langPack: string) {

        if (this.langPack === langPack) {
            return;
        }

        this.langPack = langPack;
        this.setLangWeb(langPack);
        this.setLocal('currentLanguage', langPack);
    }

    setLangWeb(lang) {
        this.langWeb.next(lang);
    }

    getLangWeb(): Observable<string> {
        return this.langWeb.asObservable();
    }

    getLangPack() {
        return this.langPack;
    }

    setEnvironment(env) {
        if (this.environment === env) {
            return;
        }
        this.environment = env;
    }

    getEnvironment() {
        return this.environment;
    }

    setInforUser(data: User) {
        if (data) {
            data.premium = this.checkPremium(data);
            this.setLocal('inforUser', data);
        }
        this.inforUser = data;
        this.broadcaster.broadcast('change-infor', data);
    }

    getInforUser() {
        return this.inforUser;
    }

    setLoginStatus(value: boolean) {
        this.statusLogin = value;
    }

    getLoginStatus() {
        return this.statusLogin;
    }

    convertSlug(slug: string, index: number) {
        let arr = slug.split("-");
        return arr[index];
    }

    strip(html) {
        if (this.environment == 'client') {
            var tmp = document.createElement("div");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        }
        return '';
    }

    logoutUser() {
        this.setInforUser(null);
        this.setLoginStatus(false);
        this.broadcaster.broadcast('user-infor', null);
        this.removeLocal('lesson-status');
        this.removeLocal('user-level');
    }

    pushHistory(query, type) {

        if (!this.dataHistory) {
            this.dataHistory = {};
        }

        if (!this.dataHistory[type]) {
            this.dataHistory[type] = [];
            this.dataHistory[type].push(query);

        } else {

            this.dataHistory[type].forEach((item, index) => {
                if (item === query) {
                    this.dataHistory[type].splice(index, 1);
                }
            });

            this.dataHistory[type].push(query);
        }

        if (this.dataHistory[type].length > 12) {
            this.dataHistory[type].splice(0, 1);
        }

        this.setLocal('history-search', JSON.stringify(this.dataHistory));

    }

    scrollToTop() {
        if (this.getEnvironment() === 'client') {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    scrollModal() {
        document.getElementsByClassName('modal-dialog')[0].scrollTop = 0;
    }

    changeContent(value) {
        let text;

        if (value && value.trim()) {
            text = this.convertInputHTML(value);
            return text; 
        } else {
            return '';
        }
    }

    convertInputHTML(html) {
        let dataResult = '';

        if (html.indexOf('<div>') !== -1 && html.indexOf('</div>') !== -1 && html.indexOf('&lt;') === -1 && html.indexOf('&gt;') === -1) {
            this.removeScript(html, res => {
                dataResult = res;
            });
        } else {
            if (html.indexOf('<script>') !== -1 && html.indexOf('</script>') !== -1) {
                this.removeScript(html, res => {
                    if (res !== '') {
                        dataResult = this.removeElm(res);
                    } else {
                        dataResult = '';
                    }
                });

            } else {
                const newHtml = this.removeElm(html);
                this.removeScript(newHtml, res => {
                    dataResult = res;
                });
            }
        }

        if (dataResult.indexOf('<input') !== -1) {
            let newData = dataResult.replace(/<input(.|\n)*?\/>/gi, '');
            newData = newData.replace(/<\/input>/gi, '');
            dataResult = newData.replace(/<input(.|\n)*?>/gi, '');
        }

        if (dataResult.indexOf('<textarea') !== -1) {
            const dataNoTextAre = dataResult.replace(/<textarea(.|\n)*?>/gi, '');
            dataResult = dataNoTextAre.replace(/<\/textarea>/gi, '');
        }
        return dataResult.trim();
    }

    removeScript (a, callback) {
        if (!a || !a.trim()) {
            callback('');
        }

        const regexScript = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
        const b = a.replace(regexScript, '');

        if (b !== '' && b.trim() !== '') {
            if (b.indexOf('<script>') !== -1 && b.indexOf('</script>') !== -1) {
                this.removeScript(b, callback);
            } else {
                callback(b);
            }
        } else {
            callback('');
        }
    }

    removeElm(html) {
        if (html.indexOf('<a') !== -1 && html.indexOf('</a>') !== -1 && html.indexOf('href=') !== -1) {
            html = html.replace(/<a/gi, '&lt;div&gt;&lt;a')

            html = html.replace(/<\/a>/gi, '&lt;/a&gt;&lt;/div&gt;');
        }

        html = html.replace(/<br>|<\/br>/gi, '&lt;br&gt;');
        if (html.indexOf('<p') !== -1 && html.indexOf('</p>') !== -1) {
            html = html.replace(/<p/gi, '&lt;div').replace(/<\/p>/gi, '&lt;/div&gt;');
        }
        
        if (html.indexOf('<ul>') !== -1 || html.indexOf('<ol>') !== -1) {
            return html;
        } else {
            const tmp = document.createElement('div');
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerHTML || '';
        }
    }

    checkStrEmpty(value) {
        if (!value) {
            return true;
        }
        let newValue = value.replace(/(<([^>]+)>)/ig, '');
        newValue = newValue.replace(/&nbsp;/g, '');
        if (!newValue || !newValue.trim()) {
            return true;
        } else {
            return false;
        }
    }

    openModal(id) {
        this.modalService.open(id);
    }

    closeModal(id) {
        this.modalService.close(id);
    }

    setLocal(key: string, value: any) {
        if (this.environment == 'client') {
            if (typeof value === 'string') {
                localStorage.setItem(key, value);
            } else {
                localStorage.setItem(key, JSON.stringify(value));
            }
        }
    }

    getLocal(key: string) {
        if (this.environment == 'client') {
            const value = localStorage.getItem(key);
            if (value) {
                try {
                    return JSON.parse(value);
                } catch (error) {
                    return value;
                }
            }
        }
        return '';
    }

    removeLocal(key: string) {
        if (this.environment == 'client') {
            localStorage.removeItem(key);
        }
    }

    checkPremium(user: User) {
        if (user.is_premium == 1) {
            return true;
        } else {
            if (user.premium_expired) {
                const now = new Date();
                let timeNow = now.getTime();

                if (user.premium_expired*1000 > timeNow) {
                    return true;
                } else {
                    return false;
                }
            } 
        }
        return false;
    }
}
