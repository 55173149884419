import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../models/user';
import { CommonService } from '../../../services/common.service';
import { UserService } from '../../../services/user.service';
import { Subscription } from 'rxjs';
import { BroadcasterService } from './../../../services/broadcaster.service';
import { Rate, Report } from '../activity/activity';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    inforUser: User | undefined; 
    myImage; 
    listReport: Report[] = []; 
    listRate: Rate[] = []; 
    localData; 
    grammarDetail; 
    wordDetail; 
    eventChangeLang!: Subscription;
    editName: boolean = false; 
    editPass: boolean = false; 
    changePassStatus: boolean; 
    showMore: boolean = false;
    username: string = ''; 
    langWeb: string = '';
    dataChangePass!: FormGroup;
    language: string = '';
    dictSearch: string = '';
   
    constructor(
        private commonService: CommonService,
        private userService: UserService,
        private router: Router,
        private broadcaster: BroadcasterService,
        public translate: TranslateService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.editName = false;
        this.editPass = false;
        this.showMore = false;
        this.changePassStatus = false;
        this.listReport = [];
        this.listRate = [];
        this.localData = [];
        this.langWeb = this.commonService.getLangPack();

        if (this.commonService.getEnvironment() === 'client') {
            this.inforUser = this.commonService.getInforUser();
            if (this.inforUser) {
               this.getData();
            } else {
                this.router.navigate(['/']);
            }
        }
        this.dataChangePass = new FormGroup({
            old_password      : new FormControl('', [ Validators.required, Validators.minLength(6)]),
            new_password      : new FormControl('', [ Validators.required, Validators.minLength(6)]),
            confirm_password  : new FormControl('', [ Validators.required, Validators.minLength(6)])
        });
        this.route.queryParams.subscribe(params => {
            this.language = params.hl;            
        });
        this.getUserActivity();
    }

    ngOnDestroy() {
        if (this.commonService.getEnvironment() === 'client') {
            // this.eventChangeLang.unsubscribe();
        }
    }

    getData() {
        this.userService.profile((data) => {
            if (data && data.status === 200) {
                this.inforUser = data.data;
                this.username = this.inforUser.username;
                this.updateDataUser();
                this.commonService.scrollToTop();
            }
        });
    }

    getUserActivity() {
        this.userService.userActivity( this.language, (data) => {
            if (data) {   
                this.listReport = data.reports.data.slice(0,5);
                this.listRate = data.rates.data.slice(0,5);
            }
        });
    }

    showWord(item) {
        this.router.navigate(['/search/word', item.word], {
            queryParams: {
                hl      : this.commonService.getLangPack()
            }
        });       
    }
    
    updateDataUser() {
        this.commonService.setInforUser( this.inforUser );
    }

    postAvatar(file) {
        if (file.target.files[0].size > (2 * 1024 * 1024)) { // 2MB
            this.broadcaster.broadcast('notify', 
                {
                    'content': this.translate.instant('user.txt_avatar_max'),
                    'type': 'success'
                }
            )
            return;
        }
        this.myImage = file;

        setTimeout(() => {
            this.commonService.openModal('modal-crop-avatar');
        }, 300);
    }

    changeImageOk(image) {
        this.inforUser.image = image;
        this.commonService.setInforUser(this.inforUser);
        this.commonService.openModal('modal-crop-avatar');
        this.broadcaster.broadcast('notify', 
            {
                'content': this.translate.instant('user.txt_change_avatar_success'),
                'type': 'success'
            }
        )
    }

    editUsername() {
        this.editName = true;
    }

    editPassword() {
        this.editPass = true;
    }

    checkEmpty() {
        if ( !this.username || (this.username === null) || (this.username === undefined) ) {
            return true;
        } else {
            return false;
        }
    }

    updateUsername() {
        if (this.checkEmpty()) {
            return;
        } else {
            this.editName = false;
            if (this.inforUser.username === this.username) {
                return;
            } else {
                let dataUpdate = {
                    "username": this.username,
                    "birthday": this.inforUser.birthday,
                    "language": this.inforUser.language,
                    "gender": this.inforUser.gender
                }

                this.userService.editProfile(dataUpdate, (data) => {
                    if ( data.status === 204 ) {
                        this.inforUser.username = this.username;
                        this.updateDataUser();
                    } else {
                        this.username = this.inforUser.username;
                    }
                });
            }
        }
    }

    changePass() {
        const data = this.dataChangePass.value;
        if ( this.dataChangePass.invalid ) {
            return;
        }
        if ( data.new_password !== data.confirm_password ) {
            return;
        }
        this.changePassStatus = false;

        let dataUpdate = {
            "old_password": data.old_password,
            "new_password": data.new_password
        }

        this.userService.changePassword( dataUpdate, (res) => {
            if (res.status === 204) {
                this.broadcaster.broadcast('notify', 
                    {
                        'content': this.translate.instant('user.txt_pass_change_success'),
                        'type': 'success'
                    }
                )
                this.editPass = false;
                this.dataChangePass.reset();
            } else {
                this.changePassStatus = true;
            }
        });
    }

    cancel() {
        this.editPass = false;
        this.dataChangePass.reset();
    }

    onTextChange() {
        this.changePassStatus = false;
    }

    moreActivity() {
        this.router.navigateByUrl('/activity' + '?hl=' + this.language);
    }

    detailItem(value) {
        let type;

        if (value.type === 'cnvi' || value.type === 'cnen') {
            type = 'word';
        } else if (value.type === 'svg') {
            type = 'kanji';
        } else {
            type = 'grammar';
        }

        // let objectKey;
        // objectKey = {
        //     id: value.word_id,
        //     type,
        //     lang: this.commonService.convertLang()
        // };
        // objectKey = JSON.stringify(objectKey);

        // if ( (this.localData[objectKey] === null) || (this.localData[objectKey] === undefined) ) {

        //     this.searchService.searchWordByType(this.commonService.convertLang(), value.type, value.word_id, (data) => {
        //         if (data) {
        //             this.localData[objectKey] = data;

        //             if (type === 'grammar') {
        //                 this.grammarDetail = data;
        //                 $('#modal-details-grammar').modal('show');
        //             } else if (type === 'word') {
        //                 this.wordDetail = data;

        //                 setTimeout(() => {
        //                     $('#modal-detail-word').modal('show');
        //                 }, 200);
        //             } else {
        //                 this.router.navigate(['/search'], {
        //                     queryParams: {
        //                         dict    : this.commonService.convertLang(),
        //                         type    : 'kanji',
        //                         query   : data.word,
        //                         hl      : this.langWeb
        //                     }
        //                 });
        //             }
        //         }
        //     });
        // } else {
        //     if (value.type === 'grammar') {
        //         this.grammarDetail = this.localData[objectKey];
        //         $('#modal-details-grammar').modal('show');

        //     } else if (value.type === 'cnvi' || value.type === 'cnen') {
        //         this.wordDetail = this.localData[objectKey];
        //         setTimeout(() => {
        //             $('#modal-detail-word').modal('show');
        //         }, 200);
        //     } else {

        //     }
        // }
    }
}
