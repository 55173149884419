import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import * as CONFIG from '../config';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private http: HttpClient,
        private commonService: CommonService
    ) { }
    
    login(email, password, callback) {
        let dataSend;

        const urlLogin = CONFIG.BASE_API_URL + 'api/account/login';
        dataSend = {
            email    : email.trim(),
            password : password.trim()
        };
        this.http.post(urlLogin, dataSend, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    register(email, password, callback) {
        let dataSend;

        const urlRegister = CONFIG.BASE_API_URL + 'api/account/register';
        dataSend = {
            email    : email.trim(),
            password : password.trim(),
            language : this.commonService.getLangPack()
        };

        this.http.post(urlRegister, dataSend, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );

    }

    profile(callback) {

        const urlProfile = CONFIG.BASE_API_URL + 'api/account/profile';

        this.http.get(urlProfile, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );

    }

    logout(token, callback) {
        let dataSend;

        const url = CONFIG.BASE_API_URL + 'api/account/logout';
        dataSend = {
            token
        };

        this.http.post(url, dataSend, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    updateAvatar(image, callback) {
        let dataSend;

        const url = CONFIG.BASE_API_URL + 'api/account/edit-avatar';

        dataSend = {
            image,
            option: 'base64'
        };

        this.http.post(url, dataSend, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    editProfile(dataUpdate, callback) {

        const url = CONFIG.BASE_API_URL + 'api/account/edit-profile';

        this.http.post(url, dataUpdate, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    changePassword(dataUpdate, callback) {

        const url = CONFIG.BASE_API_URL + 'api/account/password';

        this.http.post(url, dataUpdate, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    userActivity(language, callback) {
        const url = CONFIG.BASE_API_URL + 'api/account/history/' + language;
        this.http.get(url, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );		

    }
}
