import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'app-box-banner',
    templateUrl: './box-banner.component.html',
    styleUrls: ['./box-banner.component.css']
})
export class BoxBannerComponent implements OnInit {
    langWeb: string = this.commonService.getLangPack();
    
    constructor(
        public commonService: CommonService,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            if (this.langWeb != event.lang) {
                this.langWeb = event.lang;
            }
        });
    }

}
