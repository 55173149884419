import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements HttpInterceptor {

    constructor(
        private commonService: CommonService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const user = this.commonService.getInforUser();
        let authToken;
        let checkAPI;

        // check request in my server api or another api (google, ....)
        checkAPI = req.url.includes('api/');

        if (checkAPI && user && user.token) {
            // if user logged and api is mine
            authToken = user.token;
            const authReq = req.clone({
                headers: req.headers.set('Authorization', authToken)
            });
            return next.handle(authReq);
        } else {
            return next.handle(req);
        }

    }
}
