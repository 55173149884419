<app-modal id="modal-add-note">
    <div class="modal-dialog modal-dialog-centered cl-content">
        <div class="modal-content">
            <div class="modal-body box-add-cate">
                <div class="title-modal">
                    <span>{{ 'txt_add_to_folder' | translate }}</span>
                    <!-- <img class="icon-12" src="assets/images/icons/ic_add.png" (click)="addCate()" alt="Jaemy korean dictionary"> -->

                    <!-- <div class="btn-close-df" (click)="closeModal()"> 
                        <img class="icon-16" src="assets/images/icons/ic_close.png" alt="close jaemy dictionary"> 
                    </div> -->
                    <img class="icon-26 icon-add" title="{{ 'txt_create_folder' | translate }}" (click)="addCategory()" src="assets/images/icons/ic_add.png" alt="icon jaemy dictionary">
                </div>
                <div class="modal-box">
                    <div class="group-item row no-margin" *ngFor="let item of listCategory" (click)="addToCategory(item.id)">
                        <div class="col-md-3 col-3 text-center">
                            <div class="group-sign cl-tt">
                                {{ item.name.charAt(0).toUpperCase() }}
                            </div>
                        </div>
                        <div class="col-md-9 col-9 no-pd-left">
                            <div class="category-name">
                                <span title="{{ item.name }}">{{ item.name }}</span>
                            </div>
                            <div class="item-infor">
                                <span>{{ ( item.notebooks_count > 0 ) ? item.notebooks_count + 'w' : ('txt_no_data' | translate ) }}</span>
                                <span>{{ item.created_at | time:'datetime' }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="notify" *ngIf="notify.length > 0">{{ notify }}</div>
                    <div class="txt-err text-center" *ngIf="err.length > 0">{{ err }}</div>
                    <app-paginate-total *ngIf="totalCategory > 0" [total]="totalCategory" [page]="0" [limit]="limit" (paginateEvent)="paginateCategoryEvent($event)"></app-paginate-total>
                </div>
            </div>
        </div>
    </div>
</app-modal>
