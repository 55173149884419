export interface Help {
    id: number,
    title: string,
    content: HelpContent[]
}
export interface HelpContent {
    content: string,
    childHelp: ChildHelp[]
}
export interface ChildHelp {
    childContent: string,
    itemChild: ItemChild[]
}
export interface ItemChild {
    child: string,
    guide: Guide[]
}
export interface Guide {
    content: string,
    steps: string[]
}

export const ListHelp: Help[] =  [
    {
        id: 1,
        title: "Hướng dẫn sử dụng Jaemy.net",
        content: [
            {
                content: "Từ điển tiếng Hàn Jaemy là từ điển đa năng tích hợp nhiều tính năng hữu ích phục vụ quá trình học tiếng Hàn của bạn:",
                childHelp: [
                    {
                        childContent: "1. Tra từ",
                        itemChild: [
                            {
                                child: "Kết quả tra từ sẽ được trả về: nghĩa, cách phát âm romaji, audio, ví dụ, từ đồng nghĩa, từ liên quan, các từ gợi ý, hình ảnh, từ điển mở, và cho phép ghi chú. Nhờ các cách tra cứu này, bạn có thể đồng thời luyện tập các kỹ năng khác (nói, viết), không cần cài đặt bàn phím tiếng Hàn, nhanh chóng, tiện lợi.",
                                guide: [
                                    {
                                        content: "Bạn có thể tra từ dễ dàng bằng nhiều cách:",
                                        steps: [
                                            'Gõ từ trong ô tìm kiếm',
                                            'Sử dụng giọng nói để tìm kiếm',
                                            'Chụp hoặc sử dụng hình ảnh có sẵn để quét và tìm từ',
                                            'Vẽ từ'
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        childContent: "2. Dịch đoạn văn",
                        itemChild: [
                            {
                                child: "Dịch các đoạn văn trong tài liệu, giáo trình, bài báo,... Bạn cũng có thể dịch bằng nhiều cách: gõ chữ, quét ảnh, giọng nói, vẽ. Kết quả dịch dễ dàng được copy và lưu lại lịch sử. Bên cạnh đó, tính năng dịch cuộc hội thoại rất hữu dụng cho các bạn thường xuyên phải giao tiếp tiếng Hàn. Jaemy đóng vai trò như 1 người phiên dịch giúp bạn nhanh chóng bắt kịp nội dung cuộc nói chuyện.",
                                guide: [
                                    {
                                        content: "",
                                        steps: [""]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        childContent: "3. Tra cứu động từ bất quy tắc",
                        itemChild: [
                            {
                                child: "Động từ trong tiếng Hàn có cách chia rất phức tạp. Đặc biệt là các động từ bất quy tắc, người mới học sẽ rất dễ nhầm lẫn, dẫn tới chia sai. Jaemy có tổng hợp cách chia động từ phân theo thì thể (hiện tại, quá khứ, tương lai,...), theo loại câu, theo các biểu hiện (thân mật, trang trọng) giúp bạn nhanh chóng biết cách chia chính xác trong từng trường hợp.",
                                guide: [
                                    {
                                        content: "",
                                        steps: [""]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        childContent: "4. Sổ tay từ vựng",
                        itemChild: [
                            {
                                child: "Lưu lại các từ đã tra để dễ dàng xem lại và ôn tập. Những từ vựng mà bạn có ghi chú cũng sẽ được xuất hiện tại đây.",
                                guide: [
                                    {
                                        content: "",
                                        steps: [""]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        childContent: "5. Học tập từ vựng",
                        itemChild: [
                            {
                                child: "Trau dồi vốn từ là điều cần thiết để bạn chinh phục tiếng Hàn. Không chỉ là tra từ đơn thuần, bạn sẽ học và ghi nhớ từ vựng theo từng chủ đề và cấp độ. Luyện tập với flashcard và phần luyện nghe.",
                                guide: [
                                    {
                                        content: "",
                                        steps: [""]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        childContent: "6. Cộng đồng",
                        itemChild: [
                            {
                                child: "Kết nối với các bạn học tiếng Hàn trên khắp mọi miền tổ quốc, cùng nhau chia sẻ về phương pháp học tập, hỏi han, kết bạn, giúp đỡ nhau cùng tiến bộ. Người dùng có thể đăng bài, tương tác với các bài viết khác, lựa chọn các chủ đề quan tâm,...",
                                guide: [
                                    {
                                        content: "",
                                        steps: [""]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        title: "Hướng dẫn đăng ký, đăng nhập",
        content: [
            {
                content: "Nếu bạn đã đăng ký tài khoản sử dụng Jaemy, hãy nhấn chọn phần đăng nhập và điền đầy thông tin để bắt đầu tra dịch cùng Jaemy. Nếu chưa có tài khoản, vui lòng nhấn vào phần Đăng ký (góc phải màn hình) hoặc phần đăng ký tạo tài khoản mới (trong ô đăng nhập). Việc đăng ký có thể tốn của bạn 2 phút, với một số thông tin cơ bản. Bạn có thể đăng ký nhanh bằng tài khoản Google. Để đảm bảo quyền lợi và trách nhiệm của bản thân, vui lòng đọc kỹ điều khoản sử dụng của Jaemy trước khi hoàn tất việc đăng ký này.",
                childHelp: [{
                    childContent: "",
                    itemChild: [{
                        child: "",
                        guide: [{
                            content: "",
                            steps: [""]
                        }]
                    }]
                }]
            }
        ]
    },
    {
        id: 3,
        title: "Quên mật khẩu, lấy lại bằng cách nào ?",
        content: [
            {
                content: "Trong trường hợp đã đăng ký tài khoản sử dụng Jaemy, nhưng quên mật khẩu. Bạn có thể lấy lại mật khẩu và đăng nhập lại bằng các cách sau:",
                childHelp: [
                    {
                        childContent: "1. Chọn mục đăng nhập (Góc phải thanh công cụ). Lựa chọn mục Quên mật khẩu, lấy lại. Và làm theo hướng dẫn (Bạn cần nhớ thông tin về mail đăng ký tài khoản Jaemy).",
                        itemChild: [{
                            child: "",
                            guide: [{
                                content: "",
                                steps: [""]
                            }]
                        }]
                    },
                    {
                        childContent: "2. Trong trường hợp khẩn cấp, bạn có thể gửi mail trực tiếp cho bộ phận hỗ trợ của Jaemy: jaemy@eupgroup.net hoặc gọi điện trực tiếp đến số Hotline: 0354 7373 64. Chúng tôi sẽ cố gắng giải quyết sớm nhất có thể.",
                        itemChild: [{
                            child: "",
                            guide: [{
                                content: "",
                                steps: [""]
                            }]
                        }]
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        title: "Góp ý nội dung bằng cách nào ?",
        content: [
            {
                content: "Nhằm phục vụ tốt hơn người dùng, Jaemy mong muốn nhận được những đóng góp của các bạn. Để làm điều này, các bạn vui lòng gửi mail vào hòm thư: Jaemy@eupgroup.net.",
                childHelp: [{
                    childContent: "",
                    itemChild: [{
                        child: "",
                        guide: [{
                            content: "",
                            steps: [""]
                        }]
                    }]
                }]
            }
        ]
    },
    {
        id: 5,
        title: "Thay đổi ngôn ngữ trên Jaemy.net",
        content: [
            {
                content: "Hiện Jaemy cho phép người dùng có thể tự di chuyển đổi giữa hai ngôn ngữ: tiếng Việt và tiếng Anh. Để thay đổi ngôn ngữ trong Jaemy, vui lòng chọn biểu tượng lá cờ (góc phải của thanh công cụ).",
                childHelp: [{
                    childContent: "",
                    itemChild: [{
                        child: "",
                        guide: [{
                            content: "",
                            steps: [""]
                        }]
                    }]
                }]
            }
        ]
    },
    {
        id: 6,
        title: "Hướng dẫn tải ứng dụng về di động",
        content: [
            {
                content: "Trường hợp bạn đang sử dụng Jaemyg.net trên điện thoại và muốn tải ứng dụng Jaemy về máy: Tại chân trang của website, ấn vào biểu tượng tải ứng dụng Jaemy, bạn sẽ được điều hướng về cửa hàng ứng dụng và tải về trên điện thoại của bạn.",
                childHelp: [{
                    childContent: "",
                    itemChild: [{
                        child: "",
                        guide: [{
                            content: "",
                            steps: [""]
                        }]
                    }]
                }]
            }
        ]
    },
    {
        id: 7,
        title: "Làm thế nào để nâng cấp ứng dụng ?",
        content: [
            {
                content: 'Bạn vui lòng ấn chọn "Nâng cấp VIP" ở góc dưới bên trái, chúng tôi sẽ chuyển hướng bạn tới fanpage chính thức của Jaemy. Tại đây, bạn có thể chat trực tiếp với tư vấn viên về mong muốn nâng cấp ứng dụng. Chúng tôi sẽ hướng dẫn cụ thể và kích hoạt nâng cấp cho bạn.',
                childHelp: [{
                    childContent: "",
                    itemChild: [{
                        child: "",
                        guide: [{
                            content: "",
                            steps: [""]
                        }]
                    }]
                }]
            }
        ]
    }
]

