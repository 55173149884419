<app-modal id="modal-detail">
    <div class="modal-dialog modal-dialog-default">
        <div class="modal-content content-word">
            <div class="title-modal">
                <div class="line-title">
                    <h3 class="tab-item" [ngClass]="{'tab-active': (type == 'word') }" (click)="changeTab('word')">
                        {{ 'rs_search_word' | translate }}
                    </h3>
                    <h3 class="tab-item" [ngClass]="{'tab-active': (type == 'kanji') }" (click)="changeTab('kanji')">
                        {{ 'rs_search_kanji' | translate }}
                    </h3>
                    <h3 class="tab-item" [ngClass]="{'tab-active': (type == 'example') }" (click)="changeTab('example')">
                        {{ 'rs_search_example' | translate }}
                    </h3>
                </div>
                <div class="btn-close-df" (click)="closeModal()"> 
                    <img class="icon-22" src="assets/images/icon/ic_close.png" alt="close jaemy dictionary"> 
                </div>
            </div>
            <div class="content">
                <app-detail-word *ngIf="type == 'word' && detailWord" 
                    [data]="detailWord"
                    [googleTranslate]="googleTranslate">
                </app-detail-word>
                <div class="" *ngIf="type == 'example'">
                    <div *ngFor="let exam of listExample" class="box-item-exam">
                        <div class="box-word">
                            <div class="exam-word cl-tt">
                                {{ exam.e | tranform}}
                            </div>
                            <div class="btn-right">  
                                <div class="cust-btn-default" (click)="addToNoteBook(exam)"> <img class="icon-20" src="assets/images/icon/ic_plus2.png" alt="icon jaemy"></div>
                                <div class="cust-btn-default icon-audio" (click)="playAudio(exam, 'example', $event); $event.stopPropagation()"> 
                                    <img class="icon-18" src="assets/images/icon/ic_volume.png" alt="audio jaemy dictionary"> 
                                </div>
                            </div>
                        </div>
                        <div class="exam-phonetic">
                            {{ exam.p }}
                        </div>
                        <div class="exam-mean">
                            {{ exam.m }}
                        </div>
                    </div>
                </div>

                <div class="text-center none-data" *ngIf="loadDone && ((!detailWord && type != 'example') || (!listExample.length && type == 'example'))">
                    {{ 'txt_no_result' | translate }}
                </div>
            </div>
        </div>
    </div>
</app-modal>