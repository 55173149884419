import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BroadcasterService } from '../../services/broadcaster.service';
import { Feedback } from '../../models/search';
import { User } from '../../models/user';
import { SearchService } from '../../services/search.service';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
    @Input() feedback: Feedback;
    @Input() user: User | undefined;
    @Output() feedbackEvent = new EventEmitter();
    showMenu: boolean = false;
    showEdit: boolean = false;
    feedbackEdit: string = '';

    constructor(
        private searchService: SearchService,
        private translate: TranslateService,
        private broadcaster: BroadcasterService
    ) { }

    ngOnInit(): void {
    }

    showBoxMenu() {
        this.showMenu = true;
    }

    action(action: string) {
        if (action === 'edit') {
            this.showEdit = true;
            this.feedbackEdit = this.feedback.mean;
        }

        if (action === 'delete') {
            this.searchService.deleteFeedback(this.feedback.id).subscribe(res => {

                if (res && res['status'] === 204) {
                    // this.getDataFeedback(value.word_id, value.type, this.langSearch);
                    this.feedbackEvent.emit({feedback: this.feedback, type: 'delete'});
                } else {
                    this.broadcaster.broadcast('notify', 
                        {
                            'content': this.translate.instant('txt_mess_err'),
                            'type': 'danger'
                        }
                    )
                }
            });
        }
    } 

    saveFeedback() {
        this.searchService.editFeedback(this.feedback.id, this.feedbackEdit).subscribe(res => {
            if (res && res['status'] === 204) {
                this.feedback.mean = this.feedbackEdit;
                this.showEdit = false;
                this.feedbackEvent.emit({feedback: this.feedback, type: 'update'});
            } else {
                this.broadcaster.broadcast('notify', 
                    {
                        'content': this.translate.instant('txt_mess_err'),
                        'type': 'danger'
                    }
                )
            }
            this.feedbackEdit = '';
        });
    }

    cancel() {
        this.showMenu = false;
        this.showEdit = false;
    }

    actionReport(value, action) {
        if (!value || !action || !this.user) {
            return
        }
        this.searchService.rateFeedback(value, action).subscribe(data => {
            if (data) {
                this.feedback.like = data.like;
                this.feedback.dislike = data.dislike;
                this.feedback.action = data.like ? 1 : 0;
            }
        });
    }
} 
