import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CommonService } from '../../services/common.service';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { BroadcasterService } from '../../services/broadcaster.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    user: User | undefined; 
    titlePage: string = '';
    langWeb: string = '';
    innerWidth: number = 0;
    showTabMenu: boolean = false;

    dataLanguage = [
        {
            key     : 'vi',
            name    : 'Vietnamese',
        },
        {
            key     : 'en',
            name    : 'English',
        }
    ];

    constructor(
        public commonService: CommonService,
        private router: Router,
        private translate: TranslateService,
        private userService: UserService,
        private broadcaster: BroadcasterService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.langWeb = this.commonService.getLangPack();
        this.user = this.commonService.getInforUser();

        this.broadcaster.on('change-infor').subscribe((item: User) => {
            if (item) {
                this.user = item;
            }
        });
        
        this.route.queryParams.subscribe(params => {
           
            if (params.hl) {
                if (params.hl != this.langWeb) {
                    this.langWeb = params.hl;
                    this.updateInforAfterChange();
                }
            } else {
                setTimeout(() => {
                    this.updateParams();
                }, 500);
            }
        });
    }

    ngAfterViewInit() {
        if (this.commonService.getEnvironment() === 'client') {
            setTimeout(() => {
                this.innerWidth = window.innerWidth;
            }, 100);
        }
    }

    routerActive(menu: string) {
        // for mobile
        if (this.router.url.indexOf(menu) !== -1) {
            this.titlePage = this.translate.instant('header.'+ menu);
            return 'active-menu';
        }
    }

    routerLink(val: string) {
        this.closePanel();
        this.router.navigate(['/' + val], { queryParams: {
            hl      : this.langWeb
        }});
    }

    changeLanguage(value: string) {
        if (value === this.langWeb) {
            return;
        }
        
        this.langWeb = value;
        this.updateInforAfterChange();
        this.translate.use(this.langWeb);
        this.updateParams();
    }

    routeToHome() {
        if (this.innerWidth >= 990) {
            this.router.navigate(['/search'], {queryParams: {
                hl      : this.langWeb
            }});
        } else {
            this.showTabMenu = true;
        }
    }

    closePanel() {
        this.showTabMenu = false;
    }

    showMenu() {
        const element = document.getElementById("topnav");
        element.classList.toggle("responsive");
    }

    openModal(value) {
        this.closePanel();
        this.commonService.openModal(value);
    }

    logout() {
        if (this.user) {
            this.userService.logout(this.user.token, (data) => {
                if (data) {
                    this.commonService.removeLocal('inforUser');
                    this.commonService.setInforUser(null);
                    this.commonService.setLoginStatus(false);
                    this.router.navigate(['/search'], {queryParams: {
                        hl      : this.langWeb
                    }});
                    this.closePanel();
                    this.broadcaster.broadcast('notify', 
                        {
                            'content': this.translate.instant('user.txt_logout_success'),
                            'type': 'success'
                        }
                    )
                }
            });
        }
    }

    updateInforAfterChange() {
        this.commonService.setLangPack(this.langWeb);
        this.commonService.setLocal('currentLanguage', this.langWeb); 
    }

    updateParams() {
        let urlTree = this.router.parseUrl(this.router.url);
        urlTree.queryParams['hl'] = this.langWeb;
        this.router.navigateByUrl(urlTree);
    }
}
