import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    currentMessage = new BehaviorSubject(null);

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private commonService: CommonService,
        private firebase: AngularFireAnalytics
    ) { 
        this.angularFireMessaging.messages.subscribe( (_messaging) => {
            // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            // _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        });
    }

    logEvents(eventName: string): void {
        if (this.commonService.getEnvironment() == 'client') {
            this.firebase.logEvent(eventName);
        }
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                // console.log(token);
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
            this.currentMessage.next(payload);
        })
    }
}
