<app-modal id="modal-crop-avatar">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body box-image">
                <div class="cropArea">
                <image-cropper
                    [imageChangedEvent]="myImage"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="1 / 1"
                    [resizeToWidth]="128"
                    [backgroundColor]="333333"
                    format="png"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
                <div class="crop-image"><img src="{{myCroppedImage}}" /></div>
                </div>
                <div class="footer-image">
                <button type="button" class="btn btn-ok" (click)="chooseOk()">Ok</button>
                <button type="button" class="btn btn-cancel title-cancel" data-dismiss="modal" aria-label="Close">{{ 'txt_cancel' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</app-modal>
