import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { PaginationService } from '../../services/pagination.service';

@Component({
  selector: 'app-paginate-total',
  templateUrl: './paginate-total.component.html',
  styleUrls: ['./paginate-total.component.css']
})
export class PaginateTotalComponent implements OnInit, OnChanges {
    @Input() total: number;
    @Input() limit: number;
    @Input() page: number;
    @Output() paginateEvent = new EventEmitter();
    indexPagination; listIndex: any;
    maxSize = 7;

    constructor(
        private paginateService: PaginationService
    ) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.page) {
            this.changePagination(this.page)
        } else {
            this.indexPagination = 0;
            this.listIndex = this.paginateService.createPageArray(this.indexPagination + 1, this.maxSize, this.total, this.limit);
        }
    }

    changePagination(index) {
        if (index === 0) {
            this.indexPagination = 0;
        } else {
            this.indexPagination = index - 1;
        }
        this.listIndex = this.paginateService.createPageArray(this.indexPagination + 1, this.maxSize, this.total, this.limit);
        this.paginateEvent.emit(this.indexPagination + 1);
    }

}
