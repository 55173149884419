<div class="box-default box-content-default">
    <div class="box-activity">
        <h3 class="title">{{ 'txt_activate' | translate }}</h3>
        <div class="box-head">
            <div [ngClass]="{'fw-800': (actChoosen == 'reports')}" (click)="changeAct('reports')">{{ 'community.txt_comment' | translate }}</div>
            <div [ngClass]="{'fw-800': (actChoosen == 'rates')}" (click)="changeAct('rates')">{{ 'txt_reaction' | translate }}</div>
        </div>
        <div class="box-reports">
                <div class="act-item">
                    <div *ngIf="actChoosen == 'reports'">
                        <div *ngFor="let item of listReports" class="reports d-flex justify-content-between  border-bottom" (click)="showWord(item)">
                            <span>
                                <img alt="icon cmt" class="icon-18 mb-1" src="assets/images/icons/ic_act_cmt.png">
                                <span class="ml-2">{{ 'txt_act_comment' | translate }} : "{{ item.mean }}" </span>
                            </span>
                            <span class="time" *ngIf="item.created_at"> {{ item.created_at.date }} </span>
                        </div>
                    </div>
                    <div *ngIf="actChoosen == 'rates'" >
                        <div *ngFor="let item of listRates" class="rates"(click)="showWord(item)"  ng-repeat="created_at in item.created_at| orderBy: 'date' : true">
                            <span>
                                <img alt="icon love" class="icon-18 mb-1" src="assets/images/icons/ic_love_red.png">
                            </span>
                            <span class="ml-2">{{ 'txt_act_react' | translate }} : "{{ item.word }}"</span>
                        </div>
                    </div>
                </div>
                <div class="text-right view-more mt-4" *ngIf="listReports.length != 0 && toggle && actChoosen == 'reports'" (click)="moreActivity('reports')">
                    {{ 'txt_view_more' | translate }} 
                    <img class="icon-16 mb-1 ml-1" src="assets/images/icons/next.png" alt="icon Jaemy" />
                </div>
                <div class="text-right view-more mt-4" *ngIf="listRates.length != 0 && toggle && actChoosen == 'rates'" (click)="moreActivity('rates')">
                    {{ 'txt_view_more' | translate }} 
                    <img class="icon-16 mb-1 ml-1" src="assets/images/icons/next.png" alt="icon Jaemy" />
                </div>
            <p *ngIf="listReports.length == 0 || listRates.length == 0" class="text-center my-3">
                {{ 'txt_none_activity' | translate }}
            </p>
        </div>
    </div>
</div>
