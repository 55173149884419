import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstcase'
})
export class FirstcasePipe implements PipeTransform {

    transform(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

}
