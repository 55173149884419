<div class="footer-component app-background">
    <div class="box-default">
        <div class="row">
            <div class="col-md-3 col-sm-3 box-link">
                <h3 class="title-contain">{{ 'txt_information' | translate }}</h3>
                <div class="list-footer" routerLink='/sale-off'>{{ 'txt_about' | translate }}</div>
                <div class="list-footer" routerLink='/term'>{{ 'txt_term' | translate }}</div>
                <div *ngIf="langWeb == 'vi'" class="list-footer" routerLink='/help'>{{ 'txt_help' | translate }}</div>
                <div class="list-footer" *ngIf="langWeb == 'vi'" routerLink='/sale-off'>{{ 'txt_upgrade' | translate }}</div>
                <div class="list-footer" *ngIf="langWeb == 'en'" routerLink='/search'>{{ 'txt_upgrade' | translate }}</div>
            </div>
            <div class="col-md-4 col-sm-4">
                <h3 class="title-contain">{{ 'txt_contact' | translate }}</h3>
                <div class="list-footer" *ngIf="langWeb == 'vi'"> Phòng 403, tòa nhà số 1, ngõ 120, Trường Chinh, Đống Đa, Hà Nội </div>
                <div class="list-footer"> (+84) 354 7373 64 </div>
                <div class="list-footer"> Email: Jaemy@eupgroup.net </div>
            </div>
            <div class="col-md-5 col-sm-4 download">
                <div class="box-download text-center">
                    <h3 class="title-contain">{{ 'txt_download_app' | translate }} Jaemy</h3>
                    <a href="https://play.google.com/store/apps/details?id=com.jaemy.koreandictionary" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/icons/ic_gg_bottom.png" alt="Jaemy korean dictionary">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>