import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit {
    show: boolean = false;
    @Output() actionEvent = new EventEmitter<string>();
    @ViewChild('more') more: ElementRef;

    constructor(
        private _eref: ElementRef
    ) { }

    @HostListener('document:click', ['$event.target'])
    onClick(event){
        if (!this._eref.nativeElement.contains(event)) {
            this.show = false;
        }
    }

    ngOnInit(): void {
    }

    showMenu() {
        this.show = true;
    }

    action(action: string) {
        this.show = false;
        this.actionEvent.emit(action);
    }

}
