import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { DrawComponent } from '../../modules/draw/draw.component';

@Component({
    selector: 'app-draw-area',
    templateUrl: './draw-area.component.html',
    styleUrls: ['./draw-area.component.css']
})
export class DrawAreaComponent implements OnInit {

    @Output() insertQueryText = new EventEmitter();
    @ViewChild('draw') draw: ElementRef;
    @ViewChild(DrawComponent, {static: true}) drawComponent: DrawComponent;
    width:Subject<number> = new BehaviorSubject(0);
    
    suggestions: string[] = [];
    clearData: boolean;

    constructor() { }

    ngOnInit(): void {
    }

    clear() {
        // clear canvas 
        this.drawComponent.clear();
        this.suggestions = []
    }

    choose(value) {
        if (value != null && value !== '') {
            this.insertQueryText.emit(value);
            this.clear();
        }
    }
}
