
<p class="cl-tt">{{ 'title_details_word' | translate }}</p>
<div class="detail-word content-result-white">
    <div *ngIf="!googleTranslate && dataWord">
        <div class="box-word">
            <div class="txt-word-default">
                <span class="cl-text font-20">{{ dataWord.word }} </span>
                <span *ngIf="dataWord.romaja" class="txt-romaja">/ {{ dataWord.romaja }} /</span>
            </div>
            <div class="word-related font-14">
                <span *ngIf="dataWord.hanviet && langWeb === 'vi'" class="txt-romaja">[ {{ dataWord.hanviet }} ]</span>
                <span *ngIf="dataWord.pronunciation" class="txt-romaja">「 {{ dataWord.pronunciation }} 」</span>
                <span *ngIf="dataWord.hanja" class="txt-romaja">[ {{ dataWord.hanja }} ]</span>
            </div>
            <div class="btn-right">  
                <!-- <div class="" (click)="report()"> 
                    <img class="icon-20" src="assets/images/icons/ic_report2.png" alt="Korean dictionary">
                </div> -->
                <div class="icon-audio dp-inblock" (click)="addNote()" title="{{ 'txt_note' | translate }}"> 
                    <img class="icon-20" src="assets/images/icons/ic_label.png" alt="icon Jaemy dictionary">
                </div>
                <div class="icon-audio dp-inblock" (click)="playAudio(dataWord.word, 'word')"> 
                    <img class="icon-24" src="assets/images/icons/ic_volume2.png" alt="audio Jaemy dictionary"> 
                </div>
            </div>
        </div>
        <div class="box-detail">
            <div class="box-content" *ngFor="let content of dataWord.content">
                <p *ngIf="content.kind" class="txt-main-word">{{ 'txt_kind' | translate }}: {{ content.kind }}</p>
                <div class="item-content" *ngFor="let mean of content.means">
                    <div class="icon-dot">
                        <img class="icon-12" src="assets/images/icons/ic_dot.png" alt="icon Jaemy dictionary"> 
                    </div>
                    <div class="box-mean">
                        <div class="txt-mean"> 
                            {{ mean.mean | firstcase }} 
                            <!-- <span *ngIf="mean.pinyinWord" class="txt-romaja">【 {{ mean.pinyinWord }} 】</span> -->
                        </div>
                        <div class="txt-explain" *ngIf="mean.explain">
                            <span> {{ mean.explain }}</span>
                            <!-- <span *ngFor="let item of mean.cvExplain" (click)="searchItem(item)">
                                {{ item }}
                            </span> -->
                        </div>
                        <!-- <div class="text-more" *ngIf="mean.pinyin">
                            {{ mean.pinyin }}
                        </div> -->
                        <div class="txt-mexplain" *ngIf="mean.m_explain">
                            <span> {{ mean.m_explain }}</span>
                        </div>
                        <div class="text-more" *ngIf="mean.trans">{{ mean.trans }}</div>
                    </div>
                    <div class="list-example">
                        <p *ngIf="mean.content_examples && mean.content_examples.length > 0 " class="txt-main-word">{{ 'rs_search_example' | translate }}:</p>
                        <div class="box-example" *ngFor="let exam of mean.content_examples">
                            <div class="box-audio" (click)="playAudio(exam.e, 'example')">
                                <img class="icon-16" src="assets/images/icons/ic_volume.png" alt="icon Jaemy dictionary"> 
                            </div>
                            <div class="content-example">
                                <p class="ex-word">{{ exam.e }}</p>
                                <p class="ex-phonetic">{{ exam.p }}</p>
                                <p class="ex-mean">{{ exam.m }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="dataWord.measure && dataWord.measure.measure" class="box-compound">
                <p class="word-type">
                    <span class="txt-main-word">{{ 'txt_measure_word_dictionary' | translate }}</span>
                    <span class="word-deco">: {{ dataWord.measure.measure }}  【{{ dataWord.measure.pinyin }}】</span>
                </p>
                <div class="txt-compound txt-explain">
                    <span>{{  dataWord.measure.examples }}</span>
                </div>
            </div>
            <div *ngIf="dataCvCompound" class="box-compound">
                <p class="txt-main-word">{{ 'txt_compound_word_dictionary' | translate }}:</p>
                <div class="txt-compound txt-explain">
                    <span *ngFor="let item of dataCvCompound" (click)="searchItem(item)">
                        {{ item }}
                    </span>
                    <span class="show-more" *ngIf="hidden" (click)="showMore()">
                        {{ 'txt_view_more' | translate }}
                    </span>
                </div>
            </div>
            <div *ngFor="let data of dataWord.snym" class="box-compound">
                <p class="txt-main-word" *ngIf="data.kind == 'anto'">
                    {{ 'txt_antonym' | translate }}:
                </p>
                <p class="txt-main-word" *ngIf="data.kind == 'syno'">
                    {{ 'txt_synonym' | translate }}:
                </p>
                <p class="txt-main-word" *ngIf="data.kind == 'related'">
                    {{ 'txt_related' | translate }}:
                </p>
                <div class="txt-compound txt-explain">
                    <span *ngFor="let item of data.content" (click)="searchItem(item)">
                        {{ item }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="googleTranslate">
        <div class="box-word">
            <div class="txt-word-default">
                {{ dataWord.word }} 
                <span *ngIf="dataWord.pinyin" class="txt-romaja">【 {{ dataWord.pinyin }} 】</span>
                <span *ngIf="hanjiContain" class="txt-romaja">【 {{ hanjiContain }} 】</span>
            </div>
            <div class="btn-right">  
                <div class="icon-audio" (click)="playAudio(dataWord.word, 'word')"> 
                    <img class="icon-20" src="assets/images/icons/ic_volume.png" alt="audio Jaemy dictionary"> 
                </div>
            </div>
        </div>
        <div class="item-content" *ngFor="let mean of dataWord.content[0].means">
            <div class="txt-mean"> {{ mean.mean }} </div>
            <div class="box-source">
                {{ dataWord.source }}
            </div>
        </div>
    </div>
</div>

<p class="cl-tt mt-20" *ngIf="!googleTranslate">{{ 'rs_search_open_dict' | translate }}</p>
<div class="box-feedback content-result-white" *ngIf="listFeedback.length && !googleTranslate">
    <div class="item-feedback" *ngFor="let item of listFeedback.slice(0,3)" >
        <app-feedback 
            [feedback]="item" 
            [user]="user"
            (feedbackEvent)="feedbackEvent($event)">
        </app-feedback>
    </div>
    <div class="show-more" *ngIf="listFeedback.length > 3" (click)="goOpenDict()">{{ 'txt_view_more' | translate }}</div>
</div>
<div *ngIf="!user && !googleTranslate" class="box-feedback content-result-white">
    <span class="text-login" (click)="login()">
        {{ 'user.txt_login' | translate }}
    </span> 
    {{ 'txt_comment_word' | translate }}!
</div>
<app-add-feedback *ngIf="user && dataWord && !googleTranslate" 
    [dataWord]="{wordId: dataWord.id, type: 'word', word: dataWord.word}" 
    [user]="user"
    (addFeedback)="addFeedback($event)">
</app-add-feedback>