<div class="term-component box-default box-content-default">
    <div class="box-term">
        <p class="title-term text-center mt-2"> {{ 'user.txt_term_of_use' | translate }} </p>
        <hr>
        <div class="content-term">
            <div class="box-item" *ngFor="let term of terms">
                <div class="title-item" *ngIf="term.title">{{ term.title }}</div>
                <div *ngFor="let item of term.cateContent">
                    <div class="title-item">{{ item.title }}</div> 
                    <div class="ml-4" *ngFor="let content of item.content">
                        <div class="content" *ngIf="content.termContent">{{ content.termContent }}</div>
                        <div class="ml-4" *ngFor="let item of content.childTerm">
                            <li *ngIf="item.childTerm">
                                {{ item.childTerm }}
                                <div *ngFor="let child of item.childContent">
                                    <p *ngIf="child">
                                        {{ child }}
                                    </p>
                                </div>
                            </li>
                        </div>  
                    </div>
                </div>
            </div>
            <div class="delegate">
                <div class="text-center">
                    <p class="title-item"> {{ 'txt_legal_representative' | translate }} </p>
                    <i class="text-black"> {{ 'txt_ps' | translate }} </i>
                </div> 
            </div>  
        </div>
    </div>
</div>