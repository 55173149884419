<app-modal id="login">
    <div class="modal-dialog">
        <div class="modal-content login-component">
            <div class="modal-header">
                <div class="title">{{ 'user.txt_login' | translate }}</div>
                <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="dataLogin" class="box-input">
                    <label>Email</label>
                    <div class="st-form-group">
                        <img class="st_icon icon-16" src="assets/images/icons/ic_email.png" alt="icon email">
                        <input type="text" formControlName="email" name="email" placeholder="Email" class="form-control input-data" (input)="onTextChange($event)" />
                        <div *ngIf="submitted && dataLogin.controls.email.errors" class="txt-err">
                            <div *ngIf="dataLogin.controls.email.errors.required" class="noti-err">{{ 'user.txt_required_email' | translate }}</div>
                            <div *ngIf="dataLogin.controls.email.errors.email" class="noti-err">{{ 'user.txt_format_email' | translate }}</div>
                        </div>
                    </div>
                    <label>{{ 'user.txt_password' | translate }}</label>
                    <div class="st-form-group">
                        <img class="st_icon icon-16" src="assets/images/icons/ic_lock2.png" alt="icon lock">
                        <input type="password" formControlName="password" name="password" placeholder="Password" class="form-control input-data" (input)="onTextChange($event)" (keydown)="enterLogin($event)" />
                        <div *ngIf="submitted && dataLogin.controls.password.errors" class="txt-err">
                            <div *ngIf="dataLogin.controls.password.errors.required" class="noti-err">{{ 'user.txt_required_pw' | translate }}</div>
                            <div *ngIf="dataLogin.controls.password.errors.minlength" class="noti-err">{{ 'user.txt_pw_length' | translate }}</div>
                        </div>
                    </div>
                    <p class="txt-err txt-center">{{ notiErr }}</p>
                    <div class="box-remember">
                        <input type="checkbox" class="check-remember">
                        <div class="remember text-fuzzy">{{ 'user.txt_remember_pass' | translate }}</div>
                        <div class="account-action" (click)="resetPassword()">
                            <span>{{ 'user.txt_forget_pass' | translate }}?</span>
                        </div>
                    </div>
                </form>
                <div class="box-login">
                    <div class="btn-login" (click)="loginWithEmail()">
                        <span>{{ 'user.txt_login' | translate }}</span>
                    </div>
                    <div class="no-account">
                        <div class="account-action" (click)="goRegister()">
                            <span class="text-fuzzy">{{ 'user.txt_no_account' | translate }}? </span>
                            <span>{{ 'user.txt_register' | translate }}</span>
                        </div>
                    </div>
                </div>
                <hr/>
                <p class="text-fuzzy">{{ 'user.txt_login_with' | translate }}</p>
                <div class="box-social">
                    <div class="box-input icon-facebook" (click)="loginWithFB()">
                        <img class="icon-20" alt="icon facebook" src="assets/images/icons/ic_facebook_white.png">
                        <span>Facebook</span>
                    </div>
                    <div class="box-input icon-google" (click)="loginWithGG()">
                        <img class="icon-24" alt="icon google" src="assets/images/icons/ic_google_white.png">
                        <span>Google</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-modal>