<div class="btn-more" >
    <img class="icon-20" #more [class.hidden]="show" (click)="showMenu()" src="assets/images/icons/ic_menu_dot.png" alt="icon Jaemy dictionary"/>
    <div class="box-action" *ngIf="show">
        <div class="action" (click)="action('edit')">
            <img alt="edit comment Jaemy" class="icon-show icon-18" src="assets/images/icons/ic_note.png">
            {{ 'txt_edit' | translate }}
        </div>
        <div class="action" (click)="action('delete')">
            <img alt="edit comment Jaemy" class="icon-show icon-18" src="assets/images/icons/ic_close_grey.png">
            {{ 'txt_delete' | translate }}
        </div>
    </div>
</div>