<div *ngIf="help">
    <hr>
    <div class="item w-auto d-flex justify-content-between" (click)="showAns()">
        <p class="title-item">{{ help.title }}</p>
        <span *ngIf="toggle" class=""> <img class="icon" src="assets/images/help/down.png" alt="down"> </span>
        <span *ngIf="!toggle" class=""> <img class="icon" src="assets/images/help/up.png" alt="up"> </span>
    </div>
    <div class="toggle" *ngIf="toggle">
        <div class="content-item w-auto ml-4" *ngFor="let cont of help.content">
            <p>{{ cont.content }}</p>
            <div class="ml-4 mb-4">
                <div *ngFor="let child of cont.childHelp; let i= index">
                    <div *ngIf="child.childContent"> 
                        <b class="bg-light px-1"> {{ child.childContent }} </b>
                    </div>
                    <div class="ml-4" *ngFor="let item of child.itemChild">
                        <div *ngFor="let guide of item.guide">
                            <div>{{ guide.content }}</div>
                            <div *ngFor="let step of guide.steps">
                                <li *ngIf="step">{{ step }}</li>
                            </div>
                        </div>
                        <div>{{ item.child }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
