import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { CommonService } from '../../../services/common.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-crop-image',
    templateUrl: './crop-image.component.html',
    styleUrls: ['./crop-image.component.css']
})
export class CropImageComponent implements OnInit {

    @Input() myImage: any;
    @Output() changeImageOk = new EventEmitter<string>();

    myCroppedImage: string = '';

    constructor(
        public  commonService: CommonService,
        private userService: UserService,
        private router: Router,
        public translate: TranslateService,
    ) { }

    ngOnInit() {
    }

    imageCropped(event: ImageCroppedEvent) {
        this.myCroppedImage = event.base64;
    }
    
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

    chooseOk(): void {

        let user = this.commonService.getInforUser();
        if (user) {
            this.userService.updateAvatar(this.myCroppedImage, (data) => {
                if (data && data.status === 201) {
                    this.changeImageOk.emit(data.data);
                    this.commonService.closeModal('modal-crop-avatar');
                } else {
                    // this.commonService.showNotify('success', this.translate.instant('txt_had_err'));
                }
            });
        } else {
            this.router.navigate(['/']);
        }
    }


}
