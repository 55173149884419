import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import * as CONFIG from '../../config';
import { CommonService } from '../../services/common.service';
import { TransService } from '../../services/translate.service';

@Component({
    selector: 'app-translate',
    templateUrl: './translate.component.html',
    styleUrls: ['./translate.component.css']
})
export class TranslateComponent implements OnInit {

    textTranslate: string = '';
    resultTranslate: string = '';
    romaja: string = '';
    langWeb: string = '';
    langSource = CONFIG.LANGUAGE_CODE;
    // sourceView                  = [];
    // translateView               = [];
    // listWordCutTrans            = [];
    // wordMatch                   = [];
    langFrom = CONFIG.LANGUAGE_CODE[0];
    langTo = CONFIG.LANGUAGE_CODE[1];
    // showAllTranslateLanguage: boolean = false;
    // baseCodeLanguage: string    = '';
    showDraw: boolean = false;
    // widthDrawKanji: number      = 768;
    playingAudioFrom: boolean   = false;
    playingAudioTo: boolean     = false;
    showHistory: boolean        = false;
    // showDrawChoosen: boolean    = true;
    // loadComponentWord: boolean  = false;
    dataHistory; 

    constructor(
        private commonService: CommonService,
        private translateService: TransService,
        private seoService: SeoService

    ) { }

    ngOnInit(): void {
        this.seoService.applySEO('translate');
    }

    translateGoogle() {

        let query = this.textTranslate;
        query = this.convertString(query);
        this.showHistory = false;
        // this.listWordCutTrans = [];
        this.resultTranslate = '';
        
        // this.commonService.scrollToTop();

        if (!query || !query.trim()) {
            return;
        } else {
            this.pushHistory(query);

            this.translateService.cloudTranslate(query, this.langFrom.code, this.langTo.code , (result) => {
                if (result) {
                    if (result && result.length > 0) {
                        // if (this.langFrom.code == 'ko') {
                        //     this.getAnalysisWord(query)
                        // } 

                        this.romaja = '[romaja]: ';
                        let dataConverted  = this.translateService.convertGGResult(result);
                        this.resultTranslate += dataConverted[0];
                        this.romaja += dataConverted[1];
                    }
                }
            });

            // trackEvent('translate', query);
        }
    }

    playAudio(value: string) {
        if (this.playingAudioFrom || this.playingAudioTo) {
            this.playingAudioFrom = false;
            this.playingAudioTo = false;
            speechSynthesis.cancel();
        }

        if (( (this.langFrom.code === 'vi') && value === 'from' ) || ((this.langTo.code === 'vi') && value === 'to')) {
            return;
        }

        if (this.textTranslate) {
            speechSynthesis.cancel();
            const msg = new SpeechSynthesisUtterance();
            msg.volume  = 1;
            msg.rate = 0.75;
            msg.pitch = 1;

            if (value === 'from') {
                msg.text = this.textTranslate;
                msg.lang = this.langFrom.code;
                this.playingAudioFrom = true;
            } else {
                msg.text = this.resultTranslate;
                msg.lang = this.langTo.code;
                this.playingAudioTo = true;
            }
            speechSynthesis.speak(msg);

            msg.onend = (event) => {
                this.playingAudioFrom = false;
                this.playingAudioTo = false;
            };
        }
    }


    draw() {
        this.showDraw = !this.showDraw;
    }

    swapLanguage() {
        const tmp = this.langFrom;
        this.langFrom = this.langTo;
        // this.updateLangView(this.langFrom, 'from');

        this.langTo = tmp;
        // this.updateLangView(this.langTo, 'to');
    }

    changeLang(lang, type) {

    }
    
    clearTextTranslate() {
        this.textTranslate = '';
        this.resultTranslate = '';
        this.romaja = '';
        // this.listWordCutTrans = [];
        document.getElementById('text-translate').style.height = '200px';
    }

    showAllLang(type) {
        
    }

    onTextChange(e) {
        this.changeHeght();
    }

    getItemHistory(item) {
        let data;
        let textarea;

        data = item.query.replace(/<br\s*\/?>/gi, '\n');
        this.langFrom = item.from;
        this.langTo   = item.to;


        this.textTranslate = data;
        textarea = document.getElementById('text-translate');
        textarea[0].style.height = '150px';

        this.changeHeght();
        this.translateGoogle();

        this.commonService.scrollToTop();
    }

    getHistory() {
        this.showHistory = !this.showHistory;
    }

    pushHistory(query) {

        if (query == null || query === '') {
            return;
        }

        const newHist = {
            query,
            from : this.langFrom,
            to : this.langTo,
            date : new Date()
        };

        if (!this.dataHistory) {
            this.dataHistory = [];
            this.dataHistory.push(newHist);
        } else {
            this.dataHistory.forEach((item, index) => {
                if (item.query === query) {
                    this.dataHistory.splice(index, 1);
                }
            });

            this.dataHistory.push(newHist);
        }

        if (this.dataHistory.length > 10) {
            this.dataHistory.splice(0, 1);
        }

        this.commonService.setLocal('history-translate', this.dataHistory);

    }

    insertQueryText(data) {
        if (data === null || data === '') {
            return;
        }

        this.textTranslate = this.textTranslate + data;
    }

    changeHeght() {
        if (this.commonService.getEnvironment() === 'client') {
            const textarea = document.getElementById('text-translate');
            setTimeout(() => {
                textarea.style.height = textarea.scrollHeight + 'px';
            }, 50);
        }
    }

    copyToClipBoard(value) {
        let val;
        let selBox;

        val = (document.getElementById('content-translate') as HTMLInputElement).innerHTML;
        selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        // $('.noti-coppy').show();
        // setTimeout(() => {
        //     $('.noti-coppy').hide();
        // }, 1000);
    }

    convertString(html) {
        html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
        html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
        html = html.replace(/<\/div>/ig, '\n');
        html = html.replace(/<\/li>/ig, '\n');
        html = html.replace(/<li>/ig, '  *  ');
        html = html.replace(/<\/ul>/ig, '\n');
        html = html.replace(/<\/p>/ig, '\n');
        html = html.replace(/<br\s*[\/]?>/gi, '\n');
        html = html.replace(/<[^>]+>/ig, '');
        html = html.replace(/\n/g, '<br/>');
        return html;
    }
}
