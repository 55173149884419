<app-modal id="confirm" #confirm>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center">
                <!-- <img class="img-exit" src="assets/images/img_{{typeModal}}.png" alt="icon heyjapan"> -->
                <div class="content">
                    <span>{{ contentNoti }}</span>
                </div>
                <div class="box-btn">
                    <div class="btn-update" (click)="accept()"> {{ txt_green }} </div>
                    <div class="btn-cancel" *ngIf="txt_gray" (click)="cancel()"> {{ txt_gray }}  </div>
                </div>
            </div>
        </div>
    </div>
</app-modal>
