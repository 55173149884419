import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../modules/modal/modal.service';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

    typeModal: string = '';
    txt_green: string = '';
    txt_gray: string = '';
    contentNoti: string = '';

    @Input()
    set type(value) {
        if (value) {
            this.typeModal = value;

            if (value == 'delete-note') {
                this.contentNoti = this.translate.instant('txt_delete_note');
                this.txt_green = this.translate.instant('txt_delete');
                this.txt_gray = this.translate.instant('txt_skip');
            } 
            if (value == 'delete-post') {
                this.contentNoti = this.translate.instant('community.txt_delete_post');
                this.txt_green = this.translate.instant('txt_delete');
                this.txt_gray = this.translate.instant('txt_cancel');
            } 
        }
    }
    @Output() acceptEvent = new EventEmitter();

    constructor(
        private modalService: ModalService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
    }

    show() {
        setTimeout(() => {
            this.modalService.open('confirm');
        }, 100);
    }

    cancel() {
        this.modalService.close('confirm');
    }

    accept() {
        this.acceptEvent.emit(true);
        this.modalService.close('confirm');
    }
}
