export const BASE_API_URL = 'https://api.jaemy.net/';
export const BASE_API_URL_SEARCH = 'https://search.jaemy.net/search';
export const BASE_URL_AUDIO = '';
export const BASE_URL_DATA = 'https://data.jaemy.net/';

export const LANGUAGE_CODE = [
    {
        name : 'Korean',
        code : 'ko'
    },
    {
        name : 'Vietnamese',
        code : 'vi'
    },
    {
        name : 'Chinese (Simplified)',
        code : 'zh-CN'
    },
    {
        name : 'Chinese (Traditional)',
        code : 'zh-TW'
    },
    {
        name : 'Japanese',
        code : 'ja'
    },
    {
        name : 'English',
        code : 'en'
    },
    {
        name : 'French',
        code : 'fr'
    },
    {
        name : 'German',
        code : 'de'
    },
    {
        name : 'Indonesian',
        code : 'id'
    },
    {
        name : 'Italian',
        code : 'it'
    },
    {
        name : 'Russian',
        code : 'ru'
    },
];
