import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(
        private seoMeta: Meta,
        private seoTitle: Title,
        private commonService: CommonService,
        public translate: TranslateService,
    ) { }

    applySEO(page) {
        var url;
        if (this.commonService.getEnvironment() === 'client') {
            url = window.location.href;
        }
        this.seoTitle.setTitle(this.translate.instant('seo.' + page + '.title'));
        this.seoMeta.updateTag({ name: 'description', content: this.translate.instant('seo.' + page + '.des') });
        this.seoMeta.updateTag({ name: 'keywords', content: this.translate.instant('seo.' + page + '.key') });
        this.seoMeta.updateTag({ property: 'og:url', content: url });
    }

    applySEOSearch() {
      
        var url;
        if (this.commonService.getEnvironment() === 'client') {
            url = window.location.href;
        }
        
        this.seoTitle.setTitle(this.translate.instant('seo.search.title'));
        this.seoMeta.updateTag({ name: 'description', content: this.translate.instant('seo.search.des') });
        this.seoMeta.updateTag({ name: 'keywords', content: this.translate.instant('seo.search.key') });
        this.seoMeta.updateTag({ property: 'og:url', content: url });
        this.seoMeta.updateTag({ property: 'og:description', content: this.translate.instant('seo.search.des') });
        this.seoMeta.updateTag({ property: 'og:site_name', content: this.translate.instant('seo.search.title') });
        this.seoMeta.updateTag({ property: 'og:title', content: this.translate.instant('seo.search.title') });
    }

    applySEOWord(query, data) {
        var title = query;
        
        if (data && data != null) {
            if (data == null) {
                return;
            }

            var pinyin = data.pinyin;
            if (pinyin == null) {
                pinyin = '';
            } else {
                pinyin = " 「" + pinyin + "」 ";
            }

            title = data.word + pinyin ;
            var url;
            if (this.commonService.getEnvironment() === 'client') {
                url = window.location.href;
            }
            
            this.seoTitle.setTitle( this.translate.instant('txt_seo_means') + title + " | " + this.translate.instant('txt_title_default'));
            this.seoMeta.updateTag({ property: 'og:site_name', content: this.translate.instant('txt_seo_means') + title + " | " + this.translate.instant('txt_title_default')});
            this.seoMeta.updateTag({ property: 'og:title', content: this.translate.instant('txt_seo_means') + title + " | " + this.translate.instant('txt_title_default')});
            this.seoMeta.updateTag({ property: 'og:url', content: url });

            var mean = '';
            if (data.content && data.content.length > 0) {
                mean = data.content[0].means.length > 0 ? data.content[0].means[0].mean : '';
            }
            
            var desc = this.translate.instant('txt_seo_word') + data.word + pinyin + ":" + mean;
            this.seoMeta.updateTag({ property: 'og:description', content: desc });

        }
    }

    applySEOPost(title) {
        if (!title) {
            return;
        }

        var url;
        if (this.commonService.getEnvironment() === 'client') {
            url = window.location.href;
        }
        
        this.seoTitle.setTitle( title + " | " + this.translate.instant('community.txt_title_community'));
        this.seoMeta.updateTag({ property: 'og:site_name', content: title +" | " + this.translate.instant('community.txt_title_community') });
        this.seoMeta.updateTag({ property: 'og:title', content: title +" | " + this.translate.instant('community.txt_title_community') });
        this.seoMeta.updateTag({ property: 'og:url', content: url });
        this.seoMeta.updateTag({ property: 'og:description', content: title });
    }

    applySEOTest(level, key) {
        if (!level) {
            return;
        }

        var url;
        if (this.commonService.getEnvironment() === 'client') {
            url = window.location.href;
        }
        
        this.seoTitle.setTitle( 'HSK ' + level + " | " + this.translate.instant('community.txt_test') + key);
        this.seoMeta.updateTag({ property: 'og:site_name', content: 'HSK ' + level });
        this.seoMeta.updateTag({ property: 'og:title', content: 'HSK ' + level });
        this.seoMeta.updateTag({ property: 'og:url', content: url });
        this.seoMeta.updateTag({ property: 'og:description', content: 'HSK ' + level });
    }
}
