import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CommonService } from '../../../services/common.service';
import { UserService } from '../../../services/user.service';
import { BroadcasterService } from '../../../services/broadcaster.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./../login/login.component.css']
})
export class RegisterComponent implements OnInit {

    dataRegister!: FormGroup;
    submitted: boolean = false; 
    loginSocial: boolean = false; 
    loadDone: boolean = true;

    notiErr: string = '';
    provider: string = '';

    constructor(
        public commonService: CommonService,
        private userService: UserService,
        // private authService: SocialAuthService,
        private router: Router,
        public translate: TranslateService,
        private broadcaster: BroadcasterService
    ) {
     }

    ngOnInit() {
        this.submitted = false;
        this.loginSocial = false;
        this.loginSocial = true;

        this.dataRegister = new FormGroup({
            email     : new FormControl('', [ Validators.required, Validators.email]),
            password  : new FormControl('', [ Validators.required, Validators.minLength(6)]),
            confirm_password  : new FormControl('', [ Validators.required]),
            agree_term  : new FormControl('', [ Validators.required])
        });

        // this.authService.authState.subscribe((user) => {
        //     if (user != null) {
        //         if (this.provider) {
        //             let idToken;
        //             let dataSend;
        //             let accessToken;

        //             if (this.provider == 'google') {
        //                 accessToken = user.authToken;
        //                 idToken = user.idToken;
        //             } else if (this.provider == 'facebook') {
        //                 idToken = '';
        //                 accessToken = user.authToken;
        //             }

        //             dataSend = {
        //                 accessToken,
        //                 provider: this.provider,
        //                 idToken,
        //                 language: this.commonService.convertLang()
        //             };
        //             this.userService.loginWidth(dataSend, (res) => {
        //                 this.loginSuccess(res);
        //             });
        //         }

        //         // this.loginSocial = true;
        //     } else {
        //         this.loadDone = true;
        //     }
        // });

    }

    enterRegister(event) {
        if (event.key === 'Enter') {
            this.registerWithEmail();
        }
    }

    checkTerm() {
        this.notiErr = '';
    }

    registerWithEmail() {
        this.submitted = true;

        if (!this.dataRegister.value.agree_term) {
            this.notiErr = this.translate.instant('user.txt_err_tern');
            return;
        }

        if (this.dataRegister.invalid || (this.dataRegister.value.password !== this.dataRegister.value.confirm_password) ) {
            return;
        }

        const inforRegister = this.dataRegister.value;
        this.userService.register(inforRegister.email, inforRegister.password, data => {

            if (data.status === 201) {
                this.userService.login(inforRegister.email, inforRegister.password, (res) => {
                    this.loginSuccess(res);
        
                });
            } else {
                this.notiErr = this.translate.instant('user.txt_err_register_email');
            }
        });
    }

    loginWithFB() {
        this.loadDone = false;
        this.provider = 'facebook';
        // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    loginWithGG() {
        this.loadDone = false;
        this.provider = 'google';
        // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    loginSuccess(value) {
        this.loadDone = true;
        if (this.commonService.getEnvironment() === 'client') {
            if (value.status === 200) {
                this.commonService.closeModal('register');

                this.commonService.setInforUser(value.data);
                this.commonService.setLoginStatus(true);
                this.broadcaster.broadcast('notify', 
                    {
                        'content': this.translate.instant('user.txt_register_sucsess'),
                        'type': 'success'
                    }
                )

                this.notiErr = '';

            } else {
                this.notiErr = this.translate.instant('user.txt_err_login');
            }
        }
    }

    goLogin() {
        this.commonService.closeModal('register');
        this.commonService.openModal('login');
    }

    goRouter(value) {
        this.commonService.closeModal('register');
        // this.router.navigate([value]);
    }

    onTextChange(event) {
        this.notiErr = '';
    }

    close() {
        this.dataRegister.reset();
        this.submitted = false;
        this.notiErr = '';
        this.commonService.closeModal('register');
    }

}
