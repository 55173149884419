import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Category } from '../../models/note';
import { BroadcasterService } from '../../services/broadcaster.service';
import { User } from '../../models/user';
import { CommonService } from '../../services/common.service';
import { NoteService } from '../../services/note.service';

@Component({
    selector: 'app-add-cate',
    templateUrl: './add-cate.component.html',
    styleUrls: ['./add-cate.component.css']
})
export class AddCateComponent implements OnInit {

    user: User | undefined;
    notiErr: string = ''; 
    name: string = '';
    type: string = 'create';
    currentCategory: Category | undefined;

    constructor(
        private noteService: NoteService,
        public commonService: CommonService,
        public translate: TranslateService,
        private broadcast: BroadcasterService
    ) { }

    ngOnInit() {
        this.notiErr = '';
        this.name = '';
        this.user = this.commonService.getInforUser();

        this.broadcast.on('change-infor').subscribe((user: User) => {
            if (user) {
                this.user = user;
            }
        });

        this.broadcast.on('edit-cate').subscribe((cate: Category) => {
            if (cate) {
                this.type = 'edit';
                this.name = cate.name;
                this.currentCategory = cate;
            } else {
                this.type = 'create';
            }
            this.commonService.openModal('add-cate');
        });
    }

    addCategory() {
        let nameCate: string = this.commonService.changeContent(this.name);

        if ( !nameCate || (nameCate == undefined) ) {
            this.notiErr = this.translate.instant('txt_require_note_cate');
            return;
        }

        if (!this.user) {
            return;
        }

        if (this.type == 'create') {
            this.noteService.addNewCategory(nameCate, (data: Category) => {
                if (data) {
                    this.broadcast.broadcast('add-cate', data);
                    this.name = '';
                    this.commonService.closeModal('add-cate');
                } else {
                    console.log('Err');
                }
            });
        } else {
            this.currentCategory.name = nameCate;
            this.noteService.editCategory(nameCate, this.currentCategory.id, (data: Category) => {
                if (data) {
                    this.broadcast.broadcast('update-cate', this.currentCategory);
                    this.name = '';
                    this.commonService.closeModal('add-cate');
                } else {
                    console.log('Err');
                }
            });
        }
    }

    close() {
        this.commonService.closeModal('add-cate');
        this.notiErr = '';
        this.name = '';
    }
}
