import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    langWeb: string;
    constructor(
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.langWeb = this.commonService.getLangPack();
    }

}
