import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';
@Pipe({
    name: 'premium'
})
export class UserPremiumPipe implements PipeTransform {

    transform(user: User) {
        if (!user) {
            return false;
        }

        if (user.is_premium == 1 || user.premium) {
            return true;
        } else {
            if (user.premium_expired) {
                const now = new Date();
                let timeNow = now.getTime();

                if (user.premium_expired*1000 > timeNow) {
                    return true;
                } else {
                    return false;
                }
            } 
        }
        return false;
    }
}
