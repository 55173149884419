import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as CONFIG from '../config';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class NoteService {

    constructor(
        private http: HttpClient,
    ) { }

    getListCategory( callback) {
        const url = CONFIG.BASE_API_URL + 'api/category';

        this.http.get(url, httpOptions)
        .subscribe(
            data => callback(data['data']),
            err => callback(null)
        );
    }

    getDetailNote(id, callback) {
        const url = CONFIG.BASE_API_URL + 'api/notebooks/' + id;

        this.http.get(url, httpOptions)
        .subscribe(
            data => callback(data['data']),
            err => callback(null)
        );

    }

    addNewCategory(name, callback) {
        const url = CONFIG.BASE_API_URL + 'api/category';
        const dataSend = {
            name
        };

        this.http.post(url, dataSend, httpOptions)
        .subscribe(
            data => callback(data['data']),
            err => callback(null)
        );

    }

    editCategory(name, id, callback) {
        const url = CONFIG.BASE_API_URL + 'api/category/' + id;
        const dataSend = {
            name
        };

        this.http.put(url, dataSend, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );

    }

    deleteCategory(id, callback) {
        const url = CONFIG.BASE_API_URL + 'api/category/' + id;

        this.http.delete(url, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );

    }

    addNoteBook(word: string, type: string, mean: string, cateId: number, callback) {
        const url = CONFIG.BASE_API_URL + 'api/notebooks';
        const dataSend = {
            word,
            mean,
            type,
            cateId
        };

        this.http.post(url, dataSend, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    editNoteBook(note, id, callback) {
        const url = CONFIG.BASE_API_URL + 'api/notebooks/' + id;
        const dataSend = {
            note,
            id
        };

        this.http.patch(url, dataSend, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    deleteNoteBook(id, callback) {
        const url = CONFIG.BASE_API_URL + 'api/notebooks/' + id;

        this.http.delete(url, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }

    changeCategory(noteId, categoryId, callback) {
        const url = CONFIG.BASE_API_URL + 'api/notebooks/' + noteId + '/category/' + categoryId;

        this.http.patch(url, httpOptions)
        .subscribe(
            data => callback(data),
            err => callback(null)
        );
    }
}
