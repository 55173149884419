// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDgKgrX0E4K0bAI-mbVGR7ZEhf6dKzBcPg",
    authDomain: "jaemy-312009.firebaseapp.com",
    projectId: "jaemy-312009",
    storageBucket: "jaemy-312009.appspot.com",
    messagingSenderId: "524102256401",
    appId: "1:524102256401:web:b0854f6bc2a7d07b2f6eb0",
    measurementId: "G-TVX5LS4VXN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
