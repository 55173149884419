<app-modal id="register">
    <div class="modal-dialog">
        <div class="modal-content login-component">
            <div class="modal-header">
                <div class="title">{{ 'user.txt_register' | translate }}</div>
                <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="dataRegister" class="box-input">
                    <label>Email</label>
                    <div class="st-form-group">
                        <img class="st_icon icon-18" src="assets/images/icons/ic_email.png" alt="icon email">
                        <input type="text" formControlName="email" placeholder="Email" class="form-control input-data" (input)="onTextChange($event)"/>
                        <div *ngIf="submitted && dataRegister.controls.email.errors" class="txt-err">
                            <div *ngIf="dataRegister.controls.email.errors.required" class="noti-err">
                                {{ 'user.txt_required_email' | translate }}
                            </div>
                            <div *ngIf="dataRegister.controls.email.errors.email" class="noti-err">
                                {{ 'user.txt_format_email' | translate }}
                            </div>
                        </div>
                    </div>
                    <label>{{ 'user.txt_password' | translate }}</label>
                    <div class="st-form-group">
                        <img class="st_icon icon-18" src="assets/images/icons/ic_lock2.png" alt="icon lock">
                        <input type="password" formControlName="password" placeholder="Password" class="form-control input-data" (input)="onTextChange($event)"/>
                        <div *ngIf="submitted && dataRegister.controls.password.errors" class="txt-err">
                            <div *ngIf="dataRegister.controls.password.errors.required" class="noti-err">
                                {{ 'user.txt_required_pw' | translate }}
                            </div>
                            <div *ngIf="dataRegister.controls.password.errors.minlength" class="noti-err">
                                {{ 'user.txt_pw_length' | translate }}
                            </div>
                        </div>
                    </div>
                    <label>{{ 'user.txt_confirm_pass' | translate }}</label>
                    <div class="st-form-group">
                        <img class="st_icon icon-18" src="assets/images/icons/ic_lock2.png" alt="icon lock">
                        <input type="password" formControlName="confirm_password" placeholder="Confirm password" class="form-control input-data" (input)="onTextChange($event)" (keydown)="enterRegister($event)" />
                        <div *ngIf="dataRegister.value.confirm_password && !(dataRegister.value.password == dataRegister.value.confirm_password)" class="txt-err">
                            <div class="noti-err">{{ 'user.txt_pass_match' | translate }}</div>
                        </div>
                        <div *ngIf="submitted && dataRegister.controls.confirm_password.errors" class="txt-err">
                            <div *ngIf="dataRegister.controls.confirm_password.errors.required" class="noti-err">
                                {{ 'user.txt_required_cpw' | translate }}
                            </div>
                        </div>
                    </div>
                    <p class="txt-err txt-center">{{ notiErr }}</p>
                    <div class="box-remember">
                        <input type="checkbox" class="check-remember" formControlName="agree_term" (click)="checkTerm()">
                        <div class="remember text-fuzzy">
                            <span>{{ 'user.txt_agree' | translate }}</span>
                            <span (click)="goRouter('/term')">{{ 'user.txt_term_of_use' | translate }}</span></div>
                    </div>
                </form>
                <div class="box-login">
                    <div class="btn-login" (click)="registerWithEmail()">
                        <span>{{ 'user.txt_register' | translate }}</span>
                    </div>
                    <div class="no-account">
                        <div class="account-action" (click)="goLogin()">
                            <span class="text-fuzzy">{{ 'user.txt_had_account' | translate }}? </span>
                            <span>{{ 'user.txt_login' | translate }}</span>
                        </div>
                    </div>
                </div>
                <hr/>
                <p class="text-fuzzy">{{ 'user.txt_login_with' | translate }}</p>
                <div class="box-social">
                    <div class="box-input icon-facebook" (click)="loginWithFB()">
                        <img class="icon-20" alt="icon facebook" src="assets/images/icons/ic_facebook_white.png">
                        <span>Facebook</span>
                    </div>
                    <div class="box-input icon-google" (click)="loginWithGG()">
                        <img class="icon-24" alt="icon google" src="assets/images/icons/ic_google_white.png">
                        <span>Google</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-modal>