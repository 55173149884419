import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BroadcasterService } from '../../services/broadcaster.service';
import { CommonService } from '../../services/common.service';
import { User } from '../../models/user';
import { SearchService } from '../../services/search.service';

interface Word {
    wordId: number;
    type: string;
    word: string;
}

@Component({
    selector: 'app-add-feedback',
    templateUrl: './add-feedback.component.html',
    styleUrls: ['./add-feedback.component.css']
    })
export class AddFeedbackComponent implements OnInit {
    word: Word | undefined;
    @Input() set dataWord(value: Word) {
        this.word = value;
        this.notiErr = '';
        this.notiSuccess = '';
    }
    @Input() user: User | undefined;
    @Output() addFeedback = new EventEmitter();
    reportMean: string = '';
    notiErr: string = '';
    notiSuccess: string = '';

    constructor(
        private searchService: SearchService,
        private commonService: CommonService,
        private broadcast: BroadcasterService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.notiErr = '';
        this.notiSuccess = '';
    }

    addOrUpdate() {
        if (!this.user) {
            // this.commonServ.notify = this.translate.instant('txt_require_login');
            // $('.notify-current').fadeIn(200);
            // setTimeout(res => {
            //     $('.notify-current').fadeOut(200);
            // }, 2000);
        } else {
            let value = this.commonService.changeContent(this.reportMean);
            if (!value || !value.trim()) {
                this.notiErr = this.translate.instant('txt_require_feedback');
                return;
            }

            let data = {
                mean: value,
                type: this.word.type,
                wordId: this.word.wordId,
                word: this.word.word,
                dict: 'ko' + this.commonService.getLangPack()
            }

            this.searchService.addFeedback(data).subscribe(res => {
                if (res && res.length) {
                    this.reportMean = '';
                    this.addFeedback.emit(JSON.stringify(res[0]));
                    this.notiSuccess = this.translate.instant('txt_feedback_sucess');

                    setTimeout(() => {
                        this.notiSuccess = '';
                    }, 2000);
                } else {
                    this.notiErr = this.translate.instant('txt_mess_err');
                }
            });
        }
    }

    changeInputFeedback() {
        this.notiErr = '';
        this.notiSuccess = '';
    }
}
