import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-modal-detail',
    templateUrl: './modal-detail.component.html',
    styleUrls: ['./modal-detail.component.css']
})
export class ModalDetailComponent implements OnInit {
    loadDone: boolean = true;
    type: string = 'word';
    querySearch: string = '';
    detailWord: any;
    listExample: any = [];

    constructor(
        private commonService: CommonService
    ) { }

    ngOnInit(): void {
    }

    closeModal() {
        this.commonService.closeModal('modal-detail');
    }

    changeTab(tab) {
        this.loadDone = false;
        if (!this.querySearch) {
            this.querySearch = this.detailWord.word;
        }
       
        this.detailWord = null;
        this.type = tab;
        this.searchData(this.querySearch, this.type, this.commonService.getLangPack());
    }

    searchData(query, type, language) {

    }

}
